import { Dispatch } from "react";
import { backendApi } from "../../api/backendApi";
import { AddressesTypesResponse } from "../../interfaces/addressesTypes.interfaces";
import { AddressesTypesState } from "../../reducers/addressTypes.reducer";
import { AddressTypesActionTypes } from "../../types/addressesTypes.types";

export interface UseAddressesTypesProps {
  getAddressesTypes: () => void;
}

export const useAddressesTypes = (
  state: AddressesTypesState,
  dispatch: Dispatch<AddressTypesActionTypes>
): UseAddressesTypesProps => {
  const getAddressesTypes = async () => {
    dispatch({ type: "getAddressTypes" });
    try {
      const response = await backendApi.get<AddressesTypesResponse>(
        "addresses-types"
      );
      dispatch({ type: "getAddressesTypesSuccess", payload: response.data });
    } catch (error) {}
  };
  return {
    getAddressesTypes,
  };
};

import { useContext } from "react";
import { DialogActions, DialogContent } from "@material-ui/core";
import { ProductForm } from "../Form/ProductForm";
import { useProductModalStyles } from "./styles";
import { ProductAnnexeds } from "../ProductAnnexeds";
import { ProductsContext } from "../../../contexts/ProductsContext";
import { SecondaryActionButton } from "../../Common/ActionButtons/SecondaryActionButton";

export const DataTab = () => {
  const classes = useProductModalStyles();
  const { dispatch, onSubmit } = useContext(ProductsContext);
  return (
    <>
      <DialogContent className={classes.dataContianer}>
        <ProductForm onSubmit={onSubmit} />
        <ProductAnnexeds />
      </DialogContent>
      <DialogActions style={{ padding: 10 }}>
        <SecondaryActionButton
          onClick={() => dispatch({ type: "hideEditProduct" })}
          label="Cerrar"
        />
      </DialogActions>
    </>
  );
};

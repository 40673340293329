import { makeStyles } from "@material-ui/core/styles";

export const drawerWidth = 240;
const { innerHeight } = window;

export const useSideBarStyles = makeStyles((theme) => ({
  root: {
    display: "flex",
    backgroundColor: "#eeeeee",
    minHeight: innerHeight,
  },
  drawer: {
    [theme.breakpoints.up("sm")]: {
      width: drawerWidth,
      flexShrink: 0,
    },
  },
  appBar: {
    [theme.breakpoints.up("sm")]: {
      width: `calc(100% - ${drawerWidth}px)`,
      marginLeft: drawerWidth,
    },
  },
  menuButton: {
    marginRight: theme.spacing(2),
    [theme.breakpoints.up("sm")]: {
      display: "none",
    },
  },
  // necessary for content to be below app bar
  toolbar: theme.mixins.toolbar,
  drawerPaper: {
    width: drawerWidth,
    backgroundColor: "#fafafa",
    borderRight: "none",
    boxShadow: "0 0 1.3em darkgray",
  },
  content: {
    width: "100%",
    maxWidth: "100%",
  },
  black: {
    backgroundColor: "black",
  },
  large: {
    width: theme.spacing(9),
    height: theme.spacing(9),
    backgroundColor: "black",
  },
  formControl: {
    margin: theme.spacing(1),
    minWidth: 120,
    width: "100%",
  },
  selectEmpty: {
    marginTop: theme.spacing(2),
  },
  newUserForm: {
    "& > *": {
      margin: theme.spacing(1),
      width: "25ch",
    },
  },
  textCenter: {
    textAlign: "center",
  },
  optionsGroup: {
    maxHeight: "15%",
  },
  mobileAppBar: {
    [theme.breakpoints.up("lg")]: {
      height: 0,
    },
    height: 50,
  },
}));

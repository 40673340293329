import React from "react";
import { IconButton } from "@material-ui/core";
import { CSSProperties } from "react";
import { CustomButtonIconTypes, CustomIcon } from "./CustomIconTypes";

type ButtonColor = "primary" | "inherit" | "default" | "secondary";

interface Props {
  title?: string;
  onClick: () => void;
  iconType: CustomButtonIconTypes;
  style?: CSSProperties;
  color?: ButtonColor;
  classes?: string;
}

export const CustomIconButton = ({
  title = "",
  onClick,
  iconType,
  style,
  color = "default",
  classes,
}: Props) => {
  return (
    <IconButton
      component="span"
      onClick={onClick}
      title={title}
      color={color}
      className={classes}
    >
      <CustomIcon iconType={iconType} style={style} />
    </IconButton>
  );
};

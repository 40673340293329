import { useContext } from "react";
import {
  DialogActions,
  DialogContent,
  DialogTitle,
  Dialog,
  Typography,
} from "@material-ui/core";
import { ServiceTypesContext } from "../../contexts/ServiceTypesContext";
import { CustomActionButton } from "../Common/ActionButtons/CustomActionButton";
import { SecondaryActionButton } from "../Common/ActionButtons/SecondaryActionButton";
import { ServiceTypeForm } from "./ServiceTypeForm";

export const ServiceTypeModal = () => {
  const { closeModal, showModal, onSubmit, type } =
    useContext(ServiceTypesContext);
  return (
    <Dialog open={showModal} onClose={closeModal} maxWidth="sm" fullWidth>
      <DialogTitle id="alert-dialog-title">
        <Typography>
          {type.id ? "Editar tipo de homenaje" : "Nuevo tipo de homenaje"}
        </Typography>
      </DialogTitle>
      <DialogContent>
        <ServiceTypeForm />
      </DialogContent>
      <DialogActions>
        <CustomActionButton onClick={onSubmit} />
        <SecondaryActionButton onClick={closeModal} />
      </DialogActions>
    </Dialog>
  );
};

import React from "react";
import {
  CssBaseline,
  ListItem,
  ListItemText,
  Divider,
  Drawer,
  Hidden,
  IconButton,
  List,
  Grid,
} from "@material-ui/core";
import MenuIcon from "@material-ui/icons/Menu";
import { useTheme } from "@material-ui/core/styles";
import { NavLink } from "react-router-dom";
import { v4 as uuid } from "uuid";
import { menuItems } from "./menuItems";
import { useSideBarStyles } from "./styles";
import { UserCard } from "../User/UserCard/UserCard";
import "./SideBar.css";

interface Props {
  children: JSX.Element | JSX.Element[];
  container?: any;
}

export const ResponsiveDrawer = ({ children, container }: Props) => {
  const classes = useSideBarStyles();
  const theme = useTheme();
  const [mobileOpen, setMobileOpen] = React.useState(false);

  const handleDrawerToggle = () => {
    setMobileOpen(!mobileOpen);
  };

  const drawer = (
    <div>
      <UserCard />
      <List>
        {menuItems.map((item) => (
          <div key={uuid()} className={classes.optionsGroup}>
            <div className="d-flex justify-content-start align-items-center my-2">
              <div className="ml-4">{item.icon}</div>
              <div className="d-flex flex-column align-items-center">
                {item.subItemsList.map((sItem) => (
                  <ListItem key={uuid()} button>
                    <NavLink
                      to={sItem.path}
                      className="d-flex flex-row nav-link-custom"
                      activeClassName="active-link-item"
                    >
                      <ListItemText
                        className="my-0 font-weight-bold"
                        primary={sItem.label}
                      />
                    </NavLink>
                  </ListItem>
                ))}
              </div>
            </div>
            <Divider component="li" />
          </div>
        ))}
      </List>
    </div>
  );
  return (
    <>
      <Grid
        container
        justifyContent="space-between"
        style={{ paddingLeft: 10 }}
        alignItems="center"
      >
        <IconButton
          color="inherit"
          aria-label="open drawer"
          edge="start"
          onClick={handleDrawerToggle}
          className={classes.menuButton}
        >
          <MenuIcon />
        </IconButton>
      </Grid>
      <div className={classes.root}>
        <CssBaseline />
        <nav className={classes.drawer} aria-label="mailbox folders">
          {/* The implementation can be swapped with js to avoid SEO duplication of links. */}
          <Hidden smUp implementation="css">
            <Drawer
              container={container}
              variant="temporary"
              anchor={theme.direction === "rtl" ? "right" : "left"}
              open={mobileOpen}
              onClose={handleDrawerToggle}
              classes={{
                paper: classes.drawerPaper,
              }}
              ModalProps={{
                keepMounted: true, // Better open performance on mobile.
              }}
            >
              {drawer}
            </Drawer>
          </Hidden>
          <Hidden xsDown implementation="css">
            <Drawer
              classes={{
                paper: classes.drawerPaper,
              }}
              variant="permanent"
              open
            >
              {drawer}
            </Drawer>
          </Hidden>
        </nav>
        <main className={classes.content}>{children}</main>
      </div>
    </>
  );
};

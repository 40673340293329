import { LinksPagination, MetaPagination } from "./pagination.interfaces";

export interface ServiceTypesResponse {
  data: ServiceType[];
  links: LinksPagination;
  meta: MetaPagination;
}

export interface ServiceType {
  id?: string;
  name: string;
  status: ServiceTypeStatus;
  label: string;
}

export interface ServiceTypeResponse {
  code: number;
  message: string;
  data: ServiceType;
}

export enum ServiceTypeStatus {
  active = "V",
  inactive = "C",
}

export interface ServiceTypeStatusResponse {
  data: TypeStatus[];
  code: number;
}

export interface TypeStatus {
  label: string;
  value: string;
}

export interface ServiceTypeErrors {
  name: string[];
  status: string[];
}

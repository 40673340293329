import { Typography } from "@material-ui/core";

interface Props {
  date: string;
}

export const DateLabel = ({ date }: Props) => {
  const options = {
    weekday: "long",
    year: "numeric",
    month: "short",
    day: "numeric",
  };

  const objectDate = new Date(date);

  return (
    <Typography>
      {objectDate.toLocaleDateString("es-MX", options as any)}
    </Typography>
  );
};

import { useContext } from "react";
import { Grid, Typography } from "@material-ui/core";
import { useLoginviewStyles } from "./styles";
import { LoadingSpinner } from "../../components/LoadingSpinner/LoadingSpinner";
import { AuthContext } from "../../contexts/AuthContext";
import { LoginForm } from "../../components/Login/LoginForm/LoginForm";
import pandora from "./pandora.svg";
import valparaiso from "./valparaiso.svg";
import chia from "./chia.svg";
import "./LoginView.css";

export const LoginView = () => {
  const classes = useLoginviewStyles();
  const { loading } = useContext(AuthContext);

  return (
    <div className="login-view-container">
      <Grid container>
        <Grid container item xs={12} justifyContent="center">
          <Grid
            container
            item
            xs={12}
            lg={8}
            className={classes.titleContainer}
          >
            <Typography className={classes.welcomeTitle}>
              Bienvenido a
            </Typography>
            <img src={pandora} alt="pandora" className={classes.mainImage} />
            <h4 className={classes.welcomeSubTitle}>¡Hoy es un gran día!</h4>
          </Grid>
          <Grid
            container
            item
            xs={11}
            lg={4}
            justifyContent="center"
            className={classes.loginContainer}
          >
            <LoginForm />
          </Grid>
        </Grid>
        <Grid
          container
          item
          xs={12}
          className={classes.loginFooter}
          direction="row"
        >
          <Grid
            container
            item
            xs={12}
            lg={6}
            alignItems="flex-end"
            className={classes.itemFooter}
          >
            <Typography className={classes.footerTitle}>
              Esta es una aplicación exclusiva de Valparaiso
            </Typography>
          </Grid>
          <Grid
            container
            item
            xs={12}
            lg={6}
            justifyContent="flex-end"
            className={classes.itemFooterImagesContainer}
          >
            <img
              className={classes.itemFooterImage}
              src={valparaiso}
              alt="valparaiso"
            />
            <img className={classes.itemFooterImage} src={chia} alt="chia" />
          </Grid>
        </Grid>
        <LoadingSpinner loading={loading} color="white" />
      </Grid>
    </div>
  );
};

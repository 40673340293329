import { useContext, useEffect } from "react";
import { Dialog, DialogContent, DialogTitle, Grid } from "@material-ui/core";
import { ProductPriceForm } from "./PoductPriceForm";
import { usePriceModalStyles } from "./styles";
import { ProductsContext } from "../../../contexts/ProductsContext";
import { PriceListContext } from "../../../contexts/PriceListContext";

export const ProductPriceModal = () => {
  const classes = usePriceModalStyles();

  const { showCreatePrice, product, dispatch, loadFinancements } =
    useContext(ProductsContext);

  const { getPriceLists } = useContext(PriceListContext);

  useEffect(() => {
    loadFinancements();
    getPriceLists();
  }, []);

  return (
    <Dialog
      open={showCreatePrice}
      maxWidth="sm"
      fullWidth
      onClose={() => {
        dispatch({ type: "hideCreatePrice" });
      }}
    >
      <DialogTitle>
        <Grid className={classes.titleContainer} container>
          {product.name}
        </Grid>
      </DialogTitle>
      <DialogContent>
        <ProductPriceForm />
      </DialogContent>
    </Dialog>
  );
};

import { makeStyles } from "@material-ui/core/styles";

export const usPaginatedTableStyles = makeStyles((theme) => ({
  container: {
    padding: 20,
  },
  searchFieldContainer: {
    display: "flex",
    alignContent: "center",
    [theme.breakpoints.down("sm")]: {
      marginTop: 15,
      marginBottom: 15,
    },
  },
  textField: {
    flexGrow: 1,
  },
  marginTop: {
    marginTop: 15,
  },
  paddingTop: {
    paddingTop: 15,
  },
  tableRow: {
    textAlign: "left",
    textTransform: "uppercase",
  },
  tableHeader: {
    textTransform: "uppercase",
    color: theme.palette.grey[500],
    textAlign: "left",
  },
  hiddenDesktop: {
    [theme.breakpoints.up("lg")]: {
      display: "none",
    },
  },
  hiddenMobile: {
    [theme.breakpoints.down("md")]: {
      display: "none",
    },
  },
}));

import { TabsIndex } from "./ModalHeder";

interface Props {
  children: JSX.Element;
  index: TabsIndex;
  value: TabsIndex;
}

export const TabPanel = ({ children, value, index }: Props) => {
  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`wrapped-tabpanel-${index}`}
      aria-labelledby={`wrapped-tab-${index}`}
    >
      {children}
    </div>
  );
};

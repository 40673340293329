import { createContext, useReducer } from "react";
import {
  usePaymentFrequencies,
  UsePaymentFrequenciesProps,
} from "../hooks/paymentFrequencies/usePaymentFrequencies";
import { ProviderProps } from "../interfaces/common.interfaces";
import {
  paymentFrequenciesReducer,
  PaymentFrequencyState,
  PAYMENT_FREQUENCIES_INITIAL_STATE,
} from "../reducers/paymentFrequenciesReducer";

interface ContextProps
  extends PaymentFrequencyState,
    UsePaymentFrequenciesProps {}

export const PaymentFrequenciesContext = createContext({} as ContextProps);

export const PaymentFrequenciesProvider = ({ children }: ProviderProps) => {
  const initialState = PAYMENT_FREQUENCIES_INITIAL_STATE;
  const [state, dispatch] = useReducer(paymentFrequenciesReducer, initialState);

  const context = usePaymentFrequencies(state, dispatch);
  return (
    <PaymentFrequenciesContext.Provider value={{ ...state, ...context }}>
      {children}
    </PaymentFrequenciesContext.Provider>
  );
};

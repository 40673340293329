import { makeStyles } from "@material-ui/core";

const drawerWidth = 240;

export const useContentWrapperStyles = makeStyles((theme) => ({
  contentWrapper: {
    position: "relative",
    paddingTop: 20,
    paddingBottom: 20,
  },
  marginTop: {
    marginTop: 50,
  },
  paddingTop: {
    paddingTop: 40,
    [theme.breakpoints.up("md")]: {
      paddingTop: 80,
    },
  },
  defaultPaddingTop: {
    paddingTop: 40,
    [theme.breakpoints.up("md")]: {
      paddingTop: 80,
    },
  },
  wrapper: {
    display: "flex",
    justifyContent: "center",
    justifyItems: "center",
    flexDirection: "column",
    width: "100%",
    maxWidth: "100%",
  },
  appBar: {
    [theme.breakpoints.up("sm")]: {
      width: `calc(100% - ${drawerWidth}px)`,
      marginLeft: drawerWidth,
    },
  },
  childrenContainer: {
    width: "100%",
    maxWidth: "100%",
    paddingLeft: 15,
    paddingRight: 15,
    justifyItems: "center",
  },
}));

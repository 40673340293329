import { FinancementPaginated } from "../interfaces/fiancements.interfaces";
import {
  LinksPagination,
  MetaPagination,
} from "../interfaces/pagination.interfaces";
import { PaymentFrequency } from "../interfaces/paymentFrequency.interfaces";
import { PriceList } from "../interfaces/priceLists.interfaces";
import {
  PaymentFrequencyAmount,
  Product,
  ProductErrors,
  ProductPaginated,
  ProductPrice,
  ProductStatus,
} from "../interfaces/products.interfaces";
import { ProductActionTypes } from "../types/products.types";

export interface ProductsState {
  loading: boolean;
  error: boolean;
  errors: ProductErrors;
  loadingServiceTypes: boolean;
  success: boolean;
  message: string;
  searchValue: string;
  showNewProduct: boolean;
  showEditProduct: boolean;
  showCreatePrice: boolean;
  showPaymentFrequencyForm: boolean;
  hideAlert: boolean;
  product: Product;
  list: ProductPaginated[];
  status: ProductStatus[];
  links: LinksPagination;
  pagination: MetaPagination;
  financements: FinancementPaginated[];
  price: ProductPrice;
  paymentFrequencyAmount: PaymentFrequencyAmount;
  priceLists: PriceList[];
  paymentFrequencies: PaymentFrequency[];
}
export const PRODUCTS_INITIAL_STATE: ProductsState = {
  loading: false,
  loadingServiceTypes: false,
  success: false,
  message: "",
  searchValue: "",
  showNewProduct: false,
  showEditProduct: false,
  showCreatePrice: false,
  showPaymentFrequencyForm: false,
  hideAlert: false,
  error: false,
  list: [],
  errors: {
    name: [],
    status: [],
    typeId: [],
    financementId: [],
    cashPaymentPrice: [],
    factor: [],
    priceListId: [],
    paymentFrequencyId: [],
    paymentAmount: [],
    downPaymentAmount: [],
  },
  status: [],
  product: {
    name: "",
    typeId: "",
    status: "V",
    createdAt: "",
    creatorId: "",
    updatedAt: "",
    modifierId: "",
    //importes: [],
    annexeds: [],
    prices: [],
    paymentFrequencyAmounts: [],
  },
  pagination: {
    current_page: 1,
    from: 1,
    to: 10,
    last_page: 0,
    path: "",
    per_page: 10,
    total: 0,
  },
  links: {
    first: undefined,
    last: undefined,
    prev: undefined,
    next: undefined,
  },
  financements: [],
  price: {
    id: undefined,
    financementId: "",
    cashPaymentPrice: 0,
    factor: 0,
    priceListId: "",
    productId: "",
  },
  paymentFrequencyAmount: {
    product: "",
    financementId: "",
    paymentFrequencyId: "",
    paymentAmount: 0,
    downPaymentAmount: 0,
  },
  priceLists: [],
  paymentFrequencies: [],
};

export const productsReducer = (
  state = PRODUCTS_INITIAL_STATE,
  action: ProductActionTypes
): ProductsState => {
  switch (action.type) {
    case "defaultErrorResponse":
      return {
        ...state,
        loading: false,
      };
    case "getProducts":
    case "getProductStatus":
    case "createProduct":
    case "updateProduct":
    case "savePrice":
    case "getPaymentFrequencyProductAmount":
    case "createPaymentFrequencyProductAmount":
    case "updatePaymentFrequencyProductAmount":
    case "getFinancements":
    case "getProductData":
    case "onChangeAnnexed":
    case "getPriceList":
    case "getPaymentFrequencies":
      return {
        ...state,
        loading: true,
        error: false,
      };
    case "getProductsSucess":
      return {
        ...state,
        loading: false,
        error: false,
        list: action.payload.data,
        links: action.payload.links,
        pagination: action.payload.meta,
      };
    case "getFinancementsError":
    case "getProductsError":
    case "getProductDataError":
    case "getPriceListError":
    case "getPaymentFrequenciesError":
      return {
        ...state,
        error: true,
        loading: false,
      };
    case "getProductDataSucccess":
      return {
        ...state,
        product: action.payload,
        loading: false,
        errors: { ...PRODUCTS_INITIAL_STATE.errors },
      };
    case "setProduct":
      return {
        ...state,
        product: action.payload,
      };
    case "resetProduct":
      return {
        ...state,
        product: PRODUCTS_INITIAL_STATE.product,
        errors: PRODUCTS_INITIAL_STATE.errors,
      };
    case "setPrice":
      return {
        ...state,
        price: action.payload,
      };
    case "resetPrice":
      return {
        ...state,
        price: PRODUCTS_INITIAL_STATE.price,
      };
    case "showNewProduct":
      return {
        ...state,
        showNewProduct: true,
      };
    case "showEditProduct":
      return {
        ...state,
        showEditProduct: true,
      };
    case "hideNewProduct":
      return {
        ...state,
        showNewProduct: false,
      };
    case "hideEditProduct":
      return {
        ...state,
        showEditProduct: false,
      };
    case "getProductStatusSuccess":
      return {
        ...state,
        loading: false,
        error: false,
        status: action.payload,
      };
    case "createProductSuccess":
      return {
        ...state,
        list: [action.payload, ...state.list],
        showNewProduct: false,
        loading: false,
        success: true,
        message: "Producto creado correctamente",
        errors: { ...PRODUCTS_INITIAL_STATE.errors },
      };
    case "createProductError":
    case "updateProductError":
    case "createPaymentFrequencyProductAmountError":
    case "updatePaymentFrequencyProductAmountError":
      return {
        ...state,
        loading: false,
        errors: { ...PRODUCTS_INITIAL_STATE.errors, ...action.payload.errors },
        message: action.payload.message || "",
      };
    case "savePriceError":
      return {
        ...state,
        loading: false,
        errors: { ...PRODUCTS_INITIAL_STATE.errors, ...action.payload.errors },
      };
    case "updateProductSuccess":
      return {
        ...state,
        loading: false,
        success: true,
        message: "Producto modificado correctamente",
        list: [
          ...state.list.map((product) =>
            product.id !== action.payload.id ? product : action.payload
          ),
        ],
      };
    case "hideAlert":
      return {
        ...state,
        success: false,
      };
    case "hideErrorAlert": {
      return {
        ...state,
        error: false,
      };
    }
    case "showCreatePrice":
      return {
        ...state,
        price: PRODUCTS_INITIAL_STATE.price,
        showCreatePrice: true,
        showEditProduct: false,
      };
    case "showEditPrice":
      return {
        ...state,
        showCreatePrice: true,
        showEditProduct: false,
      };
    case "hideCreatePrice":
      return {
        ...state,
        showEditProduct: true,
        showCreatePrice: false,
      };
    case "createPriceSuccess":
      return {
        ...state,
        loading: false,
        error: false,
        success: true,
        message: "El precio de financiamiento se registro correctamente",
        showCreatePrice: false,
        showEditProduct: true,
        product: {
          ...state.product,
          prices: [...state.product.prices, action.payload],
        },
      };
    case "updatePriceSuccess":
      return {
        ...state,
        loading: false,
        error: false,
        success: true,
        message: "El precio de financiamiento se modificó correctamente",
        showCreatePrice: false,
        showEditProduct: true,
        product: {
          ...state.product,
          prices: state.product.prices.map((price) =>
            price.id !== action.payload.id ? price : action.payload
          ),
        },
      };
    case "forbiddenRequest":
      return {
        ...state,
        loading: false,
        error: true,
        message: action.payload,
        errors: { ...PRODUCTS_INITIAL_STATE.errors },
      };
    case "createPaymentFrequency":
      return {
        ...state,
        showPaymentFrequencyForm: true,
        showEditProduct: false,
        paymentFrequencyAmount: PRODUCTS_INITIAL_STATE.paymentFrequencyAmount,
      };
    case "updatePaymentFrequency":
      return {
        ...state,
        showPaymentFrequencyForm: true,
        showEditProduct: false,
      };
    case "hidePaymentFrequencyForm":
      return {
        ...state,
        showEditProduct: true,
        showPaymentFrequencyForm: false,
      };
    case "getPaymentFrequencyProductAmountError":
      return {
        ...state,
        loading: false,
      };
    case "getPaymentFrequencyProductAmountSuccess": {
      return {
        ...state,
        loading: false,
      };
    }
    case "createPaymentFrequencyProductAmountSuccess":
      return {
        ...state,
        loading: false,
        errors: { ...PRODUCTS_INITIAL_STATE.errors },
        product: {
          ...state.product,
          paymentFrequencyAmounts: [
            ...state.product.paymentFrequencyAmounts,
            action.payload.data,
          ],
        },
        showPaymentFrequencyForm: false,
        showEditProduct: true,
        success: true,
        message: action.payload.message,
      };
    case "updatePaymentFrequencyProductAmountSuccess":
      return {
        ...state,
        loading: false,
        errors: { ...PRODUCTS_INITIAL_STATE.errors },
        product: {
          ...state.product,
          paymentFrequencyAmounts: state.product.paymentFrequencyAmounts.map(
            (item) =>
              item.id !== action.payload.data.id ? item : action.payload.data
          ),
        },
        showPaymentFrequencyForm: false,
        showEditProduct: true,
        success: true,
        message: action.payload.message,
      };
    case "getFinancementsSuccesss":
      return {
        ...state,
        loading: false,
        financements: action.payload,
      };
    case "setPaymentFrequencyAmount":
      return {
        ...state,
        paymentFrequencyAmount: action.payload,
      };
    case "resetPaymentFrequencyAmount":
      return {
        ...state,
        paymentFrequencyAmount: PRODUCTS_INITIAL_STATE.paymentFrequencyAmount,
      };
    case "onChangeAnnexedError":
      return {
        ...state,
        loading: false,
        error: true,
        message: "No fue posible actualizar el anexo del producto",
      };
    case "onChangeAnnexedSuccess":
      return {
        ...state,
        loading: false,
        product: {
          ...state.product,
          annexeds: state.product.annexeds.map((annex) =>
            annex.id === action.payload.id ? action.payload : annex
          ),
        },
      };
    case "getPriceListSuccess":
      return {
        ...state,
        loading: false,
        priceLists: action.payload,
      };
    case "getPaymentFrequenciesSuccess":
      return {
        ...state,
        loading: false,
        paymentFrequencies: action.payload,
      };
    default:
      return state;
  }
};

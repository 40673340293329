import { PaymentFrequency } from "../interfaces/paymentFrequency.interfaces";
import { PaymentFrequeciesAcionTypes } from "../types/paymentFrequencies.types";

export interface PaymentFrequencyState {
  list: PaymentFrequency[];
  loading: boolean;
}

export const PAYMENT_FREQUENCIES_INITIAL_STATE: PaymentFrequencyState = {
  list: [],
  loading: false,
};

export const paymentFrequenciesReducer = (
  state = PAYMENT_FREQUENCIES_INITIAL_STATE,
  action: PaymentFrequeciesAcionTypes
): PaymentFrequencyState => {
  switch (action.type) {
    case "getPaymentFrequencies":
      return {
        ...state,
        loading: true,
      };
    case "getPaymentFrequenciesSucess":
      return {
        ...state,
        loading: false,
        list: action.payload,
      };
    case "getPaymentFrequenciesError":
      return {
        ...state,
        loading: false,
      };
    default:
      return state;
  }
};

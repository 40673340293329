import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
  Button,
  Grid,
  Switch,
  TextField,
  Typography,
} from "@material-ui/core";
import WorkIcon from "@material-ui/icons/Work";
import ExpandMoreIcon from "@material-ui/icons/ExpandMore";
import { useContext } from "react";
import { RolesContext } from "../../contexts/RolesContext";
import { v4 as uuid } from "uuid";
import { useRolesStyles } from "./styles";

export const RoleForm = () => {
  const classes = useRolesStyles();

  const {
    role,
    apps,
    errors,
    hasError,
    onChange,
    onSubmit,
    expanded,
    handleChange,
    onChangePermission,
    hasPermission,
  } = useContext(RolesContext);

  return (
    <Grid>
      <form onSubmit={onSubmit}>
        <Grid container spacing={2}>
          <Grid item xs={12} container direction="row">
            <WorkIcon className={classes.icon} />
            <TextField
              label="Nombre del puesto"
              style={{ flexGrow: 1 }}
              name="name"
              value={role.name}
              onChange={onChange}
              error={hasError("name")}
              helperText={errors.name}
            />
          </Grid>
          <Grid item xs={12}>
            <TextField
              label="Descripción"
              fullWidth
              name="comment"
              value={role.comment}
              onChange={onChange}
              error={hasError("comment")}
              helperText={errors.comment}
            />
          </Grid>
          <Grid item container justifyContent="flex-end">
            <Button variant="contained" color="primary" type="submit">
              Guardar
            </Button>
          </Grid>
        </Grid>
      </form>
      <Grid container spacing={2} className={classes.appsContainer}>
        <Grid item xs={12}>
          <Typography className={classes.permissionsTitle}>
            Actualizar permisos
          </Typography>
        </Grid>
        {apps.map((app) => (
          <Grid item container xs={12} key={`${app.id}-app`}>
            <Grid item container xs={12}>
              <Grid item xs={12}>
                <Typography>{app.name}</Typography>
              </Grid>
              <Grid item xs={12}>
                {app.modules.map((module) => (
                  <Accordion
                    expanded={expanded === `${app.name}-${module.name}`}
                    onChange={handleChange(`${app.name}-${module.name}`)}
                    disabled={module.permissions.length === 0}
                    key={uuid()}
                  >
                    <AccordionSummary
                      expandIcon={<ExpandMoreIcon />}
                      aria-controls="panel1bh-content"
                      id="panel1bh-header"
                    >
                      <Typography className={classes.heading}>
                        {module.name}
                      </Typography>
                    </AccordionSummary>
                    <AccordionDetails>
                      <Grid container spacing={2} justifyContent="center">
                        {module.permissions.map((item) => (
                          <Grid
                            item
                            container
                            direction="row"
                            xs={11}
                            spacing={2}
                            alignItems="center"
                            key={`${item.slug}-${item.id}`}
                          >
                            <Grid item>
                              <Switch
                                size="small"
                                id={item.slug}
                                name={item.name}
                                value={item.id}
                                key={`${item.slug}-${item.id}`}
                                checked={hasPermission(item.slug)}
                                onChange={onChangePermission}
                              />
                            </Grid>
                            <Grid item>
                              <Typography className={classes.heading}>
                                {`${item.name}`}
                              </Typography>
                              <Typography className={classes.secondaryHeading}>
                                {item.description}
                              </Typography>
                            </Grid>
                          </Grid>
                        ))}
                      </Grid>
                    </AccordionDetails>
                  </Accordion>
                ))}
              </Grid>
            </Grid>
          </Grid>
        ))}
      </Grid>
    </Grid>
  );
};

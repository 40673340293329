import { makeStyles } from "@material-ui/core";

export const usePriceModalStyles = makeStyles((theme) => ({
  titleContainer: {
    background: theme.palette.primary.main,
    color: "white",
    fontSize: 16,
    padding: 10,
    textTransform: "uppercase",
  },
}));

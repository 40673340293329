import React, { useContext } from "react";
import {
  Grid,
  TextField,
  Select,
  MenuItem,
  InputLabel,
  FormControl,
  FormHelperText,
} from "@material-ui/core";
import { DiscountRadioButton } from "./DiscountRadioButton";
import { DiscountContext } from "../../../contexts/DiscountsContext";
import { useGeneralStyles } from "../../../theme/generalStyles";

export const DiscountForm = () => {
  const { errors, discount, onChangeDiscount, statuses } =
    useContext(DiscountContext);
  const styles = useGeneralStyles();

  return (
    <Grid container>
      <Grid item container xs={12} spacing={2}>
        <Grid item xs={12}>
          <TextField
            label="Nombre del descuento"
            fullWidth
            name="name"
            value={discount.name}
            onChange={onChangeDiscount}
            error={errors.name.length > 0}
            helperText={errors.name[0]}
          />
        </Grid>
        <Grid item xs={12}>
          <TextField
            label="Cantidad de descuento"
            fullWidth
            name="amount"
            value={discount.amount}
            onChange={onChangeDiscount}
            error={errors.amount.length > 0}
            helperText={errors.amount[0]}
          />
        </Grid>
        <Grid container>
          <FormControl
            className={styles.formControl}
            error={errors.status.length > 0}
          >
            <InputLabel id="label-status">Estatus</InputLabel>
            <Select
              fullWidth
              labelId="label-status"
              value={discount.status}
              onChange={onChangeDiscount}
              name="status"
            >
              <MenuItem value="">Selecciona</MenuItem>
              {statuses.map((item) => (
                <MenuItem value={item.value}>{item.name}</MenuItem>
              ))}
            </Select>
            <FormHelperText className={styles.textErrorForm}>
              {errors.status[0]}
            </FormHelperText>
          </FormControl>
        </Grid>
        <DiscountRadioButton />
      </Grid>
    </Grid>
  );
};

import { createContext, useReducer } from "react";
import { useAuth, UseAuthProps } from "../hooks/useAuth";
import { ProviderProps } from "../interfaces/common.interfaces";
import {
  authReducer,
  AuthState,
  AUTH_INITIAL_STATE,
} from "../reducers/auth.reducer";

export interface ContextProps extends UseAuthProps, AuthState {}

export const AuthContext = createContext({} as ContextProps);

export const AuthProvider = ({ children }: ProviderProps) => {
  const initialState = AUTH_INITIAL_STATE;

  const [state, dispatch] = useReducer(authReducer, initialState);

  const authContext = useAuth(state, dispatch);

  return (
    <AuthContext.Provider value={{ ...state, ...authContext }}>
      {children}
    </AuthContext.Provider>
  );
};

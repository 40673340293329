import { ChangeEvent, Dispatch, FormEvent, useState } from "react";
import { useHistory } from "react-router";
import { backendApi } from "../../api/backendApi";
import {
  Permission,
  RoleErrors,
  RoleResponse,
} from "../../interfaces/roles.interfaces";
import { RolesState } from "../../reducers/roles.reducer";
import { RolesActionTypes } from "../../types/roles.types";

export interface UseSimpleRoleProps {
  expanded: string | boolean;
  handleChange: (
    panel: string
  ) => (event: ChangeEvent<{}>, isExpanded: boolean) => void;
  getRole: (id: number) => void;
  hasError: (key: keyof RoleErrors) => boolean;
  onChange: (e: ChangeEvent<HTMLInputElement>) => void;
  onSubmit: (event: FormEvent<HTMLFormElement>) => void;
  onChangePermission: (
    event: ChangeEvent<HTMLInputElement>,
    checked: boolean
  ) => void;
  hasPermission: (name: string) => boolean;
}

export const useSimpleRole = (
  state: RolesState,
  dispatch: Dispatch<RolesActionTypes>
): UseSimpleRoleProps => {
  const history = useHistory();
  const [expanded, setExpanded] = useState<string | false>(false);

  const handleChange =
    (panel: string) => (event: ChangeEvent<{}>, isExpanded: boolean) => {
      setExpanded(isExpanded ? panel : false);
    };

  const getRole = async (id: number) => {
    try {
      dispatch({ type: "getRole" });
      const response = await backendApi.get<RoleResponse>(`roles/${id}`);
      dispatch({ type: "getRoleSuccess", payload: response.data.data });
      history.push("/jobs/job");
    } catch (error) {
      dispatch({ type: "getRoleError" });
    }
  };

  const hasError = (key: keyof RoleErrors) => {
    return state.errors[key].length > 0;
  };

  const onChange = (e: ChangeEvent<HTMLInputElement>) => {
    dispatch({
      type: "setRole",
      payload: {
        ...state.role,
        [e.target.name]: e.target.value,
      },
    });
  };

  const saveRole = async () => {
    dispatch({ type: "saveRole" });
    try {
      const response = await backendApi.post<RoleResponse>("roles", state.role);
      dispatch({ type: "saveRoleSuccess", payload: response.data.data });
    } catch (error: any) {
      dispatch({
        type: "saveRoleError",
        payload: error.response.data.errors,
      });
    }
  };

  const updateRole = async () => {
    dispatch({ type: "saveRole" });
    try {
      const response = await backendApi.patch<RoleResponse>(
        `roles/${state.role.id}`,
        state.role
      );
      dispatch({ type: "updateRoleSuccess", payload: response.data.data });
    } catch (error: any) {
      dispatch({
        type: "saveRoleError",
        payload: error.response.data.errors,
      });
    }
  };

  const onSubmit = (e: FormEvent<HTMLFormElement>) => {
    e.preventDefault();
    state.role.id ? updateRole() : saveRole();
  };

  const addPermission = async (permissionId: string, roleId: number) => {
    try {
      dispatch({ type: "changePermission" });
      const response = await backendApi.post("roles/add-permission", {
        permissionId,
        roleId,
      });
      dispatch({ type: "addPermission", payload: response.data.data });
    } catch (error) {
      dispatch({ type: "changePermissionError" });
    }
  };

  const removePermission = async (permissionId: string, roleId: number) => {
    try {
      dispatch({ type: "changePermission" });
      const response = await backendApi.post("roles/remove-permission", {
        permissionId,
        roleId,
      });
      dispatch({ type: "removePermission", payload: response.data.data });
    } catch (error) {
      dispatch({ type: "changePermissionError" });
    }
  };

  const onChangePermission = (
    e: ChangeEvent<HTMLInputElement>,
    checked: boolean
  ) => {
    const { value } = e.target;
    const { id } = state.role;
    console.log(value);

    checked ? addPermission(value, id!) : removePermission(value, id!);
  };

  const hasPermission = (slug: string) => {
    return state.role.permissions.some(
      (permission) => permission.slug === slug
    );
  };

  return {
    getRole,
    hasError,
    onChange,
    onSubmit,
    expanded,
    handleChange,
    onChangePermission,
    hasPermission,
  };
};

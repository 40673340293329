import RadioButtonCheckedIcon from "@material-ui/icons/RadioButtonChecked";
import { Grid, makeStyles } from "@material-ui/core";
import { ProductShortStatus } from "../../interfaces/products.interfaces";

interface Props {
  status: ProductShortStatus;
  label: string;
}
export const ProductStatusIcon = ({ status, label }: Props) => {
  const classes = useStyles();
  return (
    <Grid className={classes.container}>
      <Grid
        item
        xs={12}
        container
        direction="row"
        alignItems="center"
        style={{ minWidth: "100%" }}
        className={classes[status]}
      >
        <RadioButtonCheckedIcon className={classes.icon} />
        {label}
      </Grid>
    </Grid>
  );
};

const useStyles = makeStyles((theme) => ({
  container: {
    minWidth: 100,
  },
  V: {
    color: theme.palette.primary.main,
  },
  C: {
    color: theme.palette.grey[900],
  },
  icon: {
    marginLeft: 10,
  },
}));

import {
  TextField,
  Avatar,
  Select,
  FormControl,
  InputLabel,
  MenuItem,
  FormHelperText,
  IconButton,
  Grid,
  Typography,
} from "@material-ui/core";
import CameraAltIcon from "@material-ui/icons/CameraAlt";
import DeleteIcon from "@material-ui/icons/Delete";
import { useContext } from "react";
import { remoteImages } from "../../api/backendApi";
import { EstablishmentsContext } from "../../contexts/EstablishmentsContext";
import { PriceListContext } from "../../contexts/PriceListContext";
import { CustomSubmit } from "../Common/ActionButtons/CustomSubmit";
import { useSideBarStyles } from "../SideBar/styles";

export const EstablishmentForm = () => {
  const classes = useSideBarStyles();
  const {
    hasError,
    establishment,
    deleteImage,
    onSubmit,
    handleImageUpload,
    onChange,
    image,
    errors,
    states,
  } = useContext(EstablishmentsContext);

  const { list: priceLists } = useContext(PriceListContext);

  const changeImageButton = (
    <IconButton onClick={deleteImage}>
      <DeleteIcon />
    </IconButton>
  );

  return (
    <form onSubmit={onSubmit}>
      <Grid container spacing={2}>
        <Grid item xs={12}>
          <Avatar
            className={`${classes.large} d-flex alig-items-center`}
            src={
              establishment.image === null
                ? image?.toString()
                : `${remoteImages}${establishment.image}`
            }
          >
            <input
              accept="image/*"
              className="d-none"
              id="contained-button-file"
              name="contained-button-file"
              type="file"
              onChange={handleImageUpload}
            />
            <label htmlFor="contained-button-file">
              <CameraAltIcon />
            </label>
          </Avatar>
          {establishment.image === null && image === ""
            ? ""
            : changeImageButton}
        </Grid>
        <Grid item xs={12}>
          <TextField
            error={hasError("name")}
            helperText={errors.name}
            label="Nombre"
            fullWidth
            name="nombre"
            value={establishment.name}
            onChange={onChange}
          />
        </Grid>
        <Grid item xs={12}>
          <TextField
            error={hasError("code")}
            helperText={errors.code[0]}
            label="Código"
            fullWidth
            name="cod_estab"
            value={establishment.code}
            onChange={onChange}
          />
        </Grid>
        <Grid item xs={12}>
          <Typography className="branch-office-form-tittle">
            Información
          </Typography>
        </Grid>
        <Grid item xs={12}>
          <TextField
            error={hasError("street")}
            helperText={errors.street}
            label="Calle"
            fullWidth
            name="street"
            value={establishment.street}
            onChange={onChange}
          />
        </Grid>
        <Grid item xs={12}>
          <TextField
            error={hasError("number")}
            helperText={errors.number}
            label="Numero"
            fullWidth
            name="number"
            value={establishment.number}
            onChange={onChange}
          />
        </Grid>
        <Grid item xs={12}>
          <TextField
            error={hasError("suburb")}
            helperText={errors.suburb}
            label="Colonia"
            fullWidth
            name="suburb"
            value={establishment.suburb}
            onChange={onChange}
          />
        </Grid>
        <Grid item xs={12}>
          <TextField
            error={hasError("township")}
            helperText={errors.township}
            label="Delegación/Municipio"
            fullWidth
            name="township"
            value={establishment.township}
            onChange={onChange}
          />
        </Grid>
        <Grid item xs={12}>
          <TextField
            error={hasError("postalCode")}
            helperText={errors.postalCode}
            label="CP"
            fullWidth
            name="postalCode"
            value={establishment.postalCode}
            onChange={onChange}
          />
        </Grid>
        <Grid item xs={12}>
          <TextField
            error={hasError("city")}
            helperText={errors.city}
            label="Ciudad"
            fullWidth
            name="ciudad"
            value={establishment.city}
            onChange={onChange}
          />
        </Grid>
        <Grid item xs={12}>
          <FormControl fullWidth error={hasError("state")}>
            <InputLabel id="demo-simple-select-label">Estado</InputLabel>
            <Select
              name="state"
              value={establishment.state}
              onChange={onChange}
            >
              {states.map((item) => (
                <MenuItem value={item.id} key={item.id}>
                  {item.name}
                </MenuItem>
              ))}
            </Select>
            <FormHelperText>{errors.state}</FormHelperText>
          </FormControl>
        </Grid>
        <Grid item xs={12}>
          <TextField
            error={hasError("country")}
            helperText={errors.country}
            id="country"
            label="País"
            fullWidth
            name="country"
            value={establishment.country}
            onChange={onChange}
          />
        </Grid>
        <Grid item xs={12}>
          <FormControl fullWidth error={hasError("priceList")} required>
            <InputLabel id="demo-simple-select-label">
              Lista de precios
            </InputLabel>
            <Select
              labelId="demo-simple-select-label"
              id="priceList"
              name="priceList"
              value={establishment.priceList}
              onChange={onChange}
            >
              {priceLists.map((item) => (
                <MenuItem key={`${item.id}-price`} value={item.id}>
                  {item.name}
                </MenuItem>
              ))}
            </Select>
            <FormHelperText>{errors.priceList}</FormHelperText>
          </FormControl>
        </Grid>
        <Grid item xs={12}>
          <CustomSubmit label="Guardar" />
        </Grid>
      </Grid>
    </form>
  );
};

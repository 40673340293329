import {
  Grid,
  TextField,
  Select,
  MenuItem,
  InputLabel,
  FormControl,
  FormHelperText,
} from "@material-ui/core";
import { useContext, useEffect } from "react";
import { CivilStatusContext } from "../../contexts/CivilStatusContext";

export const CivilStatusForm = () => {
  const { civilStatus, errors, status, onChange, loadStatus } =
    useContext(CivilStatusContext);

  useEffect(() => {
    loadStatus();
  }, []);

  return (
    <Grid container spacing={2}>
      <Grid item container xs={12}>
        <FormControl fullWidth>
          <TextField
            label="Nombre"
            fullWidth
            name="name"
            value={civilStatus.name}
            onChange={onChange}
            error={errors.name.length > 0}
            helperText={errors.name[0]}
          />
        </FormControl>
      </Grid>
      <Grid item container xs={12}>
        <FormControl fullWidth error={errors.status.length > 0}>
          <InputLabel id="label-status">Estatus</InputLabel>
          <Select
            fullWidth
            labelId="label-status"
            value={civilStatus.status}
            onChange={onChange}
            name="status"
          >
            <MenuItem value="">Selecciona</MenuItem>
            {status.map((item) => (
              <MenuItem value={item.value}>{item.name}</MenuItem>
            ))}
          </Select>
          <FormHelperText>{errors.status[0]}</FormHelperText>
        </FormControl>
      </Grid>
    </Grid>
  );
};

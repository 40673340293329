import { createContext, useReducer } from "react";
import { CustomAlert } from "../components/Common/Alert/Alert";
import { LoadingSpinner } from "../components/LoadingSpinner/LoadingSpinner";
import {
  useServiceTypes,
  UseServiceTypesProps,
} from "../hooks/serviceTypes/useServiceTypes";
import { ProviderProps } from "../interfaces/common.interfaces";
import {
  serviceTypesReducer,
  ServiceTypesState,
  SERVICETYPE_INITIAL_STATE,
} from "../reducers/serviceTypes.reducer";

interface ContexProps extends ServiceTypesState, UseServiceTypesProps {}

export const ServiceTypesContext = createContext({} as ContexProps);

export const ServiceTypesProvider = ({ children }: ProviderProps) => {
  const initialsState = SERVICETYPE_INITIAL_STATE;

  const [state, dispatch] = useReducer(serviceTypesReducer, initialsState);
  const context = useServiceTypes(state, dispatch);
  return (
    <ServiceTypesContext.Provider value={{ ...state, ...context }}>
      {children}
      <LoadingSpinner loading={state.loading} />
      <CustomAlert
        show={state.success}
        message={state.message}
        handleClose={() => dispatch({ type: "hideAlert" })}
      />
    </ServiceTypesContext.Provider>
  );
};

import { useContext } from "react";
import { ServiceTypesContext } from "../../contexts/ServiceTypesContext";
import { ServiceType } from "../../interfaces/serviceTypes.interfaces";
import { CustomIconButton } from "../Common/ActionButtons/CustomIconButton";
import { CustomTableCell } from "../Common/PaginatedTableWrapper/CustomTableCell";
import { CustomTableRow } from "../Common/PaginatedTableWrapper/CustomTableRow";
import { ServiceTypeStatusIcon } from "./ServiceTypeStatusIcon";

interface Props {
  type: ServiceType;
}

export const ServiceTypeItem = ({ type }: Props) => {
  const { setType } = useContext(ServiceTypesContext);

  return (
    <CustomTableRow>
      <CustomTableCell hiddenMobile>{type.id!}</CustomTableCell>
      <CustomTableCell>{type.name}</CustomTableCell>
      <CustomTableCell>
        <ServiceTypeStatusIcon type={type.status} label={type.label} />
      </CustomTableCell>
      <CustomTableCell style={{ margin: 0, padding: 0 }}>
        <CustomIconButton onClick={() => setType(type.id!)} iconType="edit" />
      </CustomTableCell>
    </CustomTableRow>
  );
};

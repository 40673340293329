import { createContext, useReducer } from "react";
import {
  usePriceLists,
  UsePriceListsProps,
} from "../hooks/priceLists/usePriceLists";
import { ProviderProps } from "../interfaces/common.interfaces";
import {
  priceListsReducer,
  PriceListsState,
  PRICE_LIST_INITIAL_STATE,
} from "../reducers/priceListsReducer";

interface ContextProps extends PriceListsState, UsePriceListsProps {}

export const PriceListContext = createContext({} as ContextProps);

export const PriceListProvider = ({ children }: ProviderProps) => {
  const initialsState = PRICE_LIST_INITIAL_STATE;

  const [state, dispatch] = useReducer(priceListsReducer, initialsState);

  const priceListContext = usePriceLists(state, dispatch);

  return (
    <PriceListContext.Provider value={{ ...state, ...priceListContext }}>
      {children}
    </PriceListContext.Provider>
  );
};

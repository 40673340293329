import { makeStyles } from "@material-ui/core";

export const useProductModalStyles = makeStyles((theme) => ({
  headerContainer: {
    background: "white",
  },
  headerTitle: {
    background: theme.palette.primary.main,
    color: "white",
    width: "100%",
  },
  dataContianer: {
    overflowY: "hidden",
  },
  itemContainer: {
    paddingRight: 10,
    paddingLeft: 10,
    marginBottom: 10,
  },
  icon: {
    color: theme.palette.success.main,
  },
  priceTabProductTitle: {
    fontSize: 16,
    fontWeight: "bold",
  },
}));

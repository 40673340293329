import {
  DialogActions,
  DialogContent,
  DialogTitle,
  Dialog,
  Typography,
} from "@material-ui/core";
import { useContext } from "react";
import { CivilStatusContext } from "../../contexts/CivilStatusContext";
import { CustomActionButton } from "../Common/ActionButtons/CustomActionButton";
import { SecondaryActionButton } from "../Common/ActionButtons/SecondaryActionButton";
import { CivilStatusForm } from "./CivilStatusForm";

export const CivilStatusModal = () => {
  const { showModal, handleClose, onSubmit } = useContext(CivilStatusContext);
  return (
    <Dialog open={showModal} onClose={handleClose} maxWidth="sm" fullWidth>
      <DialogTitle id="alert-dialog-title">
        <Typography>Nuevo Estado Civil</Typography>
      </DialogTitle>
      <DialogContent>
        <CivilStatusForm />
      </DialogContent>
      <DialogActions>
        <CustomActionButton onClick={onSubmit} />
        <SecondaryActionButton onClick={handleClose} />
      </DialogActions>
    </Dialog>
  );
};

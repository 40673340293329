import { ChangeEvent, FormEvent, useContext } from "react";
import {
  Grid,
  TextField,
  Select,
  MenuItem,
  InputLabel,
  FormControl,
  FormHelperText,
} from "@material-ui/core";
import { useProductModalStyles } from "../EditModal/styles";
import { ProductsContext } from "../../../contexts/ProductsContext";
import { CustomSubmit } from "../../Common/ActionButtons/CustomSubmit";
import { SecondaryActionButton } from "../../Common/ActionButtons/SecondaryActionButton";
import { ServiceTypesContext } from "../../../contexts/ServiceTypesContext";

interface Props {
  onSubmit: (e: FormEvent<HTMLFormElement>) => void;
  cancelAction?: () => void;
}

export const ProductForm = ({ onSubmit, cancelAction }: Props) => {
  const modalClasses = useProductModalStyles();

  const { errors, status, product, onChangeProduct } =
    useContext(ProductsContext);

  const { serviceTypes } = useContext(ServiceTypesContext);

  return (
    <form onSubmit={onSubmit}>
      <Grid container spacing={2}>
        <Grid item xs={12} className={modalClasses.itemContainer}>
          <TextField
            label="Nombre"
            fullWidth
            name="name"
            value={product.name}
            onChange={(e: ChangeEvent<HTMLInputElement>) => onChangeProduct(e)}
            error={errors.name.length > 0}
            helperText={errors.name[0]}
          />
        </Grid>
        <Grid xs={12} className={modalClasses.itemContainer}>
          <FormControl fullWidth error={errors.typeId.length > 0}>
            <InputLabel id="label-tipo_homenaje">Tipo de homenaje</InputLabel>
            <Select
              labelId="label-tipo_homenaje"
              value={product.typeId}
              onChange={(e: any) => onChangeProduct(e)}
              name="typeId"
            >
              <MenuItem value="">Selecciona</MenuItem>
              {serviceTypes.map((item) => (
                <MenuItem value={item.id}>{item.name}</MenuItem>
              ))}
            </Select>
            <FormHelperText>{errors.typeId[0]}</FormHelperText>
          </FormControl>
        </Grid>
        <Grid xs={12} className={modalClasses.itemContainer}>
          <FormControl fullWidth>
            <InputLabel id="label-status">Estatus</InputLabel>
            <Select
              labelId="label-status"
              value={product.status}
              onChange={(e: any) => onChangeProduct(e)}
              name="status"
            >
              <MenuItem value="">Selecciona</MenuItem>
              {status.map((item) => (
                <MenuItem value={item.value}>{item.label}</MenuItem>
              ))}
            </Select>
            <FormHelperText>{errors.status}</FormHelperText>
          </FormControl>
        </Grid>
        <Grid
          xs={12}
          container
          justifyContent="flex-end"
          className={modalClasses.itemContainer}
        >
          <CustomSubmit label="Guardar" />
          {cancelAction && (
            <SecondaryActionButton
              onClick={cancelAction}
              styles={{ marginLeft: 10 }}
            />
          )}
        </Grid>
      </Grid>
    </form>
  );
};

import { useContext } from "react";
import {
  DialogContent,
  DialogTitle,
  Dialog,
  Typography,
} from "@material-ui/core";
import { ProductsContext } from "../../contexts/ProductsContext";
import { ProductForm } from "./Form/ProductForm";

export const CreateProductModal = () => {
  const { showNewProduct, onSubmitNew, dispatch } = useContext(ProductsContext);

  return (
    <Dialog
      open={showNewProduct}
      onClose={() => dispatch({ type: "hideNewProduct" })}
      maxWidth="sm"
      fullWidth
    >
      <DialogTitle id="alert-dialog-title">
        <Typography>Nuevo Homenaje</Typography>
      </DialogTitle>
      <DialogContent>
        <ProductForm
          onSubmit={onSubmitNew}
          cancelAction={() => dispatch({ type: "hideNewProduct" })}
        />
      </DialogContent>
    </Dialog>
  );
};

import { Typography } from "@material-ui/core";
import { makeStyles } from "@material-ui/styles";
import { useContext } from "react";
import { RolesContext } from "../../contexts/RolesContext";
import { CustomAppBar } from "../Common/CustomAppBar/CustomAppBar";

const styles = makeStyles((theme) => ({
  roleName: {
    fontSize: 18,
    color: "black",
    fontWeight: "bold",
  },
}));
export const SimpleRoleAppBarContent = () => {
  const classes = styles();

  const { role } = useContext(RolesContext);

  return (
    <CustomAppBar path="/jobs" visibleBAckIcon>
      <Typography className={classes.roleName}>
        {role.id ? role.name : "Nuevo puesto de trabajo"}
      </Typography>
    </CustomAppBar>
  );
};

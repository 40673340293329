import { Grid, Typography } from "@material-ui/core";
import { useContext, useEffect } from "react";
import { ContentWrapper } from "../../../components/Common/ContentWrapper/ContentWrapper";
import { CustomAppBar } from "../../../components/Common/CustomAppBar/CustomAppBar";
import { UserForm } from "../../../components/User/UserForm/UserForm";
import { UsersContext } from "../../../contexts/UsersContext";
import { useUserViewStyles } from "./style";

export const NewUserView = () => {
  const classes = useUserViewStyles();
  const { dispatch, phonesHandler, addressesHandler } =
    useContext(UsersContext);

  useEffect(() => {
    dispatch({ type: "resetUser" });
    addressesHandler.dispatch({ type: "setAddresses", payload: [] });
    phonesHandler.dispatch({ type: "setPhones", payload: [] });
  }, []);

  return (
    <ContentWrapper
      defaultPaddingTop
      appBarContent={
        <CustomAppBar visibleBAckIcon path="/users">
          <Typography className={classes.userName}>Nuevo usuario</Typography>
        </CustomAppBar>
      }
    >
      <Grid container justify="center">
        <Grid item xs={11} lg={8}>
          <UserForm />
        </Grid>
      </Grid>
    </ContentWrapper>
  );
};

import {
  Dialog,
  DialogActions,
  DialogContent,
  Grid,
  Typography,
} from "@material-ui/core";
import { CustomActionButton } from "../ActionButtons/CustomActionButton";
import { SecondaryActionButton } from "../ActionButtons/SecondaryActionButton";
import { IconMessageDialog } from "./CustomIconMessageDialog";
import { useMessageDialogStyles } from "./styles";

interface Props {
  open: boolean;
  title?: string;
  message: string;
  actionTitle?: string;
  closeTitle?: string;
  handleClose: () => void;
  action?: () => void;
  secondaryAction?: () => void;
}
export const MessageDialog = ({
  open,
  title,
  message,
  handleClose,
  action,
  actionTitle,
  closeTitle,
  secondaryAction,
}: Props) => {
  const classes = useMessageDialogStyles();
  return (
    <Dialog
      open={open}
      onClose={handleClose}
      maxWidth="sm"
      fullWidth
      title={title}
    >
      <DialogContent>
        <Grid container justifyContent="center" alignItems="center">
          <Grid item container justifyContent="center" alignItems="center">
            <IconMessageDialog />
            <Typography className={classes.message}>{message}</Typography>
          </Grid>
        </Grid>
      </DialogContent>
      <DialogActions>
        {action && (
          <CustomActionButton
            label={actionTitle || "Guardar"}
            onClick={action}
          />
        )}
        <SecondaryActionButton
          label={closeTitle || "Cerrar"}
          onClick={secondaryAction || handleClose}
        />
      </DialogActions>
    </Dialog>
  );
};

import { Dispatch } from "react";
import { backendApi } from "../../api/backendApi";
import { PhoneTypesResponse } from "../../interfaces/phoneTypes.interfaces";
import { PhoneTypesState } from "../../reducers/phoneTypes.reducer";
import { PhoneTypesActionTypes } from "../../types/phoneTypes.types";

export interface UsePhoneTypesProps {
  getPhoneTypes: () => void;
}

export const usePhoneTypes = (
  state: PhoneTypesState,
  dispatch: Dispatch<PhoneTypesActionTypes>
): UsePhoneTypesProps => {
  const getPhoneTypes = async () => {
    if (state.list.length === 0) {
      dispatch({ type: "getPhoneTypes" });
      try {
        const response = await backendApi.get<PhoneTypesResponse>(
          "phone-types"
        );
        dispatch({ type: "getPhoneTypesSucccess", payload: response.data });
      } catch (error) {
        dispatch({ type: "getPhoneTypesError" });
      }
    }
  };

  return { getPhoneTypes };
};

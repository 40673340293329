import { ChangeEvent, Dispatch } from "react";
import { backendApi } from "../../api/backendApi";
import { Folio, FolioResponse } from "../../interfaces/transactions.interfaces";
import { TransactionsState } from "../../reducers/transaction.reducer";
import { TransactionActionTypes } from "../../types/transactions.types";

export interface UseFoliosProps {
  setFolio: (id: number) => void;
  cancelSaveFolio: () => void;
  showNewFolio: () => void;
  onSubmit: () => void;
  onChangeFolio: (e: ChangeEvent<{ name?: string; value: unknown }>) => void;
  showDeleteFolio: (id: number) => void;
  cancelDeleteFolio: () => void;
  deleteFolio: () => void;
}

export const useFolios = (
  state: TransactionsState,
  dispatch: Dispatch<TransactionActionTypes>
): UseFoliosProps => {
  const setFolio = (id: number) => {
    const payload = state.transaction.folios.find((item) => item.id === id);
    if (payload) {
      dispatch({ type: "editFolio", payload });
    }
  };

  const cancelSaveFolio = () => {
    dispatch({ type: "cancelSaveFolio" });
  };

  const showNewFolio = () => {
    dispatch({ type: "createFolio" });
  };

  const updateFolio = async () => {
    try {
      dispatch({ type: "saveFolio" });
      const response = await backendApi.patch<FolioResponse>(
        `folios/${state.folio.id}`,
        state.folio
      );
      dispatch({ type: "updateFolioSuccess", payload: response.data });
    } catch (error: any) {
      dispatch({ type: "saveFolioError", payload: error.response.data.errors });
    }
  };

  const createFolio = async () => {
    try {
      dispatch({ type: "saveFolio" });
      const response = await backendApi.post<FolioResponse>(
        "folios",
        state.folio
      );
      dispatch({ type: "saveFolioSuccess", payload: response.data });
    } catch (error: any) {
      if (error.response.status === 422) {
        dispatch({
          type: "saveFolioError",
          payload: error.response.data.errors,
        });
      } else {
        dispatch({ type: "badRequest", payload: error.response.data.message });
      }
    }
  };

  const onSubmit = () => {
    if (state.folio.id) {
      updateFolio();
    } else {
      createFolio();
    }
  };

  const onChangeFolio = (e: ChangeEvent<{ name?: string; value: unknown }>) => {
    dispatch({
      type: "setFolio",
      payload: { ...state.folio, [e.target.name!]: e.target.value },
    });
  };

  const showDeleteFolio = (id: number) => {
    const payload = state.transaction.folios.find((item) => item.id === id);
    if (payload) {
      dispatch({ type: "showDeleteFolioModal", payload });
    }
  };

  const cancelDeleteFolio = () => {
    dispatch({ type: "cancelDeleteFolio" });
  };

  const deleteFolio = async () => {
    try {
      dispatch({ type: "deleteFolio" });
      const response = await backendApi.delete<FolioResponse>("folios");
      dispatch({ type: "deleteFolioSuccess", payload: response.data });
    } catch (error: any) {
      dispatch({
        type: "deleteFolioError",
        payload: error.response.data.message,
      });
    }
  };

  return {
    setFolio,
    cancelSaveFolio,
    showNewFolio,
    onSubmit,
    onChangeFolio,
    showDeleteFolio,
    cancelDeleteFolio,
    deleteFolio,
  };
};

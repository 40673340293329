import {
  DialogActions,
  DialogContent,
  Grid,
  Table,
  TableBody,
  TableHead,
  TableRow,
  Typography,
} from "@material-ui/core";
import { useContext } from "react";
import { TransactionContext } from "../../contexts/TransactionsContext";
import { CustomActionButton } from "../Common/ActionButtons/CustomActionButton";
import { CustomIconButton } from "../Common/ActionButtons/CustomIconButton";
import { SecondaryActionButton } from "../Common/ActionButtons/SecondaryActionButton";
import { CustomTableCell } from "../Common/PaginatedTableWrapper/CustomTableCell";
import { CustomTableRow } from "../Common/PaginatedTableWrapper/CustomTableRow";
import { useTransactionStyles } from "./styles";

export const FoliosTab = () => {
  const classes = useTransactionStyles();
  const { transaction, setFolio, showNewFolio, showDeleteFolio } =
    useContext(TransactionContext);
  return (
    <>
      <DialogContent>
        <Grid container justifyContent="space-between">
          <Typography className={classes.transactiontitle}>
            {transaction.name}
          </Typography>
          <CustomActionButton
            iconType="add"
            label="Registrar folio"
            onClick={showNewFolio}
          />
        </Grid>
        <Table>
          <TableHead>
            <TableRow>
              {["Establecimiento", "Folio", "Relleno", ""].map((header) => (
                <CustomTableCell>{header}</CustomTableCell>
              ))}
            </TableRow>
          </TableHead>
          <TableBody>
            {transaction.folios.map((folio) => (
              <CustomTableRow
                onLongPressAction={() => showDeleteFolio(folio.id!)}
              >
                <CustomTableCell>{folio.establishmentName}</CustomTableCell>
                <CustomTableCell>{`${folio.folio}`}</CustomTableCell>
                <CustomTableCell>{`${folio.fill}`}</CustomTableCell>
                <CustomTableCell style={{ padding: 0, margin: 0 }}>
                  <CustomIconButton
                    iconType="edit"
                    onClick={() => setFolio(folio.id!)}
                  />
                </CustomTableCell>
              </CustomTableRow>
            ))}
          </TableBody>
        </Table>
      </DialogContent>
      <DialogActions>
        <CustomActionButton onClick={() => {}} />
        <SecondaryActionButton onClick={() => {}} />
      </DialogActions>
    </>
  );
};

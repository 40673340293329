import { createContext, Dispatch, useReducer } from "react";
import { CustomAlert } from "../components/Common/Alert/Alert";
import { ErrorsAlertContainer } from "../components/Common/ErrorContainer/ErrorContainer";
import { LoadingSpinner } from "../components/LoadingSpinner/LoadingSpinner";
import { useProducts, UseProductsProps } from "../hooks/products/useProducts";
import {
  useSimpleProuduct,
  UseSimpleProuductProps,
} from "../hooks/products/useSimpleProduct";
import { ProviderProps } from "../interfaces/common.interfaces";
import {
  productsReducer,
  ProductsState,
  PRODUCTS_INITIAL_STATE,
} from "../reducers/products.reducer";
import { ProductActionTypes } from "../types/products.types";

interface ContextProps
  extends ProductsState,
    UseProductsProps,
    UseSimpleProuductProps {
  dispatch: Dispatch<ProductActionTypes>;
}

export const ProductsContext = createContext({} as ContextProps);

export const ProductsProvider = ({ children }: ProviderProps) => {
  const initialsState = PRODUCTS_INITIAL_STATE;

  const [state, dispatch] = useReducer(productsReducer, initialsState);

  const productsContext = useProducts(state, dispatch);

  const simpleProductContext = useSimpleProuduct(state, dispatch);

  return (
    <ProductsContext.Provider
      value={{
        ...state,
        dispatch,
        ...simpleProductContext,
        ...productsContext,
      }}
    >
      {children}
      <ErrorsAlertContainer
        error={state.error}
        errors={state.errors}
        optionalMessage={state.message}
        hideErrorsAlert={() => dispatch({ type: "hideErrorAlert" })}
      />
      <CustomAlert
        show={state.success}
        message={state.message}
        handleClose={() => dispatch({ type: "hideAlert" })}
      />
      <LoadingSpinner loading={state.loading} />
    </ProductsContext.Provider>
  );
};

import { Dispatch } from "react";
import { backendApi } from "../../api/backendApi";
import { FinancementsResponse } from "../../interfaces/fiancements.interfaces";
import {
  financementsReducer,
  FinancementsState,
  FINANCEMENTS_INITIAL_STATE,
} from "../../reducers/financementsReducer";
import { FinancementsActionTypes } from "../../types/financements.types";

export interface UseFinancementsProps extends FinancementsState {
  getFinancements: () => void;
  loadFinancements: () => void;
}

export const useFinancements = (
  state: FinancementsState,
  dispatch: Dispatch<FinancementsActionTypes>
): UseFinancementsProps => {
  const getFinancements = async () => {
    dispatch({ type: "getFinancments" });
    try {
      const response = await backendApi.get<FinancementsResponse>(
        "financiamiento"
      );
      dispatch({ type: "getFinancementsSuccess", payload: response.data.data });
    } catch (error) {
      dispatch({ type: "getFinancementsError" });
    }
  };

  const loadFinancements = () => {
    if (state.list.length === 0) {
      getFinancements();
    }
  };

  return { ...state, loadFinancements, getFinancements };
};

import { createContext, Dispatch, useReducer } from "react";
import { CustomAlert } from "../components/Common/Alert/Alert";
import { LoadingSpinner } from "../components/LoadingSpinner/LoadingSpinner";
import {
  useDiscounts,
  UseDiscountsProps,
} from "../hooks/discounts/useDiscounts";
import {
  useSimpleDiscount,
  UseSimpleDiscountProps,
} from "../hooks/discounts/useSimpleDiscount";
import { ProviderProps } from "../interfaces/common.interfaces";
import {
  discountsReducer,
  DiscountsState,
  DISCOUNTS_INITIAL_STATE,
} from "../reducers/discounts.reducer";
import { DiscountActionTypes } from "../types/discounts.types";

interface ContextProps
  extends DiscountsState,
    UseDiscountsProps,
    UseSimpleDiscountProps {
  dispatch: Dispatch<DiscountActionTypes>;
}

export const DiscountContext = createContext({} as ContextProps);

export const DiscountsProvider = ({ children }: ProviderProps) => {
  const initialState = DISCOUNTS_INITIAL_STATE;
  const [state, dispatch] = useReducer(discountsReducer, initialState);

  const discountContext = useDiscounts(state, dispatch);

  const simpleDiscountContext = useSimpleDiscount(state, dispatch);

  return (
    <DiscountContext.Provider
      value={{
        ...state,
        dispatch,
        ...discountContext,
        ...simpleDiscountContext,
      }}
    >
      {children}
      <CustomAlert
        show={state.shortSuccess}
        message={state.message}
        handleClose={() => dispatch({ type: "hideAlert" })}
      />
      <LoadingSpinner loading={state.loading} />
    </DiscountContext.Provider>
  );
};

import { ChangeEvent, Dispatch } from "react";
import { backendApi } from "../../api/backendApi";
import { TransactionsResponse } from "../../interfaces/transactions.interfaces";
import { TransactionsState } from "../../reducers/transaction.reducer";
import { TransactionActionTypes } from "../../types/transactions.types";

export interface UseTransactionsProps {
  getTransactions: (path: string, params?: Object) => void;
  loadTransactions: () => void;
  searchFunction: (e: ChangeEvent<HTMLInputElement>) => void;
  clearSearchValue: () => void;
  cancelSave: () => void;
  setTransaction: (id: string) => void;
}

export const useTransactions = (
  state: TransactionsState,
  dispatch: Dispatch<TransactionActionTypes>
): UseTransactionsProps => {
  const getTransactions = async (path: string, params?: Object) => {
    try {
      dispatch({ type: "getTransactions" });
      const response = await backendApi.get<TransactionsResponse>(path, {
        params,
      });
      dispatch({ type: "getTransactionsSuccess", payload: response.data });
    } catch (error) {
      dispatch({ type: "getTransactionsError" });
    }
  };
  const loadTransactions = () => {
    getTransactions("transactions");
  };

  const searchFunction = (e: ChangeEvent<HTMLInputElement>) => {
    dispatch({ type: "setSearchValue", payload: e.target.value });
  };

  const clearSearchValue = () => {
    dispatch({ type: "setSearchValue", payload: "" });
  };

  const setTransaction = (id: string) => {
    const payload = state.list.find((item) => item.id === id);
    if (payload) {
      dispatch({ type: "editTransaction", payload });
    }
  };

  const cancelSave = () => {
    dispatch({ type: "cancelSaveTransaction" });
  };

  return {
    getTransactions,
    loadTransactions,
    searchFunction,
    clearSearchValue,
    setTransaction,
    cancelSave,
  };
};

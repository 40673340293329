import {
  LinksPagination,
  MetaPagination,
} from "../interfaces/pagination.interfaces";
import {
  EstablishmentErrors,
  Estatblishment,
  State,
} from "../interfaces/establishments.interfaces";
import { EstablishmentsActionTypes } from "../types/establishmens.types";

export interface EstablishmentsState {
  loading: boolean;
  list: Estatblishment[];
  links: LinksPagination;
  pagination: MetaPagination;
  searchValue: string;
  establishment: Estatblishment;
  errors: EstablishmentErrors;
  image: string | Blob | null;
  states: State[];
}
export const ESTABLISHMENTS_INITIAL_STATE: EstablishmentsState = {
  loading: false,
  list: [],
  pagination: {
    current_page: 1,
    from: 1,
    to: 10,
    last_page: 0,
    path: "",
    per_page: 10,
    total: 0,
  },
  links: {
    first: undefined,
    last: undefined,
    prev: undefined,
    next: undefined,
  },
  searchValue: "",
  establishment: {
    name: "",
  },
  errors: {
    code: [],
    name: [],
    state: [],
    street: [],
    postalCode: [],
    suburb: [],
    city: [],
    country: [],
    number: [],
    priceList: [],
    township: [],
  },
  image: "",
  states: [],
};

export const establishmentsReducer = (
  state = ESTABLISHMENTS_INITIAL_STATE,
  action: EstablishmentsActionTypes
): EstablishmentsState => {
  switch (action.type) {
    case "getEstablishments":
      return { ...state, loading: true };
    case "getEstablishmentsError":
      return { ...state, loading: false };
    case "getEstablishmentsSuccess":
      return {
        ...state,
        loading: false,
        links: action.payload.links,
        pagination: action.payload.meta,
        list: action.payload.data,
      };
    case "setSearchValue":
      return {
        ...state,
        searchValue: action.payload,
      };
    default:
      return state;
  }
};

import { AuthUser } from "../interfaces/user.interfaces";
import { AuthActionTypes } from "../types/auth.types";

export interface AuthState {
  errors: any;
  error: boolean;
  message: string;
  loading: boolean;
  isAuthenticated: boolean;
  authUser: AuthUser;
}

export const AUTH_INITIAL_STATE: AuthState = {
  loading: false,
  errors: {},
  error: false,
  message: "",
  authUser: {
    usuario: "",
    nombre: "",
    apellidos: "",
    email: "",
    fecha_nacimiento: "",
    sexo: "",
    status: "",
    notas: "",
    rol: "",
    id: 0,
    establecimiento_id: "",
    imagen: null,
    creado_por: "",
    edad: 0,
    fecha_nacimiento_formato: "",
    full_name: "",
    avatar_name: "",
    all_permissions: [],
    permissions: [],
    roles: [],
  },
  isAuthenticated: false,
};

export const authReducer = (
  state = AUTH_INITIAL_STATE,
  action: AuthActionTypes
): AuthState => {
  switch (action.type) {
    case "login":
    case "restoreSession":
      return {
        ...state,
        loading: true,
        error: false,
      };
    case "loginError":
      return {
        ...state,
        loading: false,
        errors: action.payload.errors,
        error: true,
        message: action.payload.message,
      };
    case "loginSuccess":
      return {
        ...state,
        error: false,
        loading: false,
      };
    case "restoreSessionSuccess":
      return {
        ...state,
        error: false,
        loading: false,
        authUser: action.payload,
        isAuthenticated: true,
      };
    case "restoreSessionError":
      return {
        ...state,
        loading: false,
        isAuthenticated: false,
      };
    case "badRequestError":
      return {
        ...state,
        loading: false,
        message: action.payload || "Cominiquese con el administrador",
        error: true,
      };
    default:
      return state;
  }
};

import { useContext, useEffect } from "react";
import { ProductsContext } from "../../contexts/ProductsContext";
import { ServiceTypesContext } from "../../contexts/ServiceTypesContext";
import { ContentWrapper } from "../../components/Common/ContentWrapper/ContentWrapper";
import { PaginatedTableWrapper } from "../../components/Common/PaginatedTableWrapper/PagintatedTableWrapper";
import { HeaderType } from "../../interfaces/pagination.interfaces";
import { ScrollToTopContainer } from "../../components/Common/ScrollToTopContatiner/ScrollToTopContainer";
import { ChildrenFab } from "../../components/Common/ScrollToTopContatiner/ChildrenFab";
import { ProductPaymentFrequencyModal } from "../../components/Products/ProductPaymentFrequencyModal/ProductPaymentFrequencyModal";
import { ProductViewItem } from "../../components/Products/ProductViewItem";
import { CreateProductModal } from "../../components/Products/CreateProductModal";
import { EditProductModal } from "../../components/Products/EditModal/EditProductModal";
import { ProductPriceModal } from "../../components/Products/ProductPriceModal/ProductPriceModal";

const headers: HeaderType[] = [
  { name: "Folio", hiddeMobile: true },
  { name: "Nombre" },
  { name: "Tipo", hiddeMobile: true },
  { name: "Fecha de Alta", hiddeMobile: true },
  { name: "Usuario de alta", hiddeMobile: true },
  { name: "Estatus", hiddeMobile: true },
  { name: "" },
];

export const ProductsView = () => {
  const {
    searchValue,
    getProducts,
    loadProducts,
    loadProductStatus,
    searchFunction,
    resetSearchValue,
    links,
    pagination,
    list,
    showCreateProduct,
  } = useContext(ProductsContext);

  const { loadServicesTypes } = useContext(ServiceTypesContext);

  useEffect(() => {
    loadProducts();
    loadProductStatus();
    loadServicesTypes();
  }, []);

  return (
    <ContentWrapper>
      <PaginatedTableWrapper
        title="Homenajes"
        iconTableSearch
        reloadFunction={loadProducts}
        searchFunction={searchFunction}
        searchValue={searchValue}
        clearSearchValue={resetSearchValue}
        links={links}
        pagination={pagination}
        getData={getProducts}
        headers={headers}
      >
        {list.map((item) => (
          <ProductViewItem product={item} />
        ))}
      </PaginatedTableWrapper>
      <CreateProductModal />
      <EditProductModal />
      <ProductPriceModal />
      <ProductPaymentFrequencyModal />
      <ScrollToTopContainer>
        <ChildrenFab onClick={showCreateProduct} iconType="plus" />
      </ScrollToTopContainer>
    </ContentWrapper>
  );
};

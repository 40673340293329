import { useEffect, ChangeEvent, useReducer, Dispatch } from "react";
import { v4 as uuid } from "uuid";
import { backendApi } from "../api/backendApi";
import { AddressesErrors } from "../interfaces/address.interfaces";
import {
  addressesReducer,
  AddressesState,
  ADDRESSES_INITIAL_STATE,
} from "../reducers/addresses.reducer";
import { AddressesActiontypes } from "../types/addresses.types";

export interface UseAddressesProps extends AddressesState {
  dispatch: Dispatch<AddressesActiontypes>;
  errors: AddressesErrors;
  updateAddresses: (e: any) => void;
  deleteAddress: (index: number) => void;
  onChangeAddress: (
    e: ChangeEvent<{ name?: string; value: unknown; checked: boolean }>,
    index: number
  ) => void;
  updateSub: (e: any) => void;
  deleteSubs: (index: number) => void;
  loadAddressesSubs: () => void;
  onInput: (
    e: ChangeEvent<{ name?: string; value: unknown; checked: boolean }>,
    index: number
  ) => void;
  handleImageUpload: (e: any, index: number) => void;
  deleteImage: (
    e: ChangeEvent<{ name?: string; value: unknown; checked: boolean }>,
    index: number
  ) => void;
  hideAddressImageModal: () => void;
  openAddressModalImage: (e: any, path: string, hasObject?: boolean) => void;
  openUsePreviousAddress: () => void;
  onChangeSelectedPreviousAddress: (e: any) => void;
  hasError: (key: keyof AddressesErrors) => boolean;
}

export const useAddresses = (): UseAddressesProps => {
  const initialState = ADDRESSES_INITIAL_STATE;
  const [state, dispatch] = useReducer(addressesReducer, initialState);

  const updateAddresses = () => {
    const { newAddress } = state;
    dispatch({
      type: "setAddresses",
      payload: [newAddress, ...state.addresses],
    });
    dispatch({ type: "setImagesRoutes", payload: [""] });
  };

  const deleteAddress = (index: number) => {
    const { images, imagesRoutes, addresses } = state;

    addresses.splice(index, 1);
    images.splice(index, 1);
    imagesRoutes.splice(index, 1);

    dispatch({ type: "setImages", payload: images });
    dispatch({ type: "setImagesRoutes", payload: imagesRoutes });
    dispatch({ type: "setAddresses", payload: addresses });
  };

  const onChangeAddress = (
    e: ChangeEvent<{ name?: string; value: unknown; checked: boolean }>,
    index: number
  ) => {
    const { name, value } = e.target;
    const { addresses } = state;
    if (name === "skipAddressNumber") {
      addresses[index] = { ...addresses[index], [name]: e.target.checked };
      addresses[index] = {
        ...addresses[index],
        number: "",
        internalNumber: "",
      };
    } else {
      addresses[index] = { ...addresses[index], [name!]: value };
    }
    dispatch({ type: "setAddresses", payload: addresses });
  };

  const updateSub = () =>
    dispatch({ type: "setSubs", payload: [...state.subs, []] });

  const loadSubs = async (postalCode: string, index: number) => {
    try {
      dispatch({ type: "setLoadingSub", payload: true });
      dispatch({ type: "setLoadingSubIndex", payload: index });
      const { subs } = state;
      dispatch({ type: "setSubs", payload: subs });
      const response = await backendApi.get(
        `suburbs/by-postal-code/${postalCode}`
      );
      subs[index] = response.data.data;
      dispatch({ type: "setSubs", payload: subs });
      dispatch({ type: "setLoadingSubIndex", payload: 0 });
      dispatch({ type: "setLoadingSub", payload: false });
    } catch (error) {
      dispatch({ type: "setLoadingSubIndex", payload: 0 });
      dispatch({ type: "setLoadingSub", payload: false });
    }
  };

  const deleteSubs = (index: number) => {
    const { subs } = state;
    subs.splice(index, 1);
    dispatch({ type: "setSubs", payload: subs });
  };

  const loadAddressesSubs = () => {
    state.addresses.forEach((item, index) => {
      if (item.postalCode) {
        loadSubs(item.postalCode, index);
      }
    });
  };

  const onInput = (
    e: ChangeEvent<{ name?: string; value: unknown; checked: boolean }>,
    index: number
  ) => {
    const { value } = e.target;
    if (e.target && `${value}`.length === 5) {
      loadSubs(`${value}`, index);
    }
  };

  const handleImageUpload = (
    e: ChangeEvent<HTMLInputElement>,
    index: number
  ) => {
    let file;
    if (e.target.files) {
      file = e.target.files[0];
    }
    const { imagesRoutes, addresses } = state;

    const identifier = uuid();
    if (file) {
      const reader = new FileReader();
      reader.onloadend = () => {
        const result = reader.result;
        imagesRoutes[index] = result?.toString() || "";
        dispatch({ type: "setImagesRoutes", payload: imagesRoutes });
        addresses[index].keyFile = identifier;
        addresses[index].hasImage = true;
        dispatch({ type: "setAddresses", payload: addresses });
      };
      dispatch({
        type: "setImages",
        payload: [...state.images, { key: identifier, file }],
      });
      reader.readAsDataURL(file);
    }
  };

  const deleteImage = (
    e: ChangeEvent<{ name?: string; value: unknown; checked: boolean }>,
    index: number
  ) => {
    const { addresses } = state;
    addresses[index].image = "";
    addresses[index].imagePath = "";
    addresses[index].hasImage = false;
    dispatch({ type: "setAddresses", payload: addresses });
  };

  const openAddressModalImage = (
    e: ChangeEvent<{ name?: string; value: unknown; checked: boolean }>,
    path: string,
    hasObject: boolean = false
  ) => {
    dispatch({ type: "setShowAddressImageModal", payload: true });
    dispatch({ type: "setImagePath", payload: path });
    dispatch({ type: "setIsObject", payload: hasObject });
  };

  const hideAddressImageModal = () =>
    dispatch({ type: "setShowAddressImageModal", payload: false });

  const openUsePreviousAddress = () => {
    dispatch({
      type: "setShowPreviousAddress",
      payload: !state.showPreviousAddress,
    });
  };

  const onChangeSelectedPreviousAddress = (e: any) => {
    const { value } = e.target;
    dispatch({ type: "setSelectedPreviousAddress", payload: value });
  };

  const hasError = (key: keyof AddressesErrors) => {
    return state.errors[key].length > 0;
  };

  useEffect(() => {
    const { selectedPreviousAddress } = state;
    if (selectedPreviousAddress && state.reusableAddresses) {
      const previous = state.reusableAddresses.find(
        (item) => `${item.id}` == selectedPreviousAddress
      );
      if (previous) {
        loadSubs(previous.postalCode!, 0);
        const previuosAddress = {
          ...previous,
          domicilio: uuid(),
        };
        dispatch({ type: "setAddresses", payload: [previuosAddress] });
      }
    }
  }, [state.selectedPreviousAddress]);

  return {
    ...state,
    dispatch,
    updateAddresses,
    deleteAddress,
    onChangeAddress,
    updateSub,
    deleteSubs,
    loadAddressesSubs,
    onInput,
    handleImageUpload,
    deleteImage,
    hideAddressImageModal,
    openAddressModalImage,
    openUsePreviousAddress,
    onChangeSelectedPreviousAddress,
    hasError,
  };
};

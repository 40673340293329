import { AppBar, Grid } from "@material-ui/core";
import { useContentWrapperStyles } from "./styles";

interface Props {
  children: JSX.Element | JSX.Element[];
  marginTop?: boolean;
  paddingTop?: boolean;
  defaultPaddingTop?: boolean;
  appBarContent?: JSX.Element | null;
}

export const ContentWrapper = ({
  children,
  marginTop = false,
  paddingTop = false,
  defaultPaddingTop = false,
  appBarContent,
}: Props) => {
  const classes = useContentWrapperStyles();
  return (
    <div
      className={`${classes.contentWrapper} ${
        paddingTop && classes.paddingTop
      } ${defaultPaddingTop && classes.defaultPaddingTop} ${
        paddingTop && classes.paddingTop
      } ${marginTop && classes.marginTop}`}
    >
      {appBarContent && (
        <AppBar position="fixed" className={classes.appBar}>
          {appBarContent}
        </AppBar>
      )}
      <Grid
        container
        justifyContent="center"
        className={classes.childrenContainer}
      >
        {children}
      </Grid>
    </div>
  );
};

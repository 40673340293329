import { useContext, useEffect } from "react";
import { ContentWrapper } from "../../components/Common/ContentWrapper/ContentWrapper";
import { CustomTableCell } from "../../components/Common/PaginatedTableWrapper/CustomTableCell";
import { CustomTableRow } from "../../components/Common/PaginatedTableWrapper/CustomTableRow";
import { PaginatedTableWrapper } from "../../components/Common/PaginatedTableWrapper/PagintatedTableWrapper";
import { ChildrenFab } from "../../components/Common/ScrollToTopContatiner/ChildrenFab";
import { ScrollToTopContainer } from "../../components/Common/ScrollToTopContatiner/ScrollToTopContainer";
import { RolesContext } from "../../contexts/RolesContext";
import { HeaderType } from "../../interfaces/pagination.interfaces";

const headers: HeaderType[] = [
  { name: "Nombre" },
  { name: "Descripción", hiddeMobile: true },
  { name: "Num. Usuarios" },
];

export const RolesListView = () => {
  const {
    links,
    pagination,
    list,
    loadRoles,
    getRoles,
    loadApps,
    searchFunction,
    searchValue,
    clearSearchValue,
    getRole,
    redirectNewRole,
  } = useContext(RolesContext);

  useEffect(() => {
    loadApps();
    loadRoles();
  }, []);

  useEffect(() => {
    const timeOut = setTimeout(() => {
      getRoles("roles", { searchValue });
    }, 500);

    return () => clearTimeout(timeOut);
  }, [searchValue]);

  return (
    <ContentWrapper>
      <PaginatedTableWrapper
        title="Puestos de trabajo"
        iconTableSearch
        searchFunction={searchFunction}
        searchValue={searchValue}
        clearSearchValue={clearSearchValue}
        reloadFunction={() => getRoles("roles")}
        headers={headers}
        links={links}
        pagination={pagination}
        getData={getRoles}
      >
        <>
          {list.map((role) => (
            <CustomTableRow
              onClick={() => getRole(role.id)}
              key={`role-${role.id}`}
            >
              <CustomTableCell>
                <span>{role.name}</span>
              </CustomTableCell>
              <CustomTableCell hiddenMobile>
                <span>{role.comment}</span>
              </CustomTableCell>
              <CustomTableCell>
                <span>{role.usersNumber}</span>
              </CustomTableCell>
            </CustomTableRow>
          ))}
        </>
      </PaginatedTableWrapper>
      <ScrollToTopContainer>
        <ChildrenFab
          iconType="plus"
          onClick={redirectNewRole}
          title="Nuevo usuario"
        />
      </ScrollToTopContainer>
    </ContentWrapper>
  );
};

import { makeStyles } from "@material-ui/core";

const generalPadding = 30;

export const useLoginviewStyles = makeStyles((theme) => ({
  titleContainer: {
    paddingTop: generalPadding,
    paddingLeft: generalPadding,
    justifyContent: "flex-start",
    flexDirection: "column",
    [theme.breakpoints.down("md")]: {
      flexDirection: "column",
      alignItems: "center",
      justifyContent: "center",
      marginTop: 15,
    },
  },
  loginContainer: {
    marginTop: 15,
    paddingTop: generalPadding,
    paddingRight: generalPadding,
    [theme.breakpoints.down("md")]: {
      paddingTop: 0,
      paddingRight: 0,
    },
  },
  mainImage: {
    width: 300,
    [theme.breakpoints.down("md")]: {
      with: 100,
    },
  },
  welcomeTitle: {
    color: "white",
    fontSize: 15,
    marginBottom: 20,
  },
  welcomeSubTitle: {
    color: "white",
    fontSize: 20,
  },
  footerTitle: {
    color: "white",
    fontSize: 18,
    [theme.breakpoints.down("md")]: {
      fontSize: 14,
    },
  },
  itemFooter: {
    paddingLeft: generalPadding,
    [theme.breakpoints.down("md")]: {
      justifyContent: "center",
      padding: 0,
    },
  },
  itemFooterImagesContainer: {
    paddingRight: generalPadding,
    paddingLeft: generalPadding,
    [theme.breakpoints.down("md")]: {
      justifyContent: "center",
      padding: 0,
      marginTop: 10,
    },
  },
  itemFooterImage: {
    marginLeft: generalPadding,
    [theme.breakpoints.down("md")]: {
      padding: 0,
      height: 30,
    },
  },
  loginFooter: {
    position: "fixed",
    bottom: "2%",
    paddingBottom: 10,
  },
}));

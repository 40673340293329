import { Grid, Typography, makeStyles } from "@material-ui/core";
import { DiscountStatusValue } from "../../types/discounts.types";
import Brightness1Icon from "@material-ui/icons/Brightness1";

interface Props {
  type: DiscountStatusValue;
  label: string;
  showIcon: boolean;
  uppercase?: boolean;
  fullName?: boolean;
  columns?: number;
}

const styles = makeStyles((theme) => ({
  V: {
    color: theme.palette.primary.main,
    fontSize: 12,
  },
  I: {
    color: theme.palette.grey[900],
    fontSize: 12,
  },
}));

export const DiscountStatusIcon = ({ label, type, showIcon }: Props) => {
  const classes = styles();
  return (
    <Grid container alignItems="center">
      {showIcon && (
        <Brightness1Icon className={classes[type]} style={{ marginRight: 5 }} />
      )}
      <Typography className={classes[type]}>{label}</Typography>
    </Grid>
  );
};

import { LinksPagination, MetaPagination } from "./pagination.interfaces";

export interface ReligionsResponse {
  data: Religion[];
  links: LinksPagination;
  meta: MetaPagination;
}

export interface Religion {
  id?: string;
  name: string;
  status: Status;
  label?: string;
}

export interface ReligionErrors {
  name: string[];
  status: string[];
}

export interface ReligionsStatusResponse {
  data: ReligionStatus[];
  code: number;
}

export interface ReligionStatus {
  value: string;
  name: string;
}

export enum Status {
  active = "V",
  inactive = "I",
}

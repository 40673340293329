import { useContext, useEffect } from "react";
import { Route, Switch, Redirect } from "react-router-dom";
import { ACCESS_TOKEN_NAME } from "../api/backendApi";
import { ResponsiveDrawer } from "../components/SideBar/SideBar";
import { AuthContext } from "../contexts/AuthContext";
import { CivilStatusView } from "./CivilStatusView";
import { DiscountsView } from "./Discounts/DiscountsView";
import { EstablishmentsListView } from "./Establishments/EstablishmentsListView";
import { EstablishmentView } from "./Establishments/EstablishmentView";
import { LoginView } from "./LoginView/LoginView";
import { ProductsView } from "./ProductsView/ProductsView";
import { ReligionsView } from "./ReligionsView";
import { RolesListView } from "./Roles/RolesListView";
import { SimpleRoleView } from "./Roles/SimpleRoleView";
import { ServiceTypesView } from "./ServiceTypesView";
import { SuburbsView } from "./SuburbsView";
import { TransactionsView } from "./TransactionsView";
import { NewUserView } from "./Users/NewUserView/NewUserView";
import { SimpleUserView } from "./Users/SimpleUserView/SimpleUserView";
import { UsersView } from "./Users/UsersView/UsersView";

export const AppRouting = () => {
  const { isAuthenticated, restoreSession } = useContext(AuthContext);

  useEffect(() => {
    if (localStorage.getItem(ACCESS_TOKEN_NAME) && !isAuthenticated) {
      restoreSession();
    }
  }, []);

  return (
    <Switch>
      {!isAuthenticated && (
        <>
          <Redirect to="/login" />
          <Route component={LoginView} exact path="/login" />
        </>
      )}
      {isAuthenticated && (
        <ResponsiveDrawer>
          <Redirect to="/users" />
          <Route component={UsersView} exact path="/users" />
          <Route component={NewUserView} exact path="/users/new-user" />
          <Route component={SimpleUserView} exact path="/users/user" />
          <Route component={RolesListView} exact path="/jobs" />
          <Route component={SimpleRoleView} exact path="/jobs/job" />
          <Route
            component={EstablishmentsListView}
            exact
            path="/establishments"
          />
          <Route
            component={EstablishmentView}
            exact
            path="/establishments/establishment"
          />
          <Route component={DiscountsView} exact path="/configs/discounts" />
          <Route component={ProductsView} exact path="/configs/products" />
          <Route
            component={ServiceTypesView}
            exact
            path="/configs/product-types"
          />
          <Route
            component={CivilStatusView}
            exact
            path="/configs/civil-status"
          />
          <Route component={ReligionsView} exact path="/configs/religions" />
          <Route component={SuburbsView} exact path="/configs/suburbs" />
          <Route component={TransactionsView} exact path="/transactions" />
        </ResponsiveDrawer>
      )}
    </Switch>
  );
};

/* eslint-disable no-unused-vars */
import { makeStyles } from "@material-ui/core/styles";
import purple from "@material-ui/core/colors/purple";
import grey from "@material-ui/core/colors/grey";

export const useGeneralStyles = makeStyles((theme) => ({
  actionButton: {
    paddingLeft: ".7em",
    paddingRight: ".7em",
    backgroundColor: purple[500],
    color: "#fff",
    "&:hover": {
      backgroundColor: purple[700],
    },
    margin: theme.spacing(1),
  },
  secondaryButton: {
    paddingLeft: ".7em",
    paddingRight: ".7em",
    backgroundColor: grey[900],
    color: "#fff",
    "&:hover": {
      backgroundColor: grey[800],
    },
  },
  primaryButton: {
    backgroundColor: purple[500],
    color: "#fff",
    "&:hover": {
      backgroundColor: purple[700],
    },
    paddingLeft: ".7em",
    paddingRight: ".7em",
  },
  formControl: {
    margin: theme.spacing(1),
    minWidth: 120,
    width: "100%",
  },
  modalTitle: {
    color: theme.palette.grey[700],
  },
  textErrorForm: {
    color: "red",
  },
  tableHead: {
    textTransform: "uppercase",
    color: theme.palette.grey[500],
  },
  tableCell: {
    color: theme.palette.grey[600],
    textAlign: "left",
  },
}));

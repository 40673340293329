import { Phone, PhonesErrors } from "../interfaces/phone.interfaces";
import { PhoneType } from "../interfaces/phoneTypes.interfaces";
import { PhonesActionTypes } from "../types/phones.types";

export interface PhonesSatate {
  phones: Phone[];
  reusablePhones: Phone[];
  newPhone: Phone;
  showPreviousPhones: boolean;
  selectedPreviousPhone: string;
  errors: PhonesErrors;
  types: PhoneType[];
}

export const PHONES_INITIAL_STATE: PhonesSatate = {
  types: [],
  phones: [],
  reusablePhones: [],
  newPhone: {
    id: 0,
    typeId: "",
    phoneNumber: "",
    editable: true,
    active: true,
  },
  showPreviousPhones: false,
  selectedPreviousPhone: "",
  errors: {
    typeId: [],
    active: [],
    editable: [],
    phoneNumber: [],
  },
};

export const phonesReducer = (
  state = PHONES_INITIAL_STATE,
  action: PhonesActionTypes
): PhonesSatate => {
  switch (action.type) {
    case "setPhones":
      return {
        ...state,
        phones: action.payload,
      };
    case "setReusablePhones":
      return {
        ...state,
        reusablePhones: action.payload,
      };
    case "setShowPreviousPhones":
      return {
        ...state,
        showPreviousPhones: action.payload,
      };
    case "setSelectedPreviousPhone":
      return {
        ...state,
        selectedPreviousPhone: action.payload,
      };
    case "setErrors":
      return {
        ...state,
        errors: action.payload,
      };
    default:
      return state;
  }
};

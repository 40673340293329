import { ChangeEvent, Dispatch } from "react";
import { backendApi } from "../../api/backendApi";
import {
  DiscountsResponse,
  DiscountStatusResponse,
} from "../../interfaces/discounts.interfaces";
import { DiscountsState } from "../../reducers/discounts.reducer";
import { DiscountActionTypes } from "../../types/discounts.types";

export interface UseDiscountsProps {
  getDiscounts: () => void;
  searchFunction: (e: ChangeEvent<HTMLInputElement>) => void;
  resetSearchValue: () => void;
  openNewDiscountModal: () => void;
  openEditDiscountModal: () => void;
  loadDiscounts: () => void;
  onChangeDiscount: (
    e: ChangeEvent<{ name?: string; value: unknown; checked?: boolean }>
  ) => void;
  openDiscountModal: () => void;
  loadStatuses: () => void;
  toCurrencyString: <T>(value: T) => void;
}

export const useDiscounts = (
  state: DiscountsState,
  dispatch: Dispatch<DiscountActionTypes>
): UseDiscountsProps => {
  const openDiscountModal = () =>
    dispatch({
      type: "setShowDiscountModal",
      payload: !state.showDiscountModal,
    });

  const getDiscounts = async () => {
    dispatch({ type: "getDiscounts" });
    try {
      const response = await backendApi.get<DiscountsResponse>("discounts");
      console.log(response);

      dispatch({ type: "getDiscountsSuccess", payload: response.data });
    } catch (error) {
      dispatch({ type: "getDiscountsError" });
    }
  };

  const onChangeDiscount = (
    e: ChangeEvent<{ name?: string; value: unknown }>
  ) => {
    dispatch({
      type: "setDiscount",
      payload: {
        ...state.discount,
        [e.target.name!]: e.target.value,
      },
    });
  };

  const loadDiscounts = () => {
    if (state.list.length === 0) {
      getDiscounts();
    }
  };

  const setSearchValue = (value: string) => {};

  const searchFunction = (e: ChangeEvent<HTMLInputElement>) => {
    setSearchValue(e.target.value);
  };

  const resetSearchValue = () =>
    dispatch({ type: "setSearchValue", payload: "" });

  const openNewDiscountModal = () =>
    dispatch({
      type: "setShowNewDiscountModal",
    });

  const openEditDiscountModal = () =>
    dispatch({
      type: "setShowEditDiscountModal",
      payload: !state.showEditDiscountModal,
    });

  const loadStatuses = async () => {
    if (state.statuses.length === 0) {
      dispatch({ type: "getStatuses" });
      try {
        const response = await backendApi.get<DiscountStatusResponse>(
          "discounts/status"
        );
        dispatch({ type: "getStatusesSuccess", payload: response.data.data });
      } catch (error) {
        dispatch({ type: "getStatusesError" });
      }
    }
  };

  const toCurrencyString = <K>(value: K) => {
    return Number(value).toLocaleString("es-MX", {
      style: "currency",
      currency: "MXN",
    });
  };

  return {
    getDiscounts,
    searchFunction,
    resetSearchValue,
    openNewDiscountModal,
    openEditDiscountModal,
    loadDiscounts,
    onChangeDiscount,
    openDiscountModal,
    loadStatuses,
    toCurrencyString,
  };
};

import { useContext } from "react";
import {
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
} from "@material-ui/core";

import { CustomActionButton } from "../Common/ActionButtons/CustomActionButton";
import { SecondaryActionButton } from "../Common/ActionButtons/SecondaryActionButton";
import { SuburbsContext } from "../../contexts/SuburbsContext";
import { SuburbForm } from "./SuburbForm";

export const SuburbsModal = () => {
  const { showModal, closeModal, saveSuburb } = useContext(SuburbsContext);

  return (
    <Dialog open={showModal} onClose={closeModal} maxWidth="sm" fullWidth>
      <DialogTitle>Colonia</DialogTitle>
      <DialogContent>
        <SuburbForm />
      </DialogContent>
      <DialogActions>
        <CustomActionButton onClick={saveSuburb} />
        <SecondaryActionButton onClick={closeModal} />
      </DialogActions>
    </Dialog>
  );
};

import {
  LinksPagination,
  MetaPagination,
} from "../interfaces/pagination.interfaces";
import {
  Folio,
  Transaction,
  TransactionErrors,
} from "../interfaces/transactions.interfaces";
import { TransactionActionTypes } from "../types/transactions.types";

export interface TransactionsState {
  loading: boolean;
  list: Transaction[];
  message: string;
  links: LinksPagination;
  pagination: MetaPagination;
  searchValue: string;
  showModal: boolean;
  showFoliosModal: boolean;
  transaction: Transaction;
  folio: Folio;
  error: boolean;
  success: boolean;
  errors: TransactionErrors;
  showDeleteFolioModal: boolean;
}
export const TRANSACTIONS_INITIAL_STATE: TransactionsState = {
  loading: false,
  success: false,
  showDeleteFolioModal: false,
  list: [],
  message: "",
  pagination: {
    current_page: 1,
    from: 1,
    to: 10,
    last_page: 0,
    path: "",
    per_page: 10,
    total: 0,
  },
  links: {
    first: undefined,
    last: undefined,
    prev: undefined,
    next: undefined,
  },
  searchValue: "",
  showModal: false,
  transaction: {
    name: "",
    byEstab: "0",
    folios: [],
  },
  showFoliosModal: false,
  folio: {
    establishmentId: "",
    establishmentName: "",
    fill: 0,
    folio: 1,
    transaction: "",
  },
  error: false,
  errors: {
    establishmentId: [],
    fill: [],
    folio: [],
    transaction: [],
  },
};

export const transactionReducer = (
  state = TRANSACTIONS_INITIAL_STATE,
  action: TransactionActionTypes
): TransactionsState => {
  switch (action.type) {
    case "getTransactions":
    case "saveFolio":
    case "deleteFolio":
      return {
        ...state,
        error: false,
        loading: true,
      };
    case "hideAlert":
      return {
        ...state,
        error: false,
        message: "",
        success: false,
      };
    case "getTransactionsError":
      return {
        ...state,
        loading: false,
      };
    case "getTransactionsSuccess":
      return {
        ...state,
        loading: false,
        list: action.payload.data,
        links: action.payload.links,
        pagination: action.payload.meta,
      };
    case "editTransaction":
      return {
        ...state,
        transaction: action.payload,
        showModal: true,
      };
    case "createTransaction":
      return {
        ...state,
        transaction: TRANSACTIONS_INITIAL_STATE.transaction,
        showModal: true,
      };
    case "cancelSaveTransaction":
      return {
        ...state,
        transaction: TRANSACTIONS_INITIAL_STATE.transaction,
        showModal: false,
      };
    case "createFolio":
      return {
        ...state,
        showModal: false,
        showFoliosModal: true,
        folio: {
          ...TRANSACTIONS_INITIAL_STATE.folio,
          transaction: state.transaction.id!,
        },
      };
    case "editFolio":
      return {
        ...state,
        folio: action.payload,
        showModal: false,
        showFoliosModal: true,
      };
    case "setFolio":
      return {
        ...state,
        folio: action.payload,
      };
    case "cancelSaveFolio":
      return {
        ...state,
        folio: TRANSACTIONS_INITIAL_STATE.folio,
        showFoliosModal: false,
        showModal: true,
      };
    case "saveFolioError":
      return {
        ...state,
        loading: false,
        error: true,
        errors: { ...TRANSACTIONS_INITIAL_STATE.errors, ...action.payload },
      };
    case "updateFolioSuccess":
      return {
        ...state,
        loading: false,
        errors: TRANSACTIONS_INITIAL_STATE.errors,
        folio: action.payload.data,
        message: action.payload.message,
        success: true,
      };
    case "deleteFolioError":
      return {
        ...state,
        loading: false,
        error: false,
        message: action.payload,
      };
    case "deleteFolioSuccess":
      return {
        ...state,
        loading: false,
        error: false,
        message: action.payload.message,
        list: state.list.filter((item) => item.id !== action.payload.data.id),
        showDeleteFolioModal: false,
        showModal: true,
      };
    case "showDeleteFolioModal":
      return {
        ...state,
        showModal: false,
        showDeleteFolioModal: true,
        folio: action.payload,
      };
    case "cancelDeleteFolio":
      return { ...state, showDeleteFolioModal: false, showModal: true };
    case "setSearchValue":
      return {
        ...state,
        searchValue: action.payload,
      };
    case "badRequest":
      return {
        ...state,
        loading: false,
        error: true,
        errors: TRANSACTIONS_INITIAL_STATE.errors,
        message: action.payload || "Error desconocido",
      };
    default:
      return state;
  }
};

import { createContext, useReducer } from "react";
import { CustomAlert } from "../components/Common/Alert/Alert";
import { ErrorsAlertContainer } from "../components/Common/ErrorContainer/ErrorContainer";
import { LoadingSpinner } from "../components/LoadingSpinner/LoadingSpinner";
import { useFolios, UseFoliosProps } from "../hooks/transactions/useFolios";
import {
  useTransactions,
  UseTransactionsProps,
} from "../hooks/transactions/useTransactions";
import { ProviderProps } from "../interfaces/common.interfaces";
import {
  transactionReducer,
  TransactionsState,
  TRANSACTIONS_INITIAL_STATE,
} from "../reducers/transaction.reducer";

interface ContextProps
  extends TransactionsState,
    UseTransactionsProps,
    UseFoliosProps {}

export const TransactionContext = createContext({} as ContextProps);

export const TransactionsProvider = ({ children }: ProviderProps) => {
  const [state, disaptch] = useReducer(
    transactionReducer,
    TRANSACTIONS_INITIAL_STATE
  );

  const context = useTransactions(state, disaptch);
  const foliosContext = useFolios(state, disaptch);

  return (
    <TransactionContext.Provider
      value={{ ...state, ...context, ...foliosContext }}
    >
      {children}
      <LoadingSpinner loading={state.loading} />
      <ErrorsAlertContainer
        errors={state.errors}
        error={state.error}
        optionalMessage={state.message}
        hideErrorsAlert={() => disaptch({ type: "hideAlert" })}
      />
      <CustomAlert
        message={state.message}
        show={state.success}
        handleClose={() => disaptch({ type: "hideAlert" })}
      />
    </TransactionContext.Provider>
  );
};

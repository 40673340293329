import { useContext, useEffect } from "react";
import { ContentWrapper } from "../../../components/Common/ContentWrapper/ContentWrapper";
import { HeaderType } from "../../../components/Common/TableWrapper/TableWrapper";
import { UsersContext } from "../../../contexts/UsersContext";
import { UserStatusIcon } from "../../../components/User/StatusIcon/UserStatusIcon";
import { ScrollToTopContainer } from "../../../components/Common/ScrollToTopContatiner/ScrollToTopContainer";
import { ChildrenFab } from "../../../components/Common/ScrollToTopContatiner/ChildrenFab";
import { UserRoleItem } from "../../../components/User/UserRoleIcon";
import { LoadingSpinner } from "../../../components/LoadingSpinner/LoadingSpinner";
import { PaginatedTableWrapper } from "../../../components/Common/PaginatedTableWrapper/PagintatedTableWrapper";
import { CustomTableRow } from "../../../components/Common/PaginatedTableWrapper/CustomTableRow";
import { CustomTableCell } from "../../../components/Common/PaginatedTableWrapper/CustomTableCell";

const headers: HeaderType[] = [
  { name: "Id", hiddeMobile: true },
  { name: "Usuario" },
  { name: "Teléfono", hiddeMobile: true },
  { name: "Email", hiddeMobile: true },
  { name: "Permisos", hiddeMobile: true },
  { name: "Estatus", hiddeMobile: true },
  { name: "" },
];

export const UsersView = () => {
  const {
    loadUsers,
    loading,
    list,
    searchFunction,
    searchValue,
    resetSearchValue,
    redirectToNewUser,
    links,
    pagination,
    getUsers,
    getUserData,
  } = useContext(UsersContext);

  useEffect(() => {
    loadUsers();
  }, []);

  useEffect(() => {
    const timeOut = setTimeout(() => {
      getUsers("users", { searchValue });
    }, 500);

    return () => clearTimeout(timeOut);
  }, [searchValue]);

  return (
    <ContentWrapper>
      <PaginatedTableWrapper
        title="Usuarios"
        iconTableSearch
        searchFunction={searchFunction}
        searchValue={searchValue}
        clearSearchValue={resetSearchValue}
        reloadFunction={() => getUsers("users")}
        headers={headers}
        links={links}
        pagination={pagination}
        getData={getUsers}
      >
        {list.map((user) => (
          <CustomTableRow
            key={`user-${user.id}`}
            onClick={() => {
              getUserData(user.id);
            }}
          >
            <CustomTableCell hiddenMobile>
              <span>{user.id}</span>
            </CustomTableCell>
            <CustomTableCell>
              <span>{user.name}</span>
            </CustomTableCell>
            <CustomTableCell hiddenMobile>
              <span>{user.phone}</span>
            </CustomTableCell>
            <CustomTableCell hiddenMobile>
              <span>{user.email}</span>
            </CustomTableCell>
            <CustomTableCell>
              {user.roles.map((role) => (
                <UserRoleItem label={role.name} key={`role-${role.id}`} />
              ))}
            </CustomTableCell>
            <CustomTableCell hiddenMobile>
              <UserStatusIcon
                type={user.status_id}
                label={user.status}
                showIcon
              />
            </CustomTableCell>
          </CustomTableRow>
        ))}
      </PaginatedTableWrapper>
      <ScrollToTopContainer>
        <ChildrenFab
          iconType="plus"
          onClick={redirectToNewUser}
          title="Nuevo usuario"
        />
      </ScrollToTopContainer>
      <LoadingSpinner loading={loading} color="primary" />
    </ContentWrapper>
  );
};

import {
  FormControl,
  FormHelperText,
  Grid,
  InputLabel,
  MenuItem,
  Select,
  TextField,
  Typography,
} from "@material-ui/core";
import { useContext, useEffect } from "react";
import { ProductsContext } from "../../../contexts/ProductsContext";

export const ProductPaymentFrequencyForm = () => {
  const {
    paymentFrequencyAmount,
    errors,
    product,
    onChangetFrequencyProductAmount,
    financements,
    paymentFrequencies,
    loadPaymentFrequencies,
  } = useContext(ProductsContext);

  useEffect(() => {
    loadPaymentFrequencies();
  }, []);

  return (
    <Grid container spacing={2}>
      <Grid item xs={12}>
        <Typography>
          {paymentFrequencyAmount.id
            ? "Editar precio por frecuencia de pago"
            : "Crear precio por frecuencia de pago"}
        </Typography>
      </Grid>
      <Grid item xs={12}>
        <Typography>{product.name}</Typography>
      </Grid>
      <Grid item xs={12}>
        <FormControl fullWidth error={errors.financementId.length > 0}>
          <InputLabel id="label-financementId">Financiamiento</InputLabel>
          <Select
            labelId="label-financementId"
            name="financementId"
            value={paymentFrequencyAmount.financementId}
            onChange={onChangetFrequencyProductAmount}
          >
            {financements.map((financement) => (
              <MenuItem value={financement.id}>{financement.name}</MenuItem>
            ))}
          </Select>
          <FormHelperText>{errors.financementId[0]}</FormHelperText>
        </FormControl>
      </Grid>
      <Grid item xs={12}>
        <FormControl fullWidth error={errors.paymentFrequencyId.length > 0}>
          <InputLabel id="label-paymentFrequencyId">
            Frecuencia de pago
          </InputLabel>
          <Select
            labelId="label-paymentFrequencyId"
            name="paymentFrequencyId"
            value={paymentFrequencyAmount.paymentFrequencyId}
            onChange={onChangetFrequencyProductAmount}
          >
            {paymentFrequencies.map((financement) => (
              <MenuItem value={financement.id}>{financement.name}</MenuItem>
            ))}
          </Select>
          <FormHelperText>{errors.paymentFrequencyId[0]}</FormHelperText>
        </FormControl>
      </Grid>
      <Grid item xs={12}>
        <FormControl fullWidth>
          <TextField
            label="Importe del abono"
            name="paymentAmount"
            value={paymentFrequencyAmount.paymentAmount}
            onChange={onChangetFrequencyProductAmount}
            error={errors.paymentAmount.length > 0}
            helperText={errors.paymentAmount[0]}
          />
        </FormControl>
      </Grid>
      <Grid item xs={12}>
        <FormControl fullWidth>
          <TextField
            label="Importe del enganche"
            name="downPaymentAmount"
            value={paymentFrequencyAmount.downPaymentAmount}
            onChange={onChangetFrequencyProductAmount}
            error={errors.downPaymentAmount.length > 0}
            helperText={errors.downPaymentAmount[0]}
          />
        </FormControl>
      </Grid>
    </Grid>
  );
};

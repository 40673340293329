import React from "react";
import ArrowUpwardIcon from "@material-ui/icons/ArrowUpward";
import CachedIcon from "@material-ui/icons/Cached";
import ViewCarouselIcon from "@material-ui/icons/ViewCarousel";
import AddIcon from "@material-ui/icons/Add";
import CommentIcon from "@material-ui/icons/Comment";
import useStyles from "./styles";

export type ScrollIconType =
  | "payments"
  | "plus"
  | "reload"
  | "survey"
  | "default";

interface Props {
  type?: ScrollIconType;
}

export const ScrollIconButton = ({ type = "default" }: Props) => {
  const classes = useStyles();

  switch (type) {
    case "plus":
      return <AddIcon className={classes.actionIcon} />;
    case "payments":
      return <ViewCarouselIcon className={classes.actionIcon} />;
    case "reload":
      return <CachedIcon className={classes.actionIcon} />;
    case "survey":
      return <CommentIcon className={classes.actionIcon} />;
    default:
      return <ArrowUpwardIcon className={classes.actionIcon} />;
  }
};

import { DialogActions, DialogContent } from "@material-ui/core";
import { useContext } from "react";
import { TransactionContext } from "../../contexts/TransactionsContext";
import { CustomActionButton } from "../Common/ActionButtons/CustomActionButton";
import { SecondaryActionButton } from "../Common/ActionButtons/SecondaryActionButton";
import { TransactionForm } from "./TransactionForm";

export const DataTab = () => {
  const { cancelSave } = useContext(TransactionContext);
  return (
    <>
      <DialogContent>
        <TransactionForm />
      </DialogContent>
      <DialogActions>
        <CustomActionButton onClick={() => {}} />
        <SecondaryActionButton onClick={cancelSave} />
      </DialogActions>
    </>
  );
};

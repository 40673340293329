import { createContext, useReducer } from "react";
import {
  useAddressesTypes,
  UseAddressesTypesProps,
} from "../hooks/addressesTypes/useAddressesTypes";
import { ProviderProps } from "../interfaces/common.interfaces";
import {
  addressesTypesReducer,
  AddressesTypesState,
  ADDRESS_TYPES_INITIAL_STATE,
} from "../reducers/addressTypes.reducer";

interface ContextProps extends AddressesTypesState, UseAddressesTypesProps {}

export const AddressesTypesContext = createContext({} as ContextProps);

export const AddressesTypesProvider = ({ children }: ProviderProps) => {
  const initialState = ADDRESS_TYPES_INITIAL_STATE;

  const [state, dispatch] = useReducer(addressesTypesReducer, initialState);

  const addressesTypesContext = useAddressesTypes(state, dispatch);

  return (
    <AddressesTypesContext.Provider
      value={{ ...state, ...addressesTypesContext }}
    >
      {children}
    </AddressesTypesContext.Provider>
  );
};

import { makeStyles } from "@material-ui/core";

export const useUserFormStyles = makeStyles(() => ({
  formControl: {
    width: "100%",
  },
  avatar: {
    height: 70,
    width: 70,
    backgroundColor: "black",
  },
}));

import { ChangeEvent, Dispatch } from "react";
import { backendApi } from "../api/backendApi";
import { ReligionsResponse } from "../interfaces/religions.interfaces";
import { ReligionsState } from "../reducers/religions.reducer";
import { ReligionActionTypes } from "../types/religions.types";

export interface UseReligionsProps {
  getReligions: (path: string) => void;
  loadReligions: () => void;
  searchFunction: (e: ChangeEvent<HTMLInputElement>) => void;
  clearSearchValue: () => void;
}

export const useReligions = (
  state: ReligionsState,
  dispatch: Dispatch<ReligionActionTypes>
): UseReligionsProps => {
  const getReligions = async (path: string) => {
    try {
      dispatch({ type: "getReligions" });
      const response = await backendApi.get<ReligionsResponse>(path);
      dispatch({ type: "getReligionsSuccess", payload: response.data });
    } catch (error) {
      dispatch({ type: "getReligionsError" });
    }
  };

  const loadReligions = () => {
    if (state.list.length === 0) {
      getReligions("religions");
    }
  };

  const searchFunction = (e: ChangeEvent<HTMLInputElement>) => {
    dispatch({ type: "setSearchValue", payload: e.target.value });
  };

  const clearSearchValue = () => {
    dispatch({ type: "setSearchValue", payload: "" });
  };

  return {
    getReligions,
    loadReligions,
    searchFunction,
    clearSearchValue,
  };
};

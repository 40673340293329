import { CSSProperties } from "react";
import CheckIcon from "@material-ui/icons/Check";
import { useMessageDialogStyles } from "./styles";

export type IconMessageDialogType = "success" | "warning" | "error";

interface Props {
  type?: IconMessageDialogType;
  styles?: CSSProperties;
  iconColor?:
    | "error"
    | "inherit"
    | "disabled"
    | "action"
    | "primary"
    | "secondary"
    | undefined;
}

export const IconMessageDialog = ({
  type = "success",
  styles,
  iconColor = "primary",
}: Props) => {
  const classes = useMessageDialogStyles();
  switch (type) {
    case "success":
      return (
        <CheckIcon className={classes.icon} color={iconColor} style={styles} />
      );
    default:
      return (
        <CheckIcon className={classes.icon} color={iconColor} style={styles} />
      );
  }
};

import { ChangeEvent, Dispatch } from "react";
import { useHistory } from "react-router";
import { backendApi } from "../../api/backendApi";
import { EstablismentsPaginatedResponse } from "../../interfaces/establishments.interfaces";
import { EstablishmentsState } from "../../reducers/establishments.reducer";
import { EstablishmentsActionTypes } from "../../types/establishmens.types";

export interface UseStablishmentsProps {
  getEstablishments: () => void;
  searchFunction: (e: ChangeEvent<HTMLInputElement>) => void;
  clearSearchvalue: () => void;
  redirectToNewOffice: () => void;
}

export const useEstablishments = (
  state: EstablishmentsState,
  dispatch: Dispatch<EstablishmentsActionTypes>
): UseStablishmentsProps => {
  const history = useHistory();

  const getEstablishments = async () => {
    if (state.list.length === 0) {
      dispatch({ type: "getEstablishments" });
      try {
        const response = await backendApi.get<EstablismentsPaginatedResponse>(
          "establishments"
        );
        dispatch({ type: "getEstablishmentsSuccess", payload: response.data });
      } catch (error) {
        dispatch({ type: "getEstablishmentsError" });
      }
    }
  };

  const searchFunction = (e: ChangeEvent<HTMLInputElement>) => {
    dispatch({ type: "setSearchValue", payload: e.target.value });
  };

  const clearSearchvalue = () => {
    dispatch({ type: "setSearchValue", payload: "" });
  };

  const redirectToNewOffice = () => {
    history.push("/establishments/establishment");
  };

  return {
    getEstablishments,
    searchFunction,
    clearSearchvalue,
    redirectToNewOffice,
  };
};

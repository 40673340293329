import { makeStyles } from "@material-ui/core";

export const useErrosAlertContainer = makeStyles((theme) => ({
  errorsContainer: {
    position: "fixed",
    right: "1%",
    top: " 15%",
    width: "20%",
    zIndex: 1500,
    backgroundColor: "#ffcccc",
    [theme.breakpoints.down("sm")]: {
      width: "60%",
    },
  },
  hideButton: {
    position: "absolute",
    right: "1%",
    top: " 1%",
  },
  errorIcon: {
    paddingRight: 5,
  },
  errorItem: {
    fontSize: 12,
    textAlign: "start",
    [theme.breakpoints.down("sm")]: {
      fontSize: 10,
    },
  },
  bulletIcon: {
    paddingRight: 5,
    fontSize: 12,
  },
}));

import { useContext } from "react";
import {
  DialogContent,
  Grid,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
  Typography,
} from "@material-ui/core";
import { useProductModalStyles } from "./styles";
import { ProductsContext } from "../../../contexts/ProductsContext";
import { GeneralContext } from "../../../contexts/GeneralContext";
import { CustomActionButton } from "../../Common/ActionButtons/CustomActionButton";
import { CustomIconButton } from "../../Common/ActionButtons/CustomIconButton";
import { HeaderType } from "../../../interfaces/pagination.interfaces";
import { CustomTableCell } from "../../Common/PaginatedTableWrapper/CustomTableCell";

const headers: HeaderType[] = [
  { name: "Financiamiento" },
  { name: "Frecuencia de pago" },
  { name: "Importe del Abono", hiddeMobile: true },
  { name: "Importe del enganche", hiddeMobile: true },
  { name: "", hiddeMobile: true },
];
export const PaymentFrequencyTab = () => {
  const classes = useProductModalStyles();
  const { product, setFrequencyProductAmount, dispatch } =
    useContext(ProductsContext);
  const { mxCurrencyFormat } = useContext(GeneralContext);
  return (
    <DialogContent className={classes.dataContianer}>
      <Grid item xs={12} container justify="space-between">
        <Typography className={classes.priceTabProductTitle}>
          {product.name}
        </Typography>
        <CustomActionButton
          label="Registrar frecuencia de pago"
          iconType="add"
          onClick={() => dispatch({ type: "createPaymentFrequency" })}
        />
      </Grid>
      <Grid item xs={12} style={{ overflowX: "auto" }}>
        <Table>
          <TableHead>
            <TableRow>
              {headers.map((header) => (
                <CustomTableCell hiddenMobile={header.hiddeMobile}>
                  {header.name}
                </CustomTableCell>
              ))}
            </TableRow>
          </TableHead>
          <TableBody>
            {product.paymentFrequencyAmounts.map((item) => (
              <TableRow>
                <CustomTableCell>
                  {item.financement?.name || ""}
                </CustomTableCell>
                <CustomTableCell>
                  {item.paymentFrequency?.name || ""}
                </CustomTableCell>
                <CustomTableCell hiddenMobile>
                  {mxCurrencyFormat(item.paymentAmount)}
                </CustomTableCell>
                <CustomTableCell hiddenMobile>
                  {mxCurrencyFormat(item.downPaymentAmount)}
                </CustomTableCell>
                <CustomTableCell>
                  <CustomIconButton
                    iconType="edit"
                    onClick={() => {
                      setFrequencyProductAmount(item.id!);
                    }}
                  />
                </CustomTableCell>
              </TableRow>
            ))}
          </TableBody>
        </Table>
      </Grid>
    </DialogContent>
  );
};

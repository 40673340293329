import { Dialog, DialogActions, DialogContent } from "@material-ui/core";
import { useContext } from "react";
import { CustomActionButton } from "../../Common/ActionButtons/CustomActionButton";
import { useRef } from "react";
import { useEffect } from "react";
import { SecondaryActionButton } from "../../Common/ActionButtons/SecondaryActionButton";
import { ProductsContext } from "../../../contexts/ProductsContext";
import { PaymentFrequenciesContext } from "../../../contexts/PaymentFrequenciesContex";
import { ProductPaymentFrequencyForm } from "./ProductPaymentFrequencyForm";

export const ProductPaymentFrequencyModal = () => {
  const isMounted = useRef(true);
  const {
    showPaymentFrequencyForm,
    dispatch,
    resetFrequencyProductAmount,
    onSubmitFrequencyProductAmount,
  } = useContext(ProductsContext);
  const { loadPaymentFrequecies } = useContext(PaymentFrequenciesContext);

  useEffect(() => {
    isMounted.current && loadPaymentFrequecies();

    return () => {
      isMounted.current = false;
    };
  }, []);
  return (
    <Dialog
      open={showPaymentFrequencyForm}
      onClose={() => {
        resetFrequencyProductAmount();
        dispatch({ type: "hidePaymentFrequencyForm" });
      }}
      maxWidth="sm"
      fullWidth
    >
      <DialogContent>
        <ProductPaymentFrequencyForm />
      </DialogContent>
      <DialogActions>
        <CustomActionButton
          label="Guardar"
          onClick={onSubmitFrequencyProductAmount}
        />
        <SecondaryActionButton
          label="Cancelar"
          onClick={() => dispatch({ type: "hidePaymentFrequencyForm" })}
        />
      </DialogActions>
    </Dialog>
  );
};

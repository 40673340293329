import { useContext, useEffect } from "react";
import { TableCell, IconButton } from "@material-ui/core";
import EditIcon from "@material-ui/icons/Edit";
import { ContentWrapper } from "../../components/Common/ContentWrapper/ContentWrapper";
import { PaginatedTableWrapper } from "../../components/Common/PaginatedTableWrapper/PagintatedTableWrapper";
import { DiscountContext } from "../../contexts/DiscountsContext";
import { HeaderType } from "../../interfaces/pagination.interfaces";
import { DiscountModal } from "../../components/Discounts/DiscountModal/DiscountModal";
import { ScrollToTopContainer } from "../../components/Common/ScrollToTopContatiner/ScrollToTopContainer";
import { ChildrenFab } from "../../components/Common/ScrollToTopContatiner/ChildrenFab";
import { CustomTableRow } from "../../components/Common/PaginatedTableWrapper/CustomTableRow";
import { DiscountStatusIcon } from "../../components/Discounts/StatusIcon";
import { CustomTableCell } from "../../components/Common/PaginatedTableWrapper/CustomTableCell";

const headers: HeaderType[] = [
  { name: "Nombre" },
  { name: "Total de descuento", hiddeMobile: true },
  { name: "Status" },
  { name: "" },
];

export const DiscountsView = () => {
  const {
    searchValue,
    getDiscounts,
    searchFunction,
    resetSearchValue,
    setDiscount,
    openNewDiscountModal,
    loadDiscounts,
    toCurrencyString,
    links,
    pagination,
    list,
  } = useContext(DiscountContext);

  useEffect(() => {
    loadDiscounts();
  }, []);

  return (
    <ContentWrapper paddingTop>
      <PaginatedTableWrapper
        title="Descuentos"
        reloadFunction={getDiscounts}
        searchFunction={searchFunction}
        searchValue={searchValue}
        clearSearchValue={resetSearchValue}
        links={links}
        pagination={pagination}
        getData={getDiscounts}
        headers={headers}
      >
        {list.map((item) => (
          <CustomTableRow>
            <CustomTableCell>{item.name}</CustomTableCell>
            <CustomTableCell hiddenMobile>
              {item.isPercentage
                ? `${item.amount}%`
                : `${toCurrencyString(item.amount)}`}
            </CustomTableCell>
            <CustomTableCell>
              <DiscountStatusIcon
                type={item.status}
                label={item.statusLabel}
                showIcon
              />
            </CustomTableCell>
            <CustomTableCell style={{ margin: 0, padding: 0 }}>
              <IconButton
                component="span"
                onClick={() => {
                  setDiscount(item.id!);
                }}
              >
                <EditIcon />
              </IconButton>
            </CustomTableCell>
          </CustomTableRow>
        ))}
      </PaginatedTableWrapper>
      <DiscountModal />
      <ScrollToTopContainer>
        <ChildrenFab onClick={openNewDiscountModal} iconType="plus" />
      </ScrollToTopContainer>
    </ContentWrapper>
  );
};

import ArrowBackIcon from "@material-ui/icons/ArrowBack";
import { Link } from "react-router-dom";
import { Grid } from "@material-ui/core";
import { useCustomAppBarStyles } from "./style";

interface Props {
  path: string;
  children: JSX.Element;
  visibleBAckIcon: boolean;
}

export const CustomAppBar = ({ path, children, visibleBAckIcon }: Props) => {
  const classes = useCustomAppBarStyles();

  return (
    <Grid container direction="row" className={classes.appBar}>
      {visibleBAckIcon && (
        <Grid
          item
          container
          xs={1}
          justifyContent="center"
          alignItems="center"
          className={classes.backIconContainer}
        >
          <Link to={path} className="text-dark">
            <ArrowBackIcon />
          </Link>
        </Grid>
      )}
      <Grid
        container
        item
        xs={10}
        alignContent="center"
        justifyContent="flex-start"
        alignItems="center"
      >
        {children}
      </Grid>
    </Grid>
  );
};

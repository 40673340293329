import { Typography } from "@material-ui/core";
import { useContext, useEffect } from "react";
import { ConfirmDialog } from "../components/Common/ConfirmDialog/ConfirmDialog";
import { ContentWrapper } from "../components/Common/ContentWrapper/ContentWrapper";
import { PaginatedTableWrapper } from "../components/Common/PaginatedTableWrapper/PagintatedTableWrapper";
import { ChildrenFab } from "../components/Common/ScrollToTopContatiner/ChildrenFab";
import { ScrollToTopContainer } from "../components/Common/ScrollToTopContatiner/ScrollToTopContainer";
import { SuburbItem } from "../components/Suburbs/SuburbItem";
import { SuburbsModal } from "../components/Suburbs/SuburbsModal";
import { SuburbsContext } from "../contexts/SuburbsContext";
import { HeaderType } from "../interfaces/pagination.interfaces";

const headers: HeaderType[] = [
  { name: "Colonia" },
  { name: "Código postal" },
  { name: "Estado", hiddeMobile: true },
  { name: "" },
];
export const SuburbsView = () => {
  const {
    getSuburbs,
    links,
    pagination,
    list,
    clearSearchValue,
    searchFunction,
    searchvalue,
    createModal,
    showDelete,
    deleteSuburb,
    cancelDeleteSub,
    suburb,
  } = useContext(SuburbsContext);

  useEffect(() => {
    const timer = setTimeout(() => {
      if (searchvalue.length === 5) {
        getSuburbs(searchvalue);
      }
    }, 500);

    return () => clearTimeout(timer);
  }, [searchvalue]);

  return (
    <ContentWrapper>
      <PaginatedTableWrapper
        iconTableSearch
        title="Colonias"
        pagination={pagination}
        links={links}
        getData={getSuburbs}
        clearSearchValue={clearSearchValue}
        searchValue={searchvalue}
        searchFunction={searchFunction}
        headers={headers}
      >
        {list.map((suburb) => (
          <SuburbItem suburb={suburb} />
        ))}
      </PaginatedTableWrapper>
      <SuburbsModal />
      <ScrollToTopContainer>
        <ChildrenFab iconType="plus" onClick={createModal} />
      </ScrollToTopContainer>
      <ConfirmDialog
        open={showDelete}
        action={deleteSuburb}
        title="Eliminar colonia"
        size="sm"
        handleClose={cancelDeleteSub}
        actionTitle="Eliminar"
      >
        <Typography>
          ¿Confrimas que quires eliminar la colonia {suburb.name}?
        </Typography>
      </ConfirmDialog>
    </ContentWrapper>
  );
};

import { makeStyles } from "@material-ui/core";

export const useRolesStyles = makeStyles((theme) => ({
  root: {
    width: "100%",
  },
  heading: {
    fontSize: theme.typography.pxToRem(15),
  },
  secondaryHeading: {
    fontSize: theme.typography.pxToRem(15),
    color: theme.palette.text.secondary,
  },
  icon: {
    fontSize: theme.typography.pxToRem(50),
    marginRight: 10,
  },
  iconSumary: {
    marginRight: theme.spacing(3),
  },
  moduleIcon: {
    fontSize: theme.typography.pxToRem(8),
    marginRight: theme.spacing(2),
  },
  appsContainer: {
    marginTop: 15,
  },
  permissionsTitle: {
    fontSize: 20,
  },
}));

import { useState } from "react";
import { Grid } from "@material-ui/core";
import { ChildrenFab } from "./ChildrenFab";
import { useFloatinActionStyles } from "./styles";

interface Props {
  children: JSX.Element | JSX.Element[];
  hideToTopButton?: boolean;
}

export const ScrollToTopContainer = ({
  children,
  hideToTopButton = false,
}: Props) => {
  const styles = useFloatinActionStyles();
  const [showScroll, setShowScroll] = useState(false);

  const checkScrollTop = () => {
    if (!showScroll && window.pageYOffset > 200) {
      setShowScroll(true);
    } else if (showScroll && window.pageYOffset <= 150) {
      setShowScroll(false);
    }
  };

  const scrollTop = () => {
    window.scrollTo({ top: 0, behavior: "smooth" });
  };

  window.addEventListener("scroll", checkScrollTop);

  return (
    <Grid container className={styles.floatingContainer}>
      <Grid
        item
        container
        xs={12}
        justifyContent="center"
        className={styles.actionButtonContainer}
        style={{ border: "1px solir red" }}
      >
        {children}
        {hideToTopButton && (
          <ChildrenFab
            onClick={scrollTop}
            title="Ir Arriba"
            styles={{
              display: showScroll ? "flex" : "none",
            }}
          />
        )}
      </Grid>
    </Grid>
  );
};

import PeopleIcon from "@material-ui/icons/People";
import WorkIcon from "@material-ui/icons/Work";
import StoreIcon from "@material-ui/icons/Store";
import SettingsIcon from "@material-ui/icons/Settings";
import PermDataSettingIcon from "@material-ui/icons/PermDataSetting";

interface SubItmemMenu {
  label: string;
  path: string;
  slug: string;
  permissionName: string;
}
export interface MenuItem {
  label: string;
  path: string;
  icon: JSX.Element;
  slug: string;
  subItemsList: SubItmemMenu[];
}

const users: MenuItem = {
  label: "Clientes",
  path: "/clients",
  slug: "clients",
  icon: <PeopleIcon />,
  subItemsList: [
    {
      label: "Usuarios",
      path: "/users",
      slug: "users",
      permissionName: "leer_clientes",
    },
  ],
};

const jobs: MenuItem = {
  label: "Puestos",
  path: "/jobs",
  slug: "jobs",
  icon: <WorkIcon />,
  subItemsList: [
    {
      label: "Puestos",
      path: "/jobs",
      slug: "jobs",
      permissionName: "",
    },
  ],
};

const offices: MenuItem = {
  label: "Sucursales",
  path: "/establishments",
  slug: "establishments",
  icon: <StoreIcon />,
  subItemsList: [
    {
      label: "Sucursales",
      path: "/establishments",
      slug: "branch_offices",
      permissionName: "",
    },
  ],
};

const configs: MenuItem = {
  label: "Configuraciones",
  path: "/configs",
  slug: "configs",
  icon: <SettingsIcon />,
  subItemsList: [
    {
      label: "Descuentos",
      path: "/configs/discounts",
      slug: "configs_discounts",
      permissionName: "",
    },
    {
      label: "Homenajes",
      path: "/configs/products",
      slug: "configs-products",
      permissionName: "",
    },
    {
      label: "Tipos de Homenaje",
      path: "/configs/product-types",
      slug: "configs_product-types",
      permissionName: "",
    },
    {
      label: "Estado cilvil",
      path: "/configs/civil-status",
      slug: "configs_civil_status",
      permissionName: "",
    },
    {
      label: "Religiones",
      path: "/configs/religions",
      slug: "configs_religions",
      permissionName: "",
    },
    {
      label: "Colonias",
      path: "/configs/suburbs",
      slug: "configs_suburbs",
      permissionName: "",
    },
  ],
};

const roots: MenuItem = {
  label: "Folios y Transacciones",
  path: "/transacctions",
  slug: "transacctions",
  icon: <PermDataSettingIcon />,
  subItemsList: [
    /* {
      label: "Modulos y Permisos",
      path: "/modules",
      slug: "modules",
      permissionName: "",
    },
    {
      label: "Folios",
      path: "/folios",
      slug: "folios",
      permissionName: "",
    }, */
    {
      label: "Transacciones",
      path: "/transactions",
      slug: "transactions",
      permissionName: "",
    },
  ],
};

export const menuItems: MenuItem[] = [users, jobs, offices, configs, roots];

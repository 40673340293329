import { useContext, useEffect } from "react";
import { ContentWrapper } from "../components/Common/ContentWrapper/ContentWrapper";
import { PaginatedTableWrapper } from "../components/Common/PaginatedTableWrapper/PagintatedTableWrapper";
import { ServiceTypesContext } from "../contexts/ServiceTypesContext";
import { ServiceTypeItem } from "../components/ServiceTypes/ServiceTypeItem";
import { HeaderType } from "../interfaces/pagination.interfaces";
import { ScrollToTopContainer } from "../components/Common/ScrollToTopContatiner/ScrollToTopContainer";
import { ChildrenFab } from "../components/Common/ScrollToTopContatiner/ChildrenFab";
import { ServiceTypeModal } from "../components/ServiceTypes/ServiceTypeModal";

const headers: HeaderType[] = [
  { name: "#", hiddeMobile: true },
  { name: "Nombre" },
  { name: "Status" },
  { name: "" },
];

export const ServiceTypesView = () => {
  const {
    links,
    pagination,
    loadServicesTypes,
    getServicesTypes,
    serviceTypes,
    searchvalue,
    searchFunction,
    clearSearchValue,
    createType,
  } = useContext(ServiceTypesContext);

  useEffect(() => {
    loadServicesTypes();
  }, []);

  return (
    <ContentWrapper>
      <PaginatedTableWrapper
        links={links}
        iconTableSearch
        pagination={pagination}
        getData={getServicesTypes}
        reloadFunction={() => loadServicesTypes(true)}
        title="Tipos de homenaje"
        searchValue={searchvalue}
        searchFunction={searchFunction}
        clearSearchValue={clearSearchValue}
        headers={headers}
      >
        {serviceTypes.map((item) => (
          <ServiceTypeItem type={item} />
        ))}
      </PaginatedTableWrapper>
      <ScrollToTopContainer>
        <ChildrenFab iconType="plus" onClick={createType} />
      </ScrollToTopContainer>
      <ServiceTypeModal />
    </ContentWrapper>
  );
};

import { createContext, Dispatch, useReducer } from "react";
import { ErrorsAlertContainer } from "../components/Common/ErrorContainer/ErrorContainer";
import { MessageDialog } from "../components/Common/MessageDialog/MessageDialog";
import { LoadingSpinner } from "../components/LoadingSpinner/LoadingSpinner";
import { useSimpleUser, UseSimpleUserProps } from "../hooks/user/useSimpleUser";
import { useUsers, UseUsersProps } from "../hooks/user/useUsers";
import {
  usersReducer,
  UsersState,
  USERS_INITIAL_STATE,
} from "../reducers/users.reducer";
import { UsersActionTypes } from "../types/users.types";

interface ContextProps extends UsersState, UseUsersProps, UseSimpleUserProps {
  dispatch: Dispatch<UsersActionTypes>;
}

interface Props {
  children: JSX.Element | JSX.Element[];
}

export const UsersContext = createContext({} as ContextProps);

export const UsersProvider = ({ children }: Props) => {
  const initialState = USERS_INITIAL_STATE;

  const [state, dispatch] = useReducer(usersReducer, initialState);

  const usersContext = useUsers(state, dispatch);
  const simpleUserContext = useSimpleUser(state, dispatch);

  return (
    <UsersContext.Provider
      value={{ ...state, dispatch, ...usersContext, ...simpleUserContext }}
    >
      {children}
      <LoadingSpinner loading={state.loading} />
      <MessageDialog
        message={state.message}
        open={state.success}
        handleClose={() => dispatch({ type: "hideMessageDialog" })}
      />
      <ErrorsAlertContainer
        errors={state.errors}
        error={state.error}
        hideErrorsAlert={() => dispatch({ type: "hideErrorAlert" })}
        optionalMessage={state.message}
      />
    </UsersContext.Provider>
  );
};

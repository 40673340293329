import { ChangeEvent, Dispatch } from "react";
import { backendApi } from "../../api/backendApi";
import {
  ServiceTypeResponse,
  ServiceTypesResponse,
  ServiceTypeStatusResponse,
} from "../../interfaces/serviceTypes.interfaces";
import { ServiceTypesState } from "../../reducers/serviceTypes.reducer";
import { ServiceTypeActionTypes } from "../../types/serviceTypes.types";

export interface UseServiceTypesProps extends ServiceTypesState {
  getServicesTypes: (paht: string) => void;
  loadServicesTypes: (force?: boolean) => void;
  searchFunction: (e: ChangeEvent<HTMLInputElement>) => void;
  clearSearchValue: () => void;
  loadServiceTpesStatus: () => void;
  onChange: (e: ChangeEvent<{ name?: string; value: unknown }>) => void;
  closeModal: () => void;
  onSubmit: () => void;
  createType: () => void;
  setType: (id: string) => void;
}

export const useServiceTypes = (
  state: ServiceTypesState,
  dispatch: Dispatch<ServiceTypeActionTypes>
): UseServiceTypesProps => {
  const getServicesTypes = async (path: string) => {
    dispatch({ type: "getServiceTypes" });
    try {
      const response = await backendApi.get<ServiceTypesResponse>(path);
      dispatch({ type: "getServiceTypesSuccess", payload: response.data });
    } catch (error) {}
  };

  const loadServicesTypes = (force: boolean = true) => {
    if (state.serviceTypes.length === 0 || force) {
      getServicesTypes("service-types");
    }
  };

  const searchFunction = (e: ChangeEvent<HTMLInputElement>) => {
    dispatch({ type: "setSearchValue", payload: e.target.value });
  };

  const clearSearchValue = () => {
    dispatch({ type: "setSearchValue", payload: "" });
  };

  const loadServiceTpesStatus = async () => {
    if (state.status.length === 0) {
      try {
        dispatch({ type: "getStatus" });
        const response = await backendApi.get<ServiceTypeStatusResponse>(
          "service-types/status"
        );
        dispatch({ type: "getStatusSuccess", payload: response.data.data });
      } catch (error) {
        dispatch({ type: "getStatusError" });
      }
    }
  };

  const onChange = (e: ChangeEvent<{ name?: string; value: unknown }>) => {
    dispatch({
      type: "setType",
      payload: { ...state.type, [e.target.name!]: e.target.value },
    });
  };

  const closeModal = () => {
    dispatch({ type: "setShowModal", payload: false });
  };

  const saveType = async () => {
    dispatch({ type: "saveType" });
    try {
      const response = await backendApi.post<ServiceTypeResponse>(
        "service-types",
        state.type
      );
      dispatch({ type: "saveTypeSuccess", payload: response.data.data });
    } catch (error: any) {
      dispatch({ type: "saveTypeError", payload: error.response.data.errors });
    }
  };

  const updateType = async () => {
    dispatch({ type: "saveType" });
    try {
      const response = await backendApi.patch<ServiceTypeResponse>(
        `service-types/${state.type.id}`,
        state.type
      );
      dispatch({ type: "updateTypeSuccess", payload: response.data.data });
    } catch (error: any) {
      dispatch({ type: "saveTypeError", payload: error.response.data.errors });
    }
  };

  const onSubmit = () => {
    if (state.type.id) {
      updateType();
    } else {
      saveType();
    }
  };

  const createType = () => {
    dispatch({ type: "createType" });
  };

  const setType = (id: string) => {
    const type = state.serviceTypes.find((item) => item.id === id);
    if (type) {
      dispatch({ type: "setType", payload: type });
    }
  };

  return {
    ...state,
    getServicesTypes,
    loadServicesTypes,
    searchFunction,
    clearSearchValue,
    loadServiceTpesStatus,
    onChange,
    closeModal,
    onSubmit,
    createType,
    setType,
  };
};

import { Dispatch } from "react";
import { backendApi } from "../../api/backendApi";
import { PaymentFrequenciesResponse } from "../../interfaces/paymentFrequency.interfaces";
import { PaymentFrequencyState } from "../../reducers/paymentFrequenciesReducer";
import { PaymentFrequeciesAcionTypes } from "../../types/paymentFrequencies.types";

export interface UsePaymentFrequenciesProps extends PaymentFrequencyState {
  getPaymentFrequencies: (frequencyId?: string) => void;
  loadPaymentFrequecies: () => void;
}

export const usePaymentFrequencies = (
  state: PaymentFrequencyState,
  dispatch: Dispatch<PaymentFrequeciesAcionTypes>
): UsePaymentFrequenciesProps => {
  const getPaymentFrequencies = async (frequencyId?: string) => {
    dispatch({ type: "getPaymentFrequencies" });
    try {
      const response = await backendApi.get<PaymentFrequenciesResponse>(
        `payment-frequencies/${frequencyId}`
      );
      dispatch({
        type: "getPaymentFrequenciesSucess",
        payload: response.data.data,
      });
    } catch (error) {
      dispatch({ type: "getPaymentFrequenciesError" });
    }
  };

  const loadPaymentFrequecies = () => {
    getPaymentFrequencies();
  };

  return { ...state, getPaymentFrequencies, loadPaymentFrequecies };
};

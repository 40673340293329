import { AddressTypePaginated } from "../interfaces/addressesTypes.interfaces";
import {
  LinksPagination,
  MetaPagination,
} from "../interfaces/pagination.interfaces";
import { AddressTypesActionTypes } from "../types/addressesTypes.types";

export interface AddressesTypesState {
  list: AddressTypePaginated[];
  loading: boolean;
  links: LinksPagination;
  pagination: MetaPagination;
}
export const ADDRESS_TYPES_INITIAL_STATE: AddressesTypesState = {
  list: [],
  loading: false,
  pagination: {
    current_page: 1,
    from: 1,
    to: 10,
    last_page: 0,
    path: "",
    per_page: 10,
    total: 0,
  },
  links: {
    first: undefined,
    last: undefined,
    prev: undefined,
    next: undefined,
  },
};

export const addressesTypesReducer = (
  state = ADDRESS_TYPES_INITIAL_STATE,
  action: AddressTypesActionTypes
): AddressesTypesState => {
  switch (action.type) {
    case "getAddressTypes":
      return { ...state, loading: true };
    case "getAddressTypesError":
      return { ...state, loading: false };
    case "getAddressesTypesSuccess":
      return {
        ...state,
        list: action.payload.data,
        links: action.payload.links,
        pagination: action.payload.meta,
      };
    default:
      return state;
  }
};

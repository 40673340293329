import { createContext, Dispatch, useReducer } from "react";
import {
  useFinancements,
  UseFinancementsProps,
} from "../hooks/financements/useFinancements";
import { ProviderProps } from "../interfaces/common.interfaces";
import {
  financementsReducer,
  FinancementsState,
  FINANCEMENTS_INITIAL_STATE,
} from "../reducers/financementsReducer";
import { FinancementsActionTypes } from "../types/financements.types";

interface ContextProps extends FinancementsState, UseFinancementsProps {
  dispatch: Dispatch<FinancementsActionTypes>;
}

export const FinancementsContext = createContext({} as ContextProps);

export const FinancementsProvider = ({ children }: ProviderProps) => {
  const initialState = FINANCEMENTS_INITIAL_STATE;
  const [state, dispatch] = useReducer(financementsReducer, initialState);
  const financements = useFinancements(state, dispatch);
  return (
    <FinancementsContext.Provider
      value={{ ...state, dispatch, ...financements }}
    >
      {children}
    </FinancementsContext.Provider>
  );
};

import { makeStyles } from "@material-ui/core/styles";

export const useProductsStyles = makeStyles((theme) => ({
  annexedsTitleContainer: {
    marginTop: 15,
    marginBottom: 15,
  },
  annexedsTitle: {
    fontSize: 20,
    color: theme.palette.grey[700],
  },
  annexedsContainer: {
    marginTop: 10,
    paddingLeft: 20,
    paddingRight: 10,
    [theme.breakpoints.down("md")]: {
      paddingLeft: 0,
      paddingRight: 0,
    },
  },
  annexNumber: {
    padding: 10,
  },
  annexContainer: {
    flexWrap: "nowrap",
    marginBottom: 10,
    background: theme.palette.grey[100],
  },
  annexControl: {
    marginTop: 10,
  },
}));

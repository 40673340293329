import { ChangeEvent, Dispatch } from "react";
import { backendApi } from "../../api/backendApi";
import {
  AllCivilStatusResponse,
  CivilStatusResponse,
  CivilStatusStatusResponse,
} from "../../interfaces/civilSatus.interfaces";
import { CivilStatusState } from "../../reducers/civislStatus.reducer";
import { CivilStatusActiontypes } from "../../types/civilStatus.tyes";

export interface UseCivilStatusProps {
  getCivilStatus: (path: string) => void;
  loadCivilStatus: () => void;
  clearSearchvalue: () => void;
  onSubmit: () => void;
  handleClose: () => void;
  onChange: (e: ChangeEvent<{ name?: string; value: unknown }>) => void;
  createCivilStatus: () => void;
  loadStatus: () => void;
  setCivilStatus: (id: string) => void;
}

export const useCivilStatus = (
  state: CivilStatusState,
  dispatch: Dispatch<CivilStatusActiontypes>
): UseCivilStatusProps => {
  const getCivilStatus = async (path: string) => {
    try {
      dispatch({ type: "getCivilStatus" });
      const response = await backendApi.get<AllCivilStatusResponse>(path);
      dispatch({ type: "getCivilStatusSuccess", payload: response.data });
    } catch (error) {}
  };

  const loadCivilStatus = () => {
    if (state.list.length === 0) {
      getCivilStatus("civil-status");
    }
  };

  const clearSearchvalue = () => {};

  const onChange = (e: ChangeEvent<{ name?: string; value: unknown }>) => {
    dispatch({
      type: "setCivilStatus",
      payload: { ...state.civilStatus, [e.target.name!]: e.target.value },
    });
  };

  const setCivilStatus = (id: string) => {
    const data = state.list.find((item) => item.id === id);

    if (data) {
      dispatch({ type: "updateCivilStatus", payload: data });
    }
  };

  const saveStatus = async () => {
    try {
      dispatch({ type: "saveStatus" });
      const response = await backendApi.post<CivilStatusResponse>(
        "civil-status",
        state.civilStatus
      );
      dispatch({ type: "saveStatusSuccess", payload: response.data.data });
    } catch (error: any) {
      if (error.response.status === 422) {
        dispatch({
          type: "saveStatusError",
          payload: error.response.data.errors,
        });
      } else {
        dispatch({ type: "badRequest", payload: error.response.data.message });
      }
    }
  };

  const updateStatus = async () => {
    try {
      dispatch({ type: "saveStatus" });
      const response = await backendApi.patch<CivilStatusResponse>(
        `civil-status/${state.civilStatus.id}`,
        state.civilStatus
      );
      dispatch({ type: "updateStatusSuccess", payload: response.data.data });
    } catch (error: any) {
      if (error.response.status === 422) {
        dispatch({
          type: "saveStatusError",
          payload: error.response.data.errors,
        });
      } else {
        dispatch({ type: "badRequest", payload: error.response.data.message });
      }
    }
  };

  const onSubmit = () => {
    if (!state.civilStatus.id) {
      saveStatus();
    } else {
      updateStatus();
    }
  };

  const handleClose = () => {
    dispatch({ type: "closeModal" });
  };

  const createCivilStatus = () => {
    dispatch({ type: "createCivilStatus" });
  };

  const loadStatus = async () => {
    if (state.status.length === 0) {
      try {
        dispatch({ type: "getStatus" });
        const response = await backendApi.get<CivilStatusStatusResponse>(
          "civil-status/status"
        );
        dispatch({ type: "getStatusSuccess", payload: response.data });
      } catch (error) {
        dispatch({ type: "getStatusError" });
      }
    }
  };

  return {
    getCivilStatus,
    loadCivilStatus,
    clearSearchvalue,
    onChange,
    onSubmit,
    handleClose,
    createCivilStatus,
    loadStatus,
    setCivilStatus,
  };
};

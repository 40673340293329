import { Button } from "@material-ui/core";
import React, { CSSProperties } from "react";
import { CustomButtonIconTypes, CustomIcon } from "./CustomIconTypes";

interface Props {
  label?: string;
  onClick: () => void;
  styles?: CSSProperties;
  iconType?: CustomButtonIconTypes;
  iconStyles?: CSSProperties;
}
export const CustomActionButton = ({
  label = "Guardar",
  styles,
  iconType,
  iconStyles,
  onClick,
}: Props) => {
  return (
    <Button
      component="span"
      onClick={onClick}
      style={styles}
      color="primary"
      variant="contained"
    >
      {label}
      {iconType && <CustomIcon iconType={iconType} style={iconStyles} />}
    </Button>
  );
};

import { Button } from "@material-ui/core";
import { Label } from "@material-ui/icons";
import { CSSProperties } from "react";

interface Props {
  label: string;
  styles?: CSSProperties;
}
export const CustomSubmit = ({ label, styles }: Props) => {
  return (
    <Button type="submit" style={styles} color="primary" variant="contained">
      {label}
    </Button>
  );
};

import { makeStyles } from "@material-ui/core";

export const useLoadingSpinnerStyles = makeStyles((theme) => ({
  spinnerContainer: {
    zIndex: 1500,
    position: "fixed",
    top: "50%",
    left: "45%",
    [theme.breakpoints.down("md")]: {
      left: "25%",
      right: "25%",
      justifySelf: "center",
    },
  },
}));

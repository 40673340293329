import {
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Grid,
  Typography,
} from "@material-ui/core";
import { useContext } from "react";
import { TransactionContext } from "../../contexts/TransactionsContext";
import { CustomActionButton } from "../Common/ActionButtons/CustomActionButton";
import { SecondaryActionButton } from "../Common/ActionButtons/SecondaryActionButton";
import { useTransactionStyles } from "./styles";

export const DeleteFolioModal = () => {
  const classes = useTransactionStyles();
  const { cancelDeleteFolio, transaction, deleteFolio } =
    useContext(TransactionContext);
  const { showDeleteFolioModal, folio } = useContext(TransactionContext);
  return (
    <Dialog
      open={showDeleteFolioModal}
      onClose={cancelDeleteFolio}
      fullWidth
      maxWidth="sm"
    >
      <DialogTitle>Elimiar folio</DialogTitle>
      <DialogContent>
        <Grid container>
          <Grid item xs={12}>
            <Typography className={classes.deleteFolioTitle}>
              El folio <span className={classes.nameItem}>{`${folio.id}`}</span>
              de la transacción
              <span className={classes.nameItem}>{`${transaction.name}`}</span>
              será eliminado
            </Typography>
          </Grid>
        </Grid>
      </DialogContent>
      <DialogActions>
        <CustomActionButton label="Eliminar folio" onClick={deleteFolio} />
        <SecondaryActionButton onClick={cancelDeleteFolio} />
      </DialogActions>
    </Dialog>
  );
};

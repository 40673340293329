import { useContext, useState } from "react";
import { Button, Typography } from "@material-ui/core";
import pandora from "../../../assets/pandora.svg";
import { AuthContext } from "../../../contexts/AuthContext";
import { useSideBarStyles } from "../../SideBar/styles";
import { ConfirmDialog } from "../../Common/ConfirmDialog/ConfirmDialog";
import "./UserCard.css";

export const UserCard = () => {
  const classes = useSideBarStyles();
  let styles = classes.toolbar;
  styles += " user-card px-0";

  const { logout } = useContext(AuthContext);
  const [showLogout, setShowLogout] = useState(false);
  const openLogout = () => setShowLogout(!showLogout);

  return (
    <>
      <div className={styles}>
        <div className="container-fluid">
          <div className="row">
            <img src={pandora} alt="" className="w-75 ml-4" />
            <div className="col-12 d-flex justify-content-start mt-4">
              <h3 className="user-full-name">Configuración</h3>
            </div>
            <div className="col-12 d-flex justify-content-start">
              <Button
                className="btn btn-link logout-link px-0 ml-2"
                onClick={openLogout}
                component="span"
                style={{ color: "white" }}
              >
                Cierra sesión
              </Button>
            </div>
          </div>
        </div>
      </div>
      <ConfirmDialog
        size="sm"
        handleClose={openLogout}
        open={showLogout}
        actionTitle="Cerrar Sesión"
        action={logout}
        title="Cerrar sesión"
      >
        <div className={classes.textCenter}>
          <Typography variant="h4">¿Cerrar sesión?</Typography>
        </div>
      </ConfirmDialog>
    </>
  );
};

import { AppState } from "./contexts/AppState";
import { AppRouting } from "./views/AppRouting";
import { HashRouter } from "react-router-dom";
import { ThemeProvider } from "@material-ui/core";
import { theme } from "./theme/theme";

const App = () => {
  return (
    <ThemeProvider theme={theme}>
      <HashRouter>
        <AppState>
          <AppRouting />
        </AppState>
      </HashRouter>
    </ThemeProvider>
  );
};

export default App;

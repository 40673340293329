import { CSSProperties } from "react";
import PulseLoader from "react-spinners/PulseLoader";
import { useLoadingSpinnerStyles } from "./styles";

type SpinnerColor = "primary" | "white";

interface Props {
  loading: boolean;
  color?: SpinnerColor;
  styles?: CSSProperties;
}

export const LoadingSpinner = ({
  loading,
  color = "primary",
  styles,
}: Props) => {
  const classes = useLoadingSpinnerStyles();

  const finalColor = (color: SpinnerColor) => {
    switch (color) {
      case "primary":
        return "#993399";
      case "white":
        return "white";
      default:
        return "";
    }
  };
  return (
    <div className={classes.spinnerContainer} style={styles}>
      <PulseLoader size={50} color={finalColor(color)} loading={loading} />
    </div>
  );
};

import { TableCell, TableRow } from "@material-ui/core";
import { ProductPrice } from "../../../interfaces/products.interfaces";
import { useContext } from "react";
import { GeneralContext } from "../../../contexts/GeneralContext";
import { CustomIconButton } from "../../Common/ActionButtons/CustomIconButton";
import { ProductsContext } from "../../../contexts/ProductsContext";
import { CustomTableCell } from "../../Common/PaginatedTableWrapper/CustomTableCell";

interface Props {
  price: ProductPrice;
}
export const ProductPriceItem = ({ price }: Props) => {
  const { mxCurrencyFormat } = useContext(GeneralContext);
  const { showEditPrice } = useContext(ProductsContext);
  return (
    <TableRow>
      <CustomTableCell>{price.financement?.name || ""}</CustomTableCell>
      <CustomTableCell hiddenMobile>
        {mxCurrencyFormat(price.cashPaymentPrice)}
      </CustomTableCell>
      <CustomTableCell hiddenMobile>{`${price.factor}`}</CustomTableCell>
      <CustomTableCell>
        {mxCurrencyFormat(price.finalPrice || 0)}
      </CustomTableCell>
      <CustomTableCell hiddenMobile>
        {price.priceList?.name || ""}
      </CustomTableCell>
      <CustomTableCell>
        <CustomIconButton
          iconType="edit"
          onClick={() => showEditPrice(price.id!)}
        />
      </CustomTableCell>
    </TableRow>
  );
};

import { AuthProvider } from "./AuthContext";
import { DiscountsProvider } from "./DiscountsContext";
import { FinancementsProvider } from "./FianacementsContext";
import { GeneralProvider } from "./GeneralContext";
import { RolesProvider } from "./RolesContext";
import { PaymentFrequenciesProvider } from "./PaymentFrequenciesContex";
import { PriceListProvider } from "./PriceListContext";
import { ProductsProvider } from "./ProductsContext";
import { ServiceTypesProvider } from "./ServiceTypesContext";
import { UsersProvider } from "./UsersContext";
import { UtislProvider } from "./UtilsContext";
import { AddressesTypesProvider } from "./AddressesTypesContext";
import { PhoneTypesProvider } from "./PhoneTypesContext";
import { EstablishmentsProvider } from "./EstablishmentsContext";
import { CivilStatusProvider } from "./CivilStatusContext";
import { ReligionsProvider } from "./ReligionsContext";
import { SuburbsProvider } from "./SuburbsContext";
import { TransactionsProvider } from "./TransactionsContext";

export interface Props {
  children: JSX.Element | JSX.Element[];
}

export const AppState = ({ children }: Props) => {
  return (
    <GeneralProvider>
      <AuthProvider>
        <RolesProvider>
          <UsersProvider>
            <DiscountsProvider>
              <PaymentFrequenciesProvider>
                <ServiceTypesProvider>
                  <PriceListProvider>
                    <FinancementsProvider>
                      <ProductsProvider>
                        <EstablishmentsProvider>
                          <UtislProvider>
                            <PhoneTypesProvider>
                              <AddressesTypesProvider>
                                <CivilStatusProvider>
                                  <ReligionsProvider>
                                    <SuburbsProvider>
                                      <TransactionsProvider>
                                        {children}
                                      </TransactionsProvider>
                                    </SuburbsProvider>
                                  </ReligionsProvider>
                                </CivilStatusProvider>
                              </AddressesTypesProvider>
                            </PhoneTypesProvider>
                          </UtislProvider>
                        </EstablishmentsProvider>
                      </ProductsProvider>
                    </FinancementsProvider>
                  </PriceListProvider>
                </ServiceTypesProvider>
              </PaymentFrequenciesProvider>
            </DiscountsProvider>
          </UsersProvider>
        </RolesProvider>
      </AuthProvider>
    </GeneralProvider>
  );
};

import { makeStyles } from "@material-ui/core";

export const useServiceTypeStyles = makeStyles((theme) => ({
  V: {
    color: theme.palette.primary.main,
  },
  C: {
    color: theme.palette.error.main,
  },
  icon: {
    marginRight: theme.spacing(1),
    fontSize: 12,
    [theme.breakpoints.down("md")]: {
      display: "none",
    },
  },
  statusLabel: {
    fontSize: 12,
  },
  largeStatusLabel: {
    fontSize: 12,
  },
}));

import { Avatar, Grid, IconButton } from "@material-ui/core";
import { useContext } from "react";
import CameraAltIcon from "@material-ui/icons/CameraAlt";
import DeleteIcon from "@material-ui/icons/Delete";
import { UsersContext } from "../../../contexts/UsersContext";
import { useUserFormStyles } from "./styles";
import { remoteImages } from "../../../api/backendApi";

export const UserAvatarForm = () => {
  const classes = useUserFormStyles();

  const { user, handleImageUpload, image, imageRoute, deleteImage } =
    useContext(UsersContext);
  return (
    <Grid item container alignItems="center">
      <Avatar
        className={classes.avatar}
        src={
          !user.image ? imageRoute?.toString() : `${remoteImages}/${user.image}`
        }
      >
        <input
          accept="image/*"
          className="d-none"
          id="contained-button-file"
          name="contained-button-file"
          type="file"
          onChange={handleImageUpload}
        />
        <label htmlFor="contained-button-file">
          <CameraAltIcon />
        </label>
      </Avatar>
      {(user.image || image) && (
        <IconButton onClick={deleteImage} component="span">
          <DeleteIcon />
        </IconButton>
      )}
    </Grid>
  );
};

import { ChangeEvent, Dispatch, useState } from "react";
import { backendApi } from "../../api/backendApi";
import {
  Discount,
  SaveDiscountResponse,
} from "../../interfaces/discounts.interfaces";
import { DiscountsState } from "../../reducers/discounts.reducer";
import { DiscountActionTypes } from "../../types/discounts.types";

type DiscountType = "is_percentage" | "is_discount";

export interface UseSimpleDiscountProps {
  isDiscount: DiscountType;
  handleIsDiscount: (e: ChangeEvent<HTMLInputElement>) => void;
  onSubmit: () => void;
  setDiscount: (id: string) => void;
}

export const useSimpleDiscount = (
  state: DiscountsState,
  dispatch: Dispatch<DiscountActionTypes>
): UseSimpleDiscountProps => {
  const [isDiscount, setIsDiscount] = useState<DiscountType>("is_discount");

  const handleIsDiscount = (e: ChangeEvent<HTMLInputElement>) => {
    setIsDiscount(e.target.value as DiscountType);
  };

  const updateDiscount = async (data: Discount) => {
    dispatch({ type: "saveDiscount" });
    try {
      const response = await backendApi.patch<SaveDiscountResponse>(
        `discounts/${data.id}`,
        data
      );
      dispatch({
        type: "updateDiscountSuccess",
        payload: { message: response.data.message, data: response.data.data },
      });
    } catch (error: any) {
      if (error.response.status === 422) {
        dispatch({
          type: "saveDiscountError",
          payload: error.resoponse.data.errors,
        });
      }
    }
  };

  const saveDiscount = async (data: Discount) => {
    dispatch({ type: "saveDiscount" });
    try {
      const response = await backendApi.post<SaveDiscountResponse>(
        "discounts",
        data
      );
      dispatch({
        type: "saveDiscountSuccess",
        payload: { message: response.data.message, data: response.data.data },
      });
    } catch (error: any) {
      if (error.response.status === 422) {
        dispatch({
          type: "saveDiscountError",
          payload: error.response.data.errors,
        });
      }
    }
  };
  const setDiscount = (discountId: string) => {
    const data = state.list.find((discount) => discount.id === discountId);
    if (data) {
      dispatch({ type: "setDiscount", payload: data });
      setIsDiscount(data.isDiscount ? "is_discount" : "is_percentage");
    }
  };

  const onSubmit = () => {
    const data: Discount = {
      ...state.discount,
      isDiscount: isDiscount === "is_discount",
      isPercentage: isDiscount === "is_percentage",
    };

    if (state.discount.id) {
      updateDiscount(data);
    } else {
      saveDiscount(data);
    }
  };

  return {
    isDiscount,
    handleIsDiscount,
    onSubmit,
    setDiscount,
  };
};

import { Dialog, Typography } from "@material-ui/core";
import { ChangeEvent, useContext, useState } from "react";
import { TransactionContext } from "../../contexts/TransactionsContext";
import { DataTab } from "./DataTab";
import { FoliosTab } from "./FoliosTab";
import { TabsIndex, TransactionsModalHeader } from "./ModalHeder";
import { TabPanel } from "./TabPanel";

export const TransactionModal = () => {
  const [value, setValue] = useState<TabsIndex>("data");

  const handleChange = (event: ChangeEvent<{}>, newValue: TabsIndex) => {
    setValue(newValue);
  };
  const { showModal, cancelSave } = useContext(TransactionContext);
  return (
    <Dialog open={showModal} maxWidth="sm" fullWidth onClose={cancelSave}>
      <TransactionsModalHeader handleChange={handleChange} value={value} />
      <TabPanel index="data" value={value}>
        <DataTab />
      </TabPanel>
      <TabPanel index="folios" value={value}>
        <FoliosTab />
      </TabPanel>
    </Dialog>
  );
};

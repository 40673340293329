import {
  Discount,
  DiscountErrors,
  DiscountStatus,
} from "../interfaces/discounts.interfaces";
import {
  LinksPagination,
  MetaPagination,
} from "../interfaces/pagination.interfaces";
import {
  DiscountActionTypes,
  DiscountStatusValue,
} from "../types/discounts.types";

export interface DiscountsState {
  loading: boolean;
  list: Discount[];
  showNewDiscountModal: boolean;
  showEditDiscountModal: boolean;
  searchValue: string;
  discount: Discount;
  showDiscountModal: boolean;
  statuses: DiscountStatus[];
  errors: DiscountErrors;
  message: string;
  success: boolean;
  shortSuccess: boolean;
  error: boolean;
  links: LinksPagination;
  pagination: MetaPagination;
}
export const DISCOUNTS_INITIAL_STATE: DiscountsState = {
  loading: false,
  list: [],
  showEditDiscountModal: false,
  showNewDiscountModal: false,
  showDiscountModal: false,
  searchValue: "",
  discount: {
    name: "",
    amount: 0,
    isDiscount: false,
    isPercentage: false,
    status: DiscountStatusValue.current,
    statusLabel: "",
  },
  statuses: [],
  errors: {
    name: [],
    amount: [],
    status: [],
  },
  success: false,
  shortSuccess: false,
  message: "",
  error: false,
  pagination: {
    current_page: 1,
    from: 1,
    to: 10,
    last_page: 0,
    path: "",
    per_page: 10,
    total: 0,
  },
  links: {
    first: undefined,
    last: undefined,
    prev: undefined,
    next: undefined,
  },
};

export const discountsReducer = (
  state = DISCOUNTS_INITIAL_STATE,
  action: DiscountActionTypes
): DiscountsState => {
  switch (action.type) {
    case "getDiscounts":
    case "getStatuses":
    case "saveDiscount":
      return {
        ...state,
        loading: true,
      };
    case "getStatusesError":
    case "getDiscountsError":
      return {
        ...state,
        loading: false,
      };
    case "getDiscountsSuccess":
      return {
        ...state,
        loading: false,
        list: action.payload.data,
        links: action.payload.links,
        pagination: action.payload.meta,
      };
    case "setSearchValue":
      return {
        ...state,
        searchValue: action.payload,
      };
    case "setShowEditDiscountModal":
      return {
        ...state,
        showEditDiscountModal: action.payload,
      };
    case "setShowNewDiscountModal":
      return {
        ...state,
        showDiscountModal: true,
        discount: DISCOUNTS_INITIAL_STATE.discount,
      };
    case "setShowDiscountModal":
      return {
        ...state,
        showDiscountModal: action.payload,
      };
    case "setDiscount":
      return {
        ...state,
        discount: action.payload,
        showDiscountModal: true,
      };
    case "getStatusesSuccess":
      return {
        ...state,
        statuses: action.payload,
      };
    case "hideAlert":
      return {
        ...state,
        error: false,
        success: false,
        shortSuccess: false,
        message: "",
      };
    case "saveDiscountError":
      return {
        ...state,
        loading: false,
        error: true,
        errors: {
          ...DISCOUNTS_INITIAL_STATE.errors,
          ...action.payload,
        },
      };
    case "saveDiscountSuccess":
      return {
        ...state,
        loading: false,
        error: false,
        errors: DISCOUNTS_INITIAL_STATE.errors,
        list: [action.payload.data, ...state.list],
        discount: action.payload.data,
        shortSuccess: true,
        message: "Descuento creado correctamente",
        showDiscountModal: false,
      };
    case "updateDiscountSuccess":
      return {
        ...state,
        error: false,
        loading: false,
        shortSuccess: true,
        discount: action.payload.data,
        list: state.list.map((item) =>
          item.id === action.payload.data.id ? action.payload.data : item
        ),
        message: "Descuento actualizado correctamente",
        showDiscountModal: false,
      };
    default:
      return state;
  }
};

import { useContext, useEffect } from "react";
import {
  FormControl,
  FormHelperText,
  Grid,
  InputLabel,
  MenuItem,
  Select,
  TextField,
} from "@material-ui/core";
import { ProductsContext } from "../../../contexts/ProductsContext";
import { CustomSubmit } from "../../Common/ActionButtons/CustomSubmit";
import { SecondaryActionButton } from "../../Common/ActionButtons/SecondaryActionButton";

export const ProductPriceForm = () => {
  const {
    product,
    price,
    errors,
    onChangePrice,
    onSubmitPrice,
    loadFinancements,
    financements,
    loadPriceLists,
    priceLists,
    dispatch,
  } = useContext(ProductsContext);

  useEffect(() => {
    loadPriceLists();
    loadFinancements();
  }, []);

  return (
    <form onSubmit={onSubmitPrice}>
      <Grid container spacing={2}>
        <Grid item xs={12}>
          <FormControl fullWidth error={errors.financementId.length > 0}>
            <InputLabel id="label-financementId">Financiamiento</InputLabel>
            <Select
              labelId="label-financementId"
              name="financementId"
              value={price.financementId}
              onChange={onChangePrice}
            >
              {financements.map((financement) => (
                <MenuItem value={financement.id}>{financement.name}</MenuItem>
              ))}
            </Select>
            <FormHelperText>{errors.financementId[0]}</FormHelperText>
          </FormControl>
        </Grid>
        {(product.prices.length === 0 || price.factor == 1) && (
          <Grid item xs={12}>
            <FormControl fullWidth>
              <TextField
                label="Precio de contado"
                name="cashPaymentPrice"
                value={price.cashPaymentPrice}
                onChange={onChangePrice}
                error={errors.cashPaymentPrice.length > 0}
                helperText={errors.cashPaymentPrice[0]}
              />
            </FormControl>
          </Grid>
        )}
        <Grid item xs={12}>
          <FormControl fullWidth>
            <TextField
              label="Factor de financiamiento"
              name="factor"
              value={price.factor}
              onChange={onChangePrice}
              error={errors.factor.length > 0}
              helperText={errors.factor[0]}
            />
          </FormControl>
        </Grid>
        <Grid item xs={12}>
          <FormControl fullWidth error={errors.priceListId.length > 0}>
            <InputLabel id="label-priceListId">Lista de precios</InputLabel>
            <Select
              labelId="label-priceListId"
              label="Lista de precios"
              name="priceListId"
              value={price.priceListId}
              onChange={onChangePrice}
            >
              {priceLists.map((list) => (
                <MenuItem value={list.id}>{list.name}</MenuItem>
              ))}
            </Select>
            <FormHelperText>{errors.priceListId[0]}</FormHelperText>
          </FormControl>
        </Grid>
        <Grid item xs={12} container justifyContent="flex-end">
          <CustomSubmit label="Guardar" />
          <SecondaryActionButton
            styles={{ marginLeft: 10 }}
            onClick={() => dispatch({ type: "hideCreatePrice" })}
          />
        </Grid>
      </Grid>
    </form>
  );
};

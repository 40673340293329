import { CSSProperties } from "react";
import AddIcon from "@material-ui/icons/Add";
import EditIcon from "@material-ui/icons/Edit";
import DeleteIcon from "@material-ui/icons/Delete";

export type CustomButtonIconTypes = "add" | "edit" | "delete";

interface IconProps {
  style?: CSSProperties;
  iconType: CustomButtonIconTypes;
}

export const CustomIcon = ({ iconType, style }: IconProps) => {
  switch (iconType) {
    case "add":
      return <AddIcon style={style} />;
    case "edit":
      return <EditIcon style={style} />;
    case "delete":
      return <DeleteIcon style={style} />;
  }
};

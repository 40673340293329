import {
  Discount,
  DiscountErrors,
  DiscountsResponse,
  DiscountStatus,
} from "../interfaces/discounts.interfaces";

export type DiscountActionTypes =
  | { type: "getDiscounts" }
  | { type: "getDiscountsError" }
  | { type: "getDiscountsSuccess"; payload: DiscountsResponse }
  | { type: "setShowEditDiscountModal"; payload: boolean }
  | { type: "setShowNewDiscountModal" }
  | { type: "setSearchValue"; payload: string }
  | { type: "setDiscount"; payload: Discount }
  | { type: "setShowDiscountModal"; payload: boolean }
  | { type: "getStatuses" }
  | { type: "getStatusesError" }
  | { type: "getStatusesSuccess"; payload: DiscountStatus[] }
  | { type: "saveDiscount" }
  | { type: "saveDiscountError"; payload: DiscountErrors }
  | {
      type: "saveDiscountSuccess";
      payload: { message: string; data: Discount };
    }
  | {
      type: "updateDiscountSuccess";
      payload: { message: string; data: Discount };
    }
  | { type: "hideAlert" }
  | { type: "invalidRequest" };

export enum DiscountStatusValue {
  current = "V",
  inactive = "I",
}

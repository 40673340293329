import { createContext, useContext, useReducer } from "react";
import { CustomAlert } from "../components/Common/Alert/Alert";
import { LoadingSpinner } from "../components/LoadingSpinner/LoadingSpinner";
import { ProviderProps } from "../interfaces/common.interfaces";
import {
  INITIAL_STATE,
  SuburbsState,
  subursReducer,
} from "../reducers/suburbs.reducer";
import { useSuburbs, UseSuburbsProps } from "./useSuburbs";

interface ContextProps extends UseSuburbsProps, SuburbsState {}
export const SuburbsContext = createContext({} as ContextProps);

export const SuburbsProvider = ({ children }: ProviderProps) => {
  const initialState = INITIAL_STATE;
  const [state, dispatch] = useReducer(subursReducer, initialState);

  const context = useSuburbs(state, dispatch);
  return (
    <SuburbsContext.Provider value={{ ...state, ...context }}>
      {children}
      <LoadingSpinner loading={state.loading} />
      <CustomAlert
        message={state.message}
        handleClose={() => dispatch({ type: "hideAlert" })}
        show={state.success}
      />
      <CustomAlert
        message={state.message}
        handleClose={() => dispatch({ type: "hideAlert" })}
        show={state.error}
        severity="error"
      />
    </SuburbsContext.Provider>
  );
};

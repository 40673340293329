import Brightness1Icon from "@material-ui/icons/Brightness1";
import { Grid, Typography } from "@material-ui/core";
import { useServiceTypeStyles } from "./style";
import { ServiceTypeStatus } from "../../interfaces/serviceTypes.interfaces";

interface Props {
  type: ServiceTypeStatus;
  label?: string;
  showIcon?: boolean;
  uppercase?: boolean;
}

export const ServiceTypeStatusIcon = ({
  type,
  label = "",
  showIcon = true,
  uppercase = false,
}: Props) => {
  const classes = useServiceTypeStyles();

  return (
    <Grid
      item
      xs={12}
      container
      justifyContent="flex-start"
      alignItems="center"
    >
      {showIcon ? (
        <>
          <Brightness1Icon
            className={`${classes.icon} ${classes[type]}`}
            fontSize="small"
          />
          <Typography className={`${classes[type]} ${classes.statusLabel}`}>
            {uppercase ? label.toUpperCase() : label}
          </Typography>
        </>
      ) : (
        <Typography className={`${classes[type]} ${classes.largeStatusLabel}`}>
          {uppercase ? label.toUpperCase() : label}
        </Typography>
      )}
    </Grid>
  );
};

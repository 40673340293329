import { Typography } from "@material-ui/core";
import { useContext } from "react";
import { EstablishmentsContext } from "../../contexts/EstablishmentsContext";
import { CustomAppBar } from "../Common/CustomAppBar/CustomAppBar";
import { UseStablishmentsStyles } from "./styles";

export const EstablishmentContentAppBar = () => {
  const classes = UseStablishmentsStyles();
  const { establishment } = useContext(EstablishmentsContext);
  return (
    <CustomAppBar path="/establishments" visibleBAckIcon>
      <Typography className={classes.establishmentName}>
        {establishment.name || "Nueva sucursal"}
      </Typography>
    </CustomAppBar>
  );
};

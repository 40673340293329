import { ChangeEvent, useContext, useState } from "react";
import { Dialog } from "@material-ui/core";
import { TabsIndex, UpdateModalHeader } from "./ModalHeder";
import { DataTab } from "./DataTab";
import { TabPanel } from "./TabPanel";
import { FinancementsTab } from "./FinancementTab";
import { PaymentFrequencyTab } from "./PaymentFrequencyTab";
import { ProductsContext } from "../../../contexts/ProductsContext";

export const EditProductModal = () => {
  const { showEditProduct, dispatch, resetProduct } =
    useContext(ProductsContext);

  const [value, setValue] = useState<TabsIndex>("data");

  const handleChange = (event: ChangeEvent<{}>, newValue: TabsIndex) => {
    setValue(newValue);
  };

  return (
    <Dialog
      open={showEditProduct}
      onClose={() => {
        dispatch({ type: "hideEditProduct" });
        resetProduct();
      }}
      maxWidth="md"
      fullWidth
      style={{ position: "absolute" }}
    >
      <UpdateModalHeader handleChange={handleChange} value={value} />
      <TabPanel index="data" value={value}>
        <DataTab />
      </TabPanel>
      <TabPanel index="financements" value={value}>
        <FinancementsTab />
      </TabPanel>
      <TabPanel index="paymentFrequency" value={value}>
        <PaymentFrequencyTab />
      </TabPanel>
    </Dialog>
  );
};

import { ChangeEvent, Dispatch, FormEvent } from "react";
import { backendApi } from "../../api/backendApi";
import {
  ProductResponse,
  FullProductResponse,
  AnnexedResponse,
  ProductPriceResponse,
  PaymentFrequencyProductAmountResponse,
} from "../../interfaces/products.interfaces";
import { ProductsState } from "../../reducers/products.reducer";
import { ProductActionTypes } from "../../types/products.types";

export interface UseSimpleProuductProps {
  setProduct: (id: string) => void;
  onChangeAnnex: (e: ChangeEvent<HTMLInputElement>, index: number) => void;
  onChangeProduct: (
    e: ChangeEvent<HTMLSelectElement | HTMLInputElement>
  ) => void;
  onSubmitNew: (e: FormEvent<HTMLFormElement>) => void;
  onSubmit: (e: FormEvent<HTMLFormElement>) => void;
  onChangePrice: (e: ChangeEvent<{ name?: string; value: unknown }>) => void;
  onSubmitPrice: (e: FormEvent<HTMLFormElement>) => void;
  showEditPrice: (id: number) => void;
  resetFrequencyProductAmount: () => void;
  onChangetFrequencyProductAmount: (
    e: ChangeEvent<{ name?: string; value: unknown }>
  ) => void;
  resetProduct: () => void;
  onSubmitFrequencyProductAmount: () => void;
  setFrequencyProductAmount: (id: number) => void;
  showCreateProduct: () => void;
}

export const useSimpleProuduct = (
  state: ProductsState,
  dispatch: Dispatch<ProductActionTypes>
): UseSimpleProuductProps => {
  const setProduct = async (id: string) => {
    try {
      dispatch({ type: "getProductData" });
      const response = await backendApi.get<FullProductResponse>(
        `products/${id}`
      );
      dispatch({ type: "getProductDataSucccess", payload: response.data.data });
      dispatch({ type: "showEditProduct" });
    } catch (error) {
      dispatch({ type: "getProductDataError" });
    }
  };

  const showCreateProduct = () => {
    dispatch({ type: "showNewProduct" });
    dispatch({ type: "resetProduct" });
  };

  const onChangeAnnex = async (
    e: ChangeEvent<HTMLInputElement>,
    id: number
  ) => {
    const { value, checked } = e.target;

    try {
      dispatch({ type: "onChangeAnnexed" });
      const response = await backendApi.post<AnnexedResponse>(
        "products/update-annex",
        {
          productId: state.product.id,
          annexedId: value,
          aplicable: checked,
        }
      );
      dispatch({ type: "onChangeAnnexedSuccess", payload: response.data.data });
    } catch (error) {
      dispatch({ type: "onChangeAnnexedError" });
    }
  };

  const onChangeProduct = (
    e: ChangeEvent<HTMLSelectElement | HTMLInputElement>
  ) => {
    dispatch({
      type: "setProduct",
      payload: { ...state.product, [e.target.name]: e.target.value },
    });
  };

  const onSubmitNew = async (e: FormEvent<HTMLFormElement>) => {
    e.preventDefault();
    dispatch({ type: "createProduct" });
    const { name, typeId, status } = state.product;
    try {
      const response = await backendApi.post<ProductResponse>("products", {
        name,
        typeId,
        status,
      });
      dispatch({ type: "createProductSuccess", payload: response.data.data });
    } catch (error: any) {
      if (error.response.status === 422) {
        dispatch({
          type: "createProductError",
          payload: error.response.data,
        });
      }
    }
  };

  const onSubmit = async (e: FormEvent<HTMLFormElement>) => {
    e.preventDefault();
    dispatch({ type: "updateProduct" });
    try {
      const { name, typeId, status, id } = state.product;
      const response = await backendApi.put<ProductResponse>(`products/${id}`, {
        name,
        typeId,
        status,
      });
      dispatch({ type: "updateProductSuccess", payload: response.data.data });
    } catch (error: any) {
      if (error.response.status === 422) {
        dispatch({
          type: "updateProductError",
          payload: error.response.data.errors,
        });
      }
    }
  };

  const onChangePrice = (e: ChangeEvent<{ name?: string; value: unknown }>) => {
    if (e.target.name) {
      dispatch({
        type: "setPrice",
        payload: { ...state.price, [e.target.name]: e.target.value },
      });
    }
  };

  const createPrice = async () => {
    try {
      dispatch({ type: "savePrice" });
      const { price } = state;
      const response = await backendApi.post<ProductPriceResponse>(
        `product-prices/${state.product.id}`,
        price
      );
      dispatch({ type: "createPriceSuccess", payload: response.data.data });
    } catch (error: any) {
      if (error.response.data.code === 422) {
        dispatch({
          type: "savePriceError",
          payload: error.response.data,
        });
      }
      if (error.response.status === 401) {
        dispatch({
          type: "forbiddenRequest",
          payload: error.response.data.message,
        });
      }
    }
  };

  const updatePrice = async () => {
    try {
      dispatch({ type: "savePrice" });
      const { price } = state;
      const response = await backendApi.patch<ProductPriceResponse>(
        `product-prices/${price.id}`,
        price
      );
      dispatch({ type: "updatePriceSuccess", payload: response.data.data });
    } catch (error: any) {
      if (error.response.data.code === 422) {
        dispatch({
          type: "savePriceError",
          payload: error.response.data,
        });
      }
      if (error.response.status === 401) {
        dispatch({
          type: "forbiddenRequest",
          payload: error.response.data.message,
        });
      }
    }
  };
  const onSubmitPrice = async (e: FormEvent<HTMLFormElement>) => {
    e.preventDefault();
    const { price } = state;
    price.id === undefined ? createPrice() : updatePrice();
  };

  const showEditPrice = (id: number) => {
    const data = state.product.prices.find((price) => price.id === id);
    if (data) {
      dispatch({ type: "setPrice", payload: data });
      dispatch({ type: "showEditPrice" });
    }
  };

  const resetProduct = () => {
    dispatch({ type: "resetProduct" });
  };

  const setFrequencyProductAmount = (id: number) => {
    const data = state.product.paymentFrequencyAmounts.find(
      (item) => item.id === id
    );
    if (data) {
      dispatch({ type: "setPaymentFrequencyAmount", payload: data });
      dispatch({ type: "updatePaymentFrequency" });
    }
  };

  const resetFrequencyProductAmount = () => {
    dispatch({ type: "resetPaymentFrequencyAmount" });
  };

  const onChangetFrequencyProductAmount = (
    e: ChangeEvent<{ name?: string; value: unknown }>
  ) => {
    if (e.target.name) {
      dispatch({
        type: "setPaymentFrequencyAmount",
        payload: {
          ...state.paymentFrequencyAmount,
          [e.target.name]: e.target.value,
        },
      });
    }
  };

  const onSubmitFrequencyProductAmount = async () => {
    const { paymentFrequencyAmount, product } = state;
    if (!paymentFrequencyAmount.id) {
      dispatch({ type: "createPaymentFrequencyProductAmount" });
      try {
        const response =
          await backendApi.post<PaymentFrequencyProductAmountResponse>(
            `payment-frequency-amount/by-product/${product.id}`,
            paymentFrequencyAmount
          );
        dispatch({
          type: "createPaymentFrequencyProductAmountSuccess",
          payload: {
            data: response.data.data,
            productId: product.id!,
            message: response.data.message,
          },
        });
      } catch (error: any) {
        if (error.response.data.code === 422) {
          dispatch({
            type: "createPaymentFrequencyProductAmountError",
            payload: { errors: error.response.data.errors },
          });
        } else {
          dispatch({ type: "defaultErrorResponse" });
        }
      }
    } else {
      dispatch({ type: "updatePaymentFrequencyProductAmount" });
      try {
        const response =
          await backendApi.patch<PaymentFrequencyProductAmountResponse>(
            `payment-frequency-amount/${paymentFrequencyAmount.id}`,
            paymentFrequencyAmount
          );
        dispatch({
          type: "updatePaymentFrequencyProductAmountSuccess",
          payload: {
            data: response.data.data,
            productId: product.id!,
            message: response.data.message,
          },
        });
      } catch (error: any) {
        if (error.response.data.code === 422) {
          dispatch({
            type: "updatePaymentFrequencyProductAmountError",
            payload: { errors: error.response.data.errors },
          });
        } else {
          dispatch({ type: "defaultErrorResponse" });
        }
      }
    }
  };

  return {
    setProduct,
    onChangeProduct,
    onChangeAnnex,
    onSubmitNew,
    onSubmit,
    onChangePrice,
    onSubmitPrice,
    showEditPrice,
    resetFrequencyProductAmount,
    onChangetFrequencyProductAmount,
    resetProduct,
    onSubmitFrequencyProductAmount,
    setFrequencyProductAmount,
    showCreateProduct,
  };
};

import { Religion } from "../../interfaces/religions.interfaces";
import { CustomIconButton } from "../Common/ActionButtons/CustomIconButton";
import { CustomTableCell } from "../Common/PaginatedTableWrapper/CustomTableCell";
import { CustomTableRow } from "../Common/PaginatedTableWrapper/CustomTableRow";

interface Props {
  religion: Religion;
}
export const ReligionItem = ({ religion }: Props) => {
  return (
    <CustomTableRow>
      <CustomTableCell>{religion.name}</CustomTableCell>
      <CustomTableCell>{religion.status}</CustomTableCell>
      <CustomTableCell style={{ margin: 0, padding: 0 }}>
        <CustomIconButton iconType="edit" onClick={() => {}} />
      </CustomTableCell>
    </CustomTableRow>
  );
};

import {
  FormControl,
  Grid,
  InputLabel,
  MenuItem,
  Select,
  TextField,
} from "@material-ui/core";
import { useContext } from "react";
import { TransactionContext } from "../../contexts/TransactionsContext";

export const TransactionForm = () => {
  const { transaction } = useContext(TransactionContext);
  return (
    <Grid container spacing={2}>
      <Grid item xs={12}>
        <FormControl fullWidth>
          <TextField label="Nombre" value={transaction.name} fullWidth />
        </FormControl>
      </Grid>
      <Grid item xs={12}>
        <FormControl fullWidth>
          <InputLabel id="select-tipe">Tipo</InputLabel>
          <Select value={transaction.byEstab}>
            <MenuItem value="0">General</MenuItem>
            <MenuItem value="1">Por establecimiento</MenuItem>
          </Select>
        </FormControl>
      </Grid>
    </Grid>
  );
};

import { ChangeEvent, Dispatch, useEffect, useReducer } from "react";
import { v4 as uuid } from "uuid";
import { Phone } from "../interfaces/phone.interfaces";
import {
  phonesReducer,
  PhonesSatate,
  PHONES_INITIAL_STATE,
} from "../reducers/phones.reducer";
import { PhonesActionTypes } from "../types/phones.types";

export interface UsePhonesProps extends PhonesSatate {
  dispatch: Dispatch<PhonesActionTypes>;
  onChangePhone: (
    p: ChangeEvent<{ name?: string; value: unknown }>,
    index: number
  ) => void;
  deletePhone: (index: number) => void;
  updatePhones: () => void;
  openUsePreviousPhone: () => void;
  onChangeSelectedPreviousPhone: (
    e: ChangeEvent<{
      name?: string;
      value: unknown;
    }>
  ) => void;
}

interface Args {
  data: Phone[];
  reusablePhones?: Phone[];
}

export const usePhones = (): UsePhonesProps => {
  const initialState = PHONES_INITIAL_STATE;
  const [state, dispatch] = useReducer(phonesReducer, initialState);

  const onChangePhone = (
    e: ChangeEvent<{ name?: string; value: unknown }>,
    index: number
  ) => {
    const { name, value } = e.target;
    const { phones } = state;
    phones[index] = { ...phones[index], [name!]: value };
    dispatch({ type: "setPhones", payload: phones });
  };

  const deletePhone = (index: number) => {
    const { phones } = state;
    phones.splice(index, 1);
    dispatch({ type: "setPhones", payload: phones });
  };

  const updatePhones = () => {
    const { newPhone } = state;
    dispatch({ type: "setPhones", payload: [newPhone, ...state.phones] });
  };

  const openUsePreviousPhone = () =>
    dispatch({
      type: "setShowPreviousPhones",
      payload: !state.showPreviousPhones,
    });

  const onChangeSelectedPreviousPhone = (
    e: ChangeEvent<{
      name?: string | undefined;
      value: unknown;
    }>
  ) => {
    dispatch({
      type: "setSelectedPreviousPhone",
      payload: `${e.target.value}`,
    });
  };

  useEffect(() => {
    const { selectedPreviousPhone } = state;
    const previous = state.reusablePhones.find(
      (item) => `${item.id}` === selectedPreviousPhone
    );

    if (previous) {
      const previousPhone: Phone = {
        id: 0,
        typeId: previous.typeId,
        phoneNumber: previous.phoneNumber,
        active: false,
        editable: true,
      };
      dispatch({ type: "setPhones", payload: [previousPhone] });
    }
  }, [state.selectedPreviousPhone]);

  return {
    ...state,
    dispatch,
    updatePhones,
    onChangePhone,
    deletePhone,
    openUsePreviousPhone,
    onChangeSelectedPreviousPhone,
  };
};

import { makeStyles } from "@material-ui/core/styles";

export const useAlertStyles = makeStyles((theme) => ({
  root: {
    zIndex: 3000,
    position: "fixed",
    top: "10%",
    right: "5%",
    width: "30%",
    textAlign: "left",
    "& > * + *": {
      marginTop: theme.spacing(2),
    },
    [theme.breakpoints.down("md")]: {
      width: "50%",
    },
  },
  alert: {
    border: "1px solid green",
  },
  errorAlert: {
    border: "1px solid red",
  },
}));

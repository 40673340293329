import { useContext, useEffect } from "react";
import {
  Grid,
  makeStyles,
  TextField,
  Select,
  InputLabel,
  MenuItem,
  FormHelperText,
  FormControl,
} from "@material-ui/core";

import { SuburbsContext } from "../../contexts/SuburbsContext";

const useStyles = makeStyles(() => ({
  formControl: {
    width: "100%",
  },
  textError: {
    color: "red",
  },
}));

export const SuburbForm = () => {
  const classes = useStyles();
  const { errors, routes, suburb, onChangeSuburb, loadRoutes } =
    useContext(SuburbsContext);

  useEffect(() => {
    loadRoutes();
  }, []);

  return (
    <Grid container justifyContent="center" spacing={2}>
      <Grid item xs={12}>
        <TextField
          value={suburb.postalCodeId}
          label="Codigo postal"
          className={classes.formControl}
          inputProps={{ maxLength: 5 }}
          name="postalCodeId"
          onChange={onChangeSuburb}
          error={errors.postalCodeId.length > 0}
          helperText={errors.postalCodeId[0]}
        />
      </Grid>
      <Grid item xs={12}>
        <TextField
          value={suburb.name}
          label="Nombre"
          className={classes.formControl}
          name="name"
          onChange={onChangeSuburb}
          error={errors.name.length > 0}
          helperText={errors.name[0]}
        />
      </Grid>
      <Grid item xs={12}>
        <TextField
          value={suburb.city}
          label="Ciudad"
          className={classes.formControl}
          name="city"
          onChange={onChangeSuburb}
          error={errors.city.length > 0}
          helperText={errors.city[0]}
        />
      </Grid>
      <Grid item xs={12}>
        <FormControl
          className={classes.formControl}
          error={errors && errors.routeId.length > 0}
        >
          <InputLabel id="route-select-helper-label">Ruta</InputLabel>
          <Select
            value={suburb.routeId}
            name="routeId"
            labelId="route-select-helper-label"
            id="route-select-helper"
            onChange={onChangeSuburb}
          >
            <MenuItem value="">
              <em>Selecciona ruta</em>
            </MenuItem>
            {routes.map((route) => (
              <MenuItem
                value={route.id}
              >{`${route.id} - ${route.name} - ${route.collector.name}`}</MenuItem>
            ))}
          </Select>
          <FormHelperText>{errors.routeId[0]}</FormHelperText>
        </FormControl>
      </Grid>
    </Grid>
  );
};

import {
  LinksPagination,
  MetaPagination,
} from "../interfaces/pagination.interfaces";
import {
  Gender,
  SimpleRole,
  User,
  UserPaginated,
  UsersErrors,
  UsersStatus,
} from "../interfaces/users.interfaces";
import { UsersActionTypes } from "../types/users.types";

export interface UsersState {
  loading: boolean;
  success: boolean;
  error: boolean;
  list: UserPaginated[];
  filter: User[];
  user: User;
  searchValue: string;
  pagination: MetaPagination;
  links: LinksPagination;
  errors: UsersErrors;
  statusList: UsersStatus[];
  genders: Gender[];
  message: string;
  image: string | Blob | null;
  imageRoute: ArrayBuffer | string | null;
  roles: SimpleRole[];
}
export const USERS_INITIAL_STATE: UsersState = {
  loading: false,
  success: false,
  error: false,
  message: "",
  list: [],
  filter: [],
  searchValue: "",
  roles: [],
  user: {
    id: 0,
    folio: "",
    name: "",
    lastName: "",
    email: "",
    birthDate: "",
    gender: "",
    status: "",
    comment: "",
    roleId: "",
    phone: "",
    roles: [],
    stablishmentId: "",
    image: "",
    createdBy: "",
    avatarName: "",
    lastLogin: "",
    statusId: "",
    roleNames: [],
    fullName: "",
    phones: [],
    addresses: [],
  },
  pagination: {
    current_page: 1,
    from: 1,
    to: 10,
    last_page: 0,
    path: "",
    per_page: 10,
    total: 0,
  },
  links: {
    first: undefined,
    last: undefined,
    prev: undefined,
    next: undefined,
  },
  errors: {
    name: [],
    lastName: [],
    statusId: [],
    birthDate: [],
    stablishmentId: [],
    comment: [],
    gender: [],
    password: [],
    email: [],
    roleNames: [],
    phones: [],
    addresses: [],
  },
  statusList: [],
  genders: [],
  image: "",
  imageRoute: "",
};

export const usersReducer = (
  state = USERS_INITIAL_STATE,
  action: UsersActionTypes
): UsersState => {
  switch (action.type) {
    case "getUsers":
    case "getUsersStatus":
    case "getGenders":
    case "saveUser":
    case "getUserData":
    case "getRoles":
      return {
        ...state,
        loading: true,
        success: false,
        message: "",
        error: false,
      };
    case "getUsersSuccess":
      return {
        ...state,
        loading: false,
        list: action.payload.data,
        links: action.payload.links,
        pagination: action.payload.meta,
      };
    case "getUsersError":
    case "getUsersStatusError":
    case "getGendersError":
    case "getGendersError":
    case "getRolesError":
      return { ...state, loading: false };
    case "hideMessageDialog":
      return {
        ...state,
        success: false,
      };
    case "setFilter":
      return { ...state, filter: action.payload };
    case "setSearchvalue":
      return { ...state, searchValue: action.payload };
    case "setUser":
      return { ...state, user: { ...action.payload } };
    case "resetUser":
      return {
        ...state,
        user: USERS_INITIAL_STATE.user,
        errors: USERS_INITIAL_STATE.errors,
        error: false,
      };
    case "getUsersStatusSuccess":
      return { ...state, statusList: action.payload, loading: false };
    case "getGendersSuccess":
      return { ...state, genders: action.payload, loading: false };
    case "saveUserError":
      return {
        ...state,
        loading: false,
        errors: {
          ...USERS_INITIAL_STATE.errors,
          ...action.payload,
        },
        error: true,
      };
    case "saveUserSuccess":
      return {
        ...state,
        user: action.payload.data.user,
        list: [action.payload.data.listItem!, ...state.list],
        loading: false,
        success: true,
        message: action.payload.message,
        errors: USERS_INITIAL_STATE.errors,
      };
    case "updateUserSuccess":
      return {
        ...state,
        loading: false,
        user: action.payload.data,
        message: action.payload.message,
        success: true,
        errors: USERS_INITIAL_STATE.errors,
      };
    case "setImage":
      return {
        ...state,
        image: action.payload,
      };
    case "setImageRoute":
      return {
        ...state,
        imageRoute: action.payload,
      };
    case "badRequest":
      return {
        ...state,
        loading: false,
        error: true,
        message: action.payload || "Error desconocido",
      };
    case "getUserDataSucess":
      return {
        ...state,
        loading: false,
        user: action.payload,
      };
    case "deleteImage":
      return {
        ...state,
        imageRoute: "",
        image: "",
        user: { ...state.user, image: "" },
      };
    case "hideErrorAlert":
      return {
        ...state,
        error: false,
      };
    case "resetErrors":
      return {
        ...state,
        error: false,
        errors: USERS_INITIAL_STATE.errors,
      };
    case "getRolesSuccess":
      return {
        ...state,
        loading: false,
        roles: action.payload,
      };
    default:
      return state;
  }
};

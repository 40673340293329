import { useContext, useEffect } from "react";
import { TransactionContext } from "../../contexts/TransactionsContext";
import { Transaction } from "../../interfaces/transactions.interfaces";
import { CustomIconButton } from "../Common/ActionButtons/CustomIconButton";
import { CustomTableCell } from "../Common/PaginatedTableWrapper/CustomTableCell";
import { CustomTableRow } from "../Common/PaginatedTableWrapper/CustomTableRow";

interface Props {
  transaction: Transaction;
}
export const TransactionItem = ({ transaction }: Props) => {
  const { setTransaction } = useContext(TransactionContext);

  return (
    <CustomTableRow>
      <CustomTableCell hiddenMobile>{transaction.id || ""}</CustomTableCell>
      <CustomTableCell>{transaction.name}</CustomTableCell>
      <CustomTableCell>{transaction.label || ""}</CustomTableCell>
      <CustomTableCell style={{ margin: 0, padding: 0 }}>
        <CustomIconButton
          iconType="edit"
          onClick={() => setTransaction(transaction.id!)}
        />
      </CustomTableCell>
    </CustomTableRow>
  );
};

import { TableRow } from "@material-ui/core";
import { useTouchElement } from "../../../hooks/useTouchElement";
import { usPaginatedTableStyles } from "./styles";

interface Props {
  children: JSX.Element[];
  onClick?: () => void;
  onLongPressAction?: () => void;
}

export const CustomTableRow = ({
  children,
  onClick,
  onLongPressAction,
}: Props) => {
  const classes = usPaginatedTableStyles();

  const { onTouchEnd, onTouchStart } = useTouchElement(onLongPressAction);
  return (
    <>
      <TableRow
        hover
        className={classes.hiddenDesktop}
        onClick={onClick}
        onTouchEnd={onTouchEnd}
        onTouchStart={onTouchStart}
      >
        {children}
      </TableRow>
      <TableRow hover className={classes.hiddenMobile} onDoubleClick={onClick}>
        {children}
      </TableRow>
    </>
  );
};

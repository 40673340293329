import {
  LinksPagination,
  MetaPagination,
} from "../interfaces/pagination.interfaces";
import { PhoneType } from "../interfaces/phoneTypes.interfaces";
import { PhoneTypesActionTypes } from "../types/phoneTypes.types";

export interface PhoneTypesState {
  list: PhoneType[];
  loading: boolean;
  links: LinksPagination;
  pagination: MetaPagination;
}

export const PHONE_TYPES_INITIAL_STATE: PhoneTypesState = {
  list: [],
  loading: false,
  pagination: {
    current_page: 1,
    from: 1,
    to: 10,
    last_page: 0,
    path: "",
    per_page: 10,
    total: 0,
  },
  links: {
    first: undefined,
    last: undefined,
    prev: undefined,
    next: undefined,
  },
};

export const phoneTypesReducer = (
  state = PHONE_TYPES_INITIAL_STATE,
  action: PhoneTypesActionTypes
) => {
  switch (action.type) {
    case "getPhoneTypes":
      return { ...state, loading: true };
    case "getPhoneTypesError":
      return { ...state, loading: false };
    case "getPhoneTypesSucccess":
      return {
        ...state,
        list: action.payload.data,
        links: action.payload.links,
        pagination: action.payload.meta,
      };
    default:
      return state;
  }
};

import { makeStyles } from "@material-ui/core";

export const useMessageDialogStyles = makeStyles((theme) => ({
  message: {
    fontSize: 18,
  },
  icon: {
    fontSize: 60,
  },
}));

import { makeStyles } from "@material-ui/core";

export const useFloatinActionStyles = makeStyles((theme) => ({
  actionButtonContainer: {
    bottom: "4%",
    right: "2%",
    marginTop: theme.spacing(1),
    [theme.breakpoints.down("sm")]: {
      right: "1%",
      bottom: "2%",
    },
  },
  reloadButtonContainer: {
    bottom: "18%",
    right: "2%",
    marginTop: theme.spacing(1),
  },
  optionalContainer: {
    bottom: "34%",
    right: "2%",
  },
  fab: {
    backgroundColor: theme.palette.primary.main,
    marginTop: 10,
  },
  actionIcon: {
    color: "white",
  },
  floatingContainer: {
    position: "fixed",
    bottom: "4%",
    right: "1%",
    width: "5%",
    minWidth: "100px",
    display: "flex",
  },
}));

import { makeStyles, TableCell } from "@material-ui/core";
import { CSSProperties } from "@material-ui/styles";

interface Props {
  children: JSX.Element | JSX.Element[] | string;
  hiddenMobile?: boolean;
  style?: CSSProperties;
}

const styles = makeStyles((theme) => ({
  hiddenDesktop: {
    [theme.breakpoints.up("lg")]: {
      display: "none",
    },
  },
  hiddenMobile: {
    [theme.breakpoints.down("md")]: {
      display: "none",
    },
  },
}));

export const CustomTableCell = ({ children, hiddenMobile, style }: Props) => {
  const classes = styles();
  return (
    <TableCell
      className={hiddenMobile ? classes.hiddenMobile : ""}
      style={style}
    >
      {children}
    </TableCell>
  );
};

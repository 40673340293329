import React, { useContext, useEffect } from "react";
import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  Typography,
  DialogTitle,
} from "@material-ui/core";
import { DiscountForm } from "../DiscountForm/DicountForm";
import { DiscountContext } from "../../../contexts/DiscountsContext";
import { useGeneralStyles } from "../../../theme/generalStyles";

export const DiscountModal = () => {
  const {
    showDiscountModal,
    dispatch,
    onSubmit,
    openDiscountModal,
    loadStatuses,
    discount,
  } = useContext(DiscountContext);

  const styles = useGeneralStyles();

  useEffect(() => {
    loadStatuses();
  }, []);

  return (
    <Dialog
      open={showDiscountModal}
      onClose={() => dispatch({ type: "setShowDiscountModal", payload: false })}
      maxWidth="sm"
      fullWidth
    >
      <DialogTitle id="alert-dialog-title">
        <Typography>
          {discount.id ? "Editar Descuento" : "Nuevo Descuento"}
        </Typography>
      </DialogTitle>
      <DialogContent>
        <DiscountForm />
      </DialogContent>
      <DialogActions>
        <Button
          component="span"
          className={styles.actionButton}
          onClick={onSubmit}
        >
          Guardar
        </Button>
        <Button
          component="span"
          className={styles.secondaryButton}
          onClick={openDiscountModal}
        >
          Cancelar
        </Button>
      </DialogActions>
    </Dialog>
  );
};

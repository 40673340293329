import { makeStyles } from "@material-ui/core/styles";

export const usePhonesStyles = makeStyles((theme) => ({
  phoneContainer: {
    marginTop: 10,
  },
  formControl: {
    width: "100%",
  },
  formElement: {
    textAlign: "start",
  },
  enabledTextColor: {
    color: theme.palette.success.dark,
  },
  disabledTextColor: {
    color: theme.palette.grey[700],
  },
  reusablePhonesSelect: {
    textAlign: "left",
  },
  itemContainer: {
    marginRight: 10,
  },
}));

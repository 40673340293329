import { createContext, Dispatch, useReducer } from "react";
import { ErrorsAlertContainer } from "../components/Common/ErrorContainer/ErrorContainer";
import { MessageDialog } from "../components/Common/MessageDialog/MessageDialog";
import { LoadingSpinner } from "../components/LoadingSpinner/LoadingSpinner";
import { useRoles, UseRolesProps } from "../hooks/roles/useRoles";
import {
  useSimpleRole,
  UseSimpleRoleProps,
} from "../hooks/roles/useSimpleRole";
import { ProviderProps } from "../interfaces/common.interfaces";
import {
  rolesReducer,
  RolesState,
  ROLES_INITIAL_STATE,
} from "../reducers/roles.reducer";
import { RolesActionTypes } from "../types/roles.types";

interface ContextProps extends RolesState, UseRolesProps, UseSimpleRoleProps {
  dispatch: Dispatch<RolesActionTypes>;
}

export const RolesContext = createContext({} as ContextProps);

export const RolesProvider = ({ children }: ProviderProps) => {
  const initialState = ROLES_INITIAL_STATE;

  const [state, dispatch] = useReducer(rolesReducer, initialState);
  const rolesContext = useRoles(state, dispatch);
  const simpleRoleContext = useSimpleRole(state, dispatch);
  return (
    <RolesContext.Provider
      value={{ ...state, dispatch, ...rolesContext, ...simpleRoleContext }}
    >
      {children}
      <MessageDialog
        message={state.message}
        open={state.success}
        handleClose={() => dispatch({ type: "hideMessageDialog" })}
      />
      <ErrorsAlertContainer
        errors={state.errors}
        error={state.error}
        hideErrorsAlert={() => dispatch({ type: "hideErrorAlert" })}
        optionalMessage={state.message}
      />
      <LoadingSpinner loading={state.loading} />
    </RolesContext.Provider>
  );
};

import { createContext } from "react";
import {
  usePagination,
  UsePaginationProps,
} from "../hooks/general/usePagination";

interface Props {
  children: JSX.Element;
}

interface ContexProps extends UsePaginationProps {
  getKeyValue: <T extends Object>(data: T, key: keyof T) => any;
  mxCurrencyFormat: (num: number) => string;
  can: (permission: string) => boolean;
}

export const GeneralContext = createContext({} as ContexProps);

export const GeneralProvider = ({ children }: Props) => {
  const paginationContext = usePagination();

  const getKeyValue = function <T extends object, U extends keyof T>(
    obj: T,
    key: U
  ) {
    return obj[key];
  };

  const can = (permission: string) => {
    return true;
  };

  const mxCurrencyFormat = (value: number) =>
    new Intl.NumberFormat("es-MX", {
      style: "currency",
      currency: "MXN",
    }).format(value);

  return (
    <GeneralContext.Provider
      value={{ ...paginationContext, getKeyValue, mxCurrencyFormat, can }}
    >
      {children}
    </GeneralContext.Provider>
  );
};

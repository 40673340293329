import { useContext } from "react";
import { ProductsContext } from "../../contexts/ProductsContext";
import { ProductPaginated } from "../../interfaces/products.interfaces";
import { CustomIconButton } from "../Common/ActionButtons/CustomIconButton";
import { DateLabel } from "../Common/DateLabel";
import { CustomTableCell } from "../Common/PaginatedTableWrapper/CustomTableCell";
import { CustomTableRow } from "../Common/PaginatedTableWrapper/CustomTableRow";
import { ProductStatusIcon } from "./ProductStatusIcon";

interface Props {
  product: ProductPaginated;
}
export const ProductViewItem = ({ product }: Props) => {
  const { setProduct } = useContext(ProductsContext);
  return (
    <CustomTableRow>
      <CustomTableCell hiddenMobile>{product.id}</CustomTableCell>
      <CustomTableCell>{product.name}</CustomTableCell>
      <CustomTableCell hiddenMobile>{product.type}</CustomTableCell>
      <CustomTableCell hiddenMobile>
        <DateLabel date={product.createdAt} />
      </CustomTableCell>
      <CustomTableCell hiddenMobile>{product.creator || ""}</CustomTableCell>
      <CustomTableCell hiddenMobile>
        <ProductStatusIcon
          status={product.status}
          label={product.statusLabel}
        />
      </CustomTableCell>
      <CustomTableCell style={{ margin: 0, padding: 0 }}>
        <CustomIconButton
          iconType="edit"
          onClick={() => {
            setProduct(product.id);
          }}
        />
      </CustomTableCell>
    </CustomTableRow>
  );
};

import { Grid, Switch, Typography, makeStyles } from "@material-ui/core";
import { ChangeEvent, useContext } from "react";
import { ProductsContext } from "../../contexts/ProductsContext";
import { useProductsStyles } from "./styles";

export const ProductAnnexeds = () => {
  const classes = useProductsStyles();

  const { product, onChangeAnnex } = useContext(ProductsContext);

  return (
    <>
      <Grid container className={classes.annexedsTitleContainer}>
        {product.annexeds.length > 0 && (
          <Typography className={classes.annexedsTitle}>Anexos</Typography>
        )}
      </Grid>
      <Grid container xs={12} className={classes.annexedsContainer}>
        {product.annexeds &&
          product.annexeds.map((item) => (
            <Grid
              container
              justifyContent="space-between"
              direction="row"
              className={classes.annexContainer}
            >
              <div style={{ display: "flex", marginTop: 10 }}>
                <Typography className={classes.annexNumber}>
                  {item.number}
                </Typography>
                <Typography
                  style={{
                    textAlign: "justify",
                    marginLeft: 10,
                    marginRight: 10,
                  }}
                >
                  {item.comment}
                </Typography>
              </div>
              <Switch
                className={classes.annexControl}
                checked={item.applicable}
                value={item.id}
                onChange={(e: ChangeEvent<HTMLInputElement>) => {
                  onChangeAnnex(e, item.id);
                }}
                name={`checked-${item.id}`}
              />
            </Grid>
          ))}
      </Grid>
    </>
  );
};

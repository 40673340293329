import {
  Grid,
  FormControl,
  TextField,
  Typography,
  InputLabel,
  Select,
  MenuItem,
} from "@material-ui/core";
import { useContext, useEffect } from "react";
import { EstablishmentsContext } from "../../contexts/EstablishmentsContext";
import { TransactionContext } from "../../contexts/TransactionsContext";

export const FolioForm = () => {
  const { folio, onChangeFolio, transaction } = useContext(TransactionContext);
  const { list: establisments, getEstablishments } = useContext(
    EstablishmentsContext
  );

  useEffect(() => {
    getEstablishments();
  }, []);

  return (
    <Grid container spacing={2}>
      <Grid item xs={12}>
        <Typography>{transaction.name}</Typography>
      </Grid>
      <Grid item xs={12}>
        <Typography>{folio.id ? "Editar folio" : "Nuevo folio"}</Typography>
      </Grid>
      <Grid item xs={12}>
        <FormControl fullWidth>
          <InputLabel id="select-tipe">Establecimiento</InputLabel>
          <Select
            value={folio.establishmentId}
            name="establishmentId"
            onChange={onChangeFolio}
          >
            <MenuItem value="">Selecciona</MenuItem>
            {establisments.map((estab) => (
              <MenuItem value={estab.code}>{estab.name}</MenuItem>
            ))}
          </Select>
        </FormControl>
      </Grid>
      <Grid item xs={12}>
        <FormControl fullWidth>
          <TextField
            value={folio.folio}
            label="Folio"
            name="folio"
            onChange={onChangeFolio}
          />
        </FormControl>
      </Grid>
      <Grid item xs={12}>
        <FormControl fullWidth>
          <TextField
            value={folio.fill}
            label="Relleno"
            name="fill"
            onChange={onChangeFolio}
          />
        </FormControl>
      </Grid>
    </Grid>
  );
};

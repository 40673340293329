import { PriceList } from "../interfaces/priceLists.interfaces";
import { PriceListActionTypes } from "../types/priceLists.types";
export interface PriceListsState {
  loading: boolean;
  list: PriceList[];
}
export const PRICE_LIST_INITIAL_STATE: PriceListsState = {
  loading: false,
  list: [],
};

export const priceListsReducer = (
  state = PRICE_LIST_INITIAL_STATE,
  action: PriceListActionTypes
): PriceListsState => {
  switch (action.type) {
    case "getPriceLists":
      return {
        ...state,
        loading: false,
      };
    case "getPriceListsSuccess":
      return {
        ...state,
        list: action.payload,
        loading: false,
      };
    default:
      return state;
  }
};

import { ChangeEvent, Dispatch } from "react";
import { useHistory } from "react-router";
import { backendApi } from "../../api/backendApi";
import {
  RolesResponse,
  UsersGendersResponse,
  UsersPaginatedResponse,
  UsersStatusResponse,
} from "../../interfaces/users.interfaces";
import { UsersState } from "../../reducers/users.reducer";
import { UsersActionTypes } from "../../types/users.types";

export interface UseUsersProps {
  loadUsers: () => void;
  searchFunction: (e: ChangeEvent<HTMLInputElement>) => void;
  resetSearchValue: () => void;
  redirectToNewUser: () => void;
  getUsers: (path: string, params?: Object) => void;
  loadUsertStatus: () => void;
  loadGenders: () => void;
  loadRoles: () => void;
}

export const useUsers = (
  state: UsersState,
  dispatch: Dispatch<UsersActionTypes>
): UseUsersProps => {
  const history = useHistory();

  const loadUsers = () => {
    if (state.list.length === 0) {
      getUsers("users");
    }
  };

  const searchFunction = (e: ChangeEvent<HTMLInputElement>) =>
    dispatch({ type: "setSearchvalue", payload: e.target.value });

  const resetSearchValue = () =>
    dispatch({ type: "setSearchvalue", payload: "" });

  const redirectToNewUser = () => history.push("/users/new-user");

  const getUsers = async (path: string, params?: Object) => {
    try {
      dispatch({ type: "getUsers" });
      const response = await backendApi.get<UsersPaginatedResponse>(path, {
        params,
      });
      dispatch({ type: "getUsersSuccess", payload: response.data });
    } catch (error) {
      dispatch({ type: "getUsersError" });
    }
  };

  const loadUsertStatus = async () => {
    if (state.statusList.length === 0) {
      try {
        dispatch({ type: "getUsersStatus" });
        const response = await backendApi.get<UsersStatusResponse>(
          "users/status"
        );
        dispatch({
          type: "getUsersStatusSuccess",
          payload: response.data.data,
        });
      } catch (error) {
        dispatch({ type: "getUsersStatusError" });
      }
    }
  };

  const loadGenders = async () => {
    if (state.genders.length === 0) {
      dispatch({ type: "getGenders" });
      try {
        const response = await backendApi.get<UsersGendersResponse>(
          "users/genders"
        );
        dispatch({ type: "getGendersSuccess", payload: response.data.data });
      } catch (error) {}
    }
  };

  const loadRoles = async () => {
    if (state.roles.length === 0) {
      dispatch({ type: "getRoles" });
      try {
        const response = await backendApi.get<RolesResponse>("users/roles");
        dispatch({ type: "getRolesSuccess", payload: response.data.data });
      } catch (error) {
        dispatch({ type: "getRolesError" });
      }
    }
  };

  return {
    loadUsers,
    searchFunction,
    resetSearchValue,
    redirectToNewUser,
    getUsers,
    loadUsertStatus,
    loadGenders,
    loadRoles,
  };
};

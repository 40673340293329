import {
  FormControl,
  MenuItem,
  InputLabel,
  Select,
  TextField,
  FormControlLabel,
  IconButton,
  Button,
  FormHelperText,
  Switch,
  Grid,
} from "@material-ui/core";
import AddCircleOutlineIcon from "@material-ui/icons/AddCircleOutline";
import DeleteIcon from "@material-ui/icons/Delete";
import PhotoCameraIcon from "@material-ui/icons/PhotoCamera";
import { v4 as uuid } from "uuid";
import CancelIcon from "@material-ui/icons/Cancel";
import { remoteImages } from "../../api/backendApi";
import { UseAddressesProps } from "../../hooks/useAddresses";
import { useAddressesStyles } from "./styles";
import { AddressesTypesContext } from "../../contexts/AddressesTypesContext";
import { useContext, useEffect } from "react";

interface Props {
  handler: UseAddressesProps;
  errorContent?: string;
}

export const Addresses = ({ handler, errorContent = "" }: Props) => {
  const classes = useAddressesStyles();

  const { list: types, getAddressesTypes } = useContext(AddressesTypesContext);

  const {
    errors,
    addresses,
    deleteAddress,
    updateAddresses,
    onChangeAddress,
    handleImageUpload,
    imagesRoutes,
    deleteImage,
    openAddressModalImage,
    onInput,
    subs,
    updateSub,
    deleteSubs,
    hasError,
    loadAddressesSubs,
  } = handler;

  useEffect(() => {
    getAddressesTypes();
    loadAddressesSubs();
  }, []);

  return (
    <Grid container spacing={2}>
      <Grid item xs={12}>
        <FormHelperText>{errorContent}</FormHelperText>
      </Grid>
      <Grid item container xs={12}>
        <Button
          component="span"
          className={classes.enabledTextColor}
          onClick={(e: any) => {
            updateAddresses(e);
            updateSub(e);
          }}
        >
          <AddCircleOutlineIcon color="inherit" />
          <span className="mx-2">NUEVA DIRECCIÓN</span>
        </Button>
      </Grid>
      {addresses.map((item, index) => (
        <Grid item xs={12} container key={item.id}>
          <Grid item xs={11}>
            <Grid container spacing={1}>
              <Grid item xs={11} lg={2}>
                <FormControl className={classes.formControl}>
                  <InputLabel id="demo-simple-select-label">Tipo</InputLabel>
                  <Select
                    labelId="demo-simple-select-label"
                    data-idx={item}
                    name="type"
                    value={item.type}
                    onChange={(e: any) => {
                      onChangeAddress(e, index);
                    }}
                  >
                    {types.map((type) => (
                      <MenuItem value={type.id} key={uuid()}>
                        {type.name}
                      </MenuItem>
                    ))}
                  </Select>
                </FormControl>
              </Grid>
              <Grid item xs={11} lg={5}>
                <TextField
                  className={classes.formControl}
                  label="Calle"
                  id="calle"
                  name="street"
                  error={hasError("street")}
                  helperText={errors.street}
                  value={item.street}
                  onChange={(e: any) => {
                    onChangeAddress(e, index);
                  }}
                />
              </Grid>
              <Grid item xs={5} sm={3} lg={2}>
                <TextField
                  className={classes.formControl}
                  disabled={item.skipAddressNumber}
                  label="Número"
                  id="number"
                  name="number"
                  error={hasError("number")}
                  helperText={errors.number}
                  value={item.number}
                  onChange={(e: any) => {
                    onChangeAddress(e, index);
                  }}
                />
              </Grid>
              <Grid item xs={5} sm={3} lg={2}>
                <TextField
                  className={classes.formControl}
                  disabled={item.skipAddressNumber}
                  label="Interior"
                  id="internalNumber"
                  name="internalNumber"
                  error={hasError("internalNumber")}
                  helperText={errors.internalNumber}
                  value={item.internalNumber}
                  onChange={(e: any) => {
                    onChangeAddress(e, index);
                  }}
                />
              </Grid>
              <Grid item xs={5} sm={3} lg={2}>
                <FormControlLabel
                  className={classes.formControl}
                  style={{ alignContent: "flex-end" }}
                  control={
                    <Switch
                      checked={item.skipAddressNumber}
                      onChange={(e: any) => {
                        onChangeAddress(e, index);
                      }}
                      name="skip_address_number"
                      color="primary"
                    />
                  }
                  label="S/N"
                />
              </Grid>
              <Grid item xs={6} sm={3} lg={2}>
                <TextField
                  label="C.P."
                  id="postalCode"
                  name="postalCode"
                  type="text"
                  error={hasError("postalCode")}
                  helperText={errors.postalCode}
                  value={item.postalCode}
                  onChange={(e: any) => {
                    onChangeAddress(e, index);
                  }}
                  onInput={(e: any) => {
                    onInput(e, index);
                  }}
                  inputProps={{ maxLength: 5 }}
                />
              </Grid>
              <Grid item xs={11} lg={4}>
                <FormControl className={classes.formControl}>
                  <InputLabel id="label-colonia">Colonia</InputLabel>
                  <Select
                    labelId="label-colonia"
                    name="suburbId"
                    value={item.suburbId}
                    onChange={(e: any) => {
                      onChangeAddress(e, index);
                    }}
                  >
                    {subs[index] &&
                      subs[index].map((sub) => (
                        <MenuItem value={sub.id} key={sub.id}>
                          {sub.name}
                        </MenuItem>
                      ))}
                  </Select>
                </FormControl>
              </Grid>
              <Grid item xs={11} lg={6}>
                <TextField
                  className={classes.formControl}
                  label="Referencias"
                  id="comment"
                  name="comment"
                  error={hasError("comment")}
                  helperText={errors.comment}
                  value={item.comment}
                  onChange={(e: any) => {
                    onChangeAddress(e, index);
                  }}
                />
              </Grid>
            </Grid>
            <Grid container spacing={2}>
              {item.imagePath ? (
                <Grid
                  item
                  xs={11}
                  container
                  direction="row"
                  className={classes.imageContainer}
                  alignItems="center"
                >
                  <img
                    className={classes.images}
                    src={
                      item.imagePath
                        ? `${remoteImages}/${item.imagePath}`
                        : imagesRoutes[index]
                    }
                    alt=""
                    onClick={(e) => {
                      openAddressModalImage(e, item.imagePath!);
                    }}
                  />
                  <img
                    className={classes.images}
                    src={!item.image && imagesRoutes ? imagesRoutes[index] : ""}
                    alt=""
                    onClick={(e) => {
                      openAddressModalImage(e, imagesRoutes[index], true);
                    }}
                  />
                  <IconButton
                    component="span"
                    onClick={(e: any) => {
                      deleteImage(e, index);
                    }}
                  >
                    <CancelIcon />
                  </IconButton>
                </Grid>
              ) : (
                <Grid item xs={1}>
                  <img
                    className={classes.images}
                    src={item.image ? `${remoteImages}/${item.image}` : ""}
                    alt=""
                    onClick={(e) => {
                      openAddressModalImage(e, item.image!);
                    }}
                  />
                  <img
                    className={classes.images}
                    src={!item.image && imagesRoutes ? imagesRoutes[index] : ""}
                    alt=""
                    onClick={(e) => {
                      openAddressModalImage(e, imagesRoutes[index], true);
                    }}
                  />
                  <input
                    accept="image/*"
                    className="d-none"
                    id={`contained-button-file-${index}`}
                    name="contained-button-file"
                    type="file"
                    onChange={(e: any) => {
                      handleImageUpload(e, index);
                    }}
                  />
                  <label htmlFor={`contained-button-file-${index}`}>
                    <IconButton component="span">
                      <PhotoCameraIcon />
                    </IconButton>
                  </label>
                </Grid>
              )}
            </Grid>
          </Grid>
          <Grid item xs={1} container alignContent="center" justify="flex-end">
            <IconButton
              component="span"
              onClick={() => {
                deleteAddress(index);
                deleteSubs(index);
              }}
            >
              <DeleteIcon />
            </IconButton>
          </Grid>
        </Grid>
      ))}
      <Grid item container xs={12}>
        <FormHelperText className="text-error-form">
          {/* {errors.domicilios} */}
        </FormHelperText>
      </Grid>
    </Grid>
  );
};

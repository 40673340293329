import { Dispatch, useReducer } from "react";
import {
  paginationRedcuer,
  PaginationState,
} from "../../reducers/pagination.reducer";
import { PaginationActionTypes } from "../../types/pagination.types";

export interface UsePaginationProps extends PaginationState {
  dispatch: Dispatch<PaginationActionTypes>;
  increasePage: () => void;
  decreasePage: () => void;
  updateTotalRows: (items: number) => void;
}

const initalState: PaginationState = {
  to: 0,
  from: 0,
  totalRows: 0,
  currentPage: 1,
  perPage: 10,
  lastPage: 0,
};

export const usePagination = (): UsePaginationProps => {
  const [state, dispatch] = useReducer(paginationRedcuer, initalState);

  const increasePage = () => dispatch({ type: "increase" });
  const decreasePage = () => dispatch({ type: "decrease" });
  const updateTotalRows = (items: number) => {
    dispatch({ type: "updateTotal", payload: items });
  };

  return {
    ...state,
    dispatch,
    increasePage,
    decreasePage,
    updateTotalRows,
  };
};

import { Dialog, DialogActions, DialogContent } from "@material-ui/core";
import { useContext } from "react";
import { TransactionContext } from "../../contexts/TransactionsContext";
import { CustomActionButton } from "../Common/ActionButtons/CustomActionButton";
import { SecondaryActionButton } from "../Common/ActionButtons/SecondaryActionButton";
import { FolioForm } from "./FolioForm";

export const FolioModal = () => {
  const { showFoliosModal, cancelSaveFolio, onSubmit } =
    useContext(TransactionContext);

  return (
    <Dialog open={showFoliosModal} onClose={cancelSaveFolio}>
      <DialogContent>
        <FolioForm />
      </DialogContent>
      <DialogActions>
        <CustomActionButton onClick={onSubmit} />
        <SecondaryActionButton onClick={cancelSaveFolio} />
      </DialogActions>
    </Dialog>
  );
};

import { useContext } from "react";
import { SuburbsContext } from "../../contexts/SuburbsContext";
import { Suburb } from "../../interfaces/suburb.interfaces";
import { CustomIconButton } from "../Common/ActionButtons/CustomIconButton";
import { CustomTableCell } from "../Common/PaginatedTableWrapper/CustomTableCell";
import { CustomTableRow } from "../Common/PaginatedTableWrapper/CustomTableRow";
import { useSuburbStyles } from "./styles";

interface Props {
  suburb: Suburb;
}

export const SuburbItem = ({ suburb }: Props) => {
  const classes = useSuburbStyles();
  const { setSuburb, showDeleteSuburb } = useContext(SuburbsContext);
  return (
    <CustomTableRow onLongPressAction={() => showDeleteSuburb(suburb.id!)}>
      <CustomTableCell>{suburb.name}</CustomTableCell>
      <CustomTableCell>{suburb.postalCode?.id || ""}</CustomTableCell>
      <CustomTableCell hiddenMobile>
        {suburb?.postalCode?.state.name || ""}
      </CustomTableCell>
      <CustomTableCell style={{ margin: 0, padding: 0 }}>
        <CustomIconButton
          iconType="edit"
          onClick={() => setSuburb(suburb.id!)}
        />
        <CustomIconButton
          iconType="delete"
          onClick={() => setSuburb(suburb.id!)}
          classes={classes.hiddenMovile}
        />
      </CustomTableCell>
    </CustomTableRow>
  );
};

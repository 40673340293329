import { useContext, useEffect } from "react";
import { Grid, Typography } from "@material-ui/core";
import { ContentWrapper } from "../../../components/Common/ContentWrapper/ContentWrapper";
import { UsersContext } from "../../../contexts/UsersContext";
import { CustomAppBar } from "../../../components/Common/CustomAppBar/CustomAppBar";
import { UserForm } from "../../../components/User/UserForm/UserForm";
import { useUserViewStyles } from "./style";

export const SimpleUserView = () => {
  const classes = useUserViewStyles();
  const { user, dispatch } = useContext(UsersContext);

  useEffect(() => {
    dispatch({ type: "resetErrors" });
  }, []);
  return (
    <ContentWrapper
      paddingTop
      defaultPaddingTop
      appBarContent={
        <CustomAppBar visibleBAckIcon path="/users">
          <Typography className={classes.userName}>{user.fullName}</Typography>
        </CustomAppBar>
      }
    >
      <Grid container justify="center">
        <Grid item xs={11} lg={8}>
          <UserForm />
        </Grid>
      </Grid>
    </ContentWrapper>
  );
};

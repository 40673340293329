import { Dispatch } from "react";
import { backendApi } from "../../api/backendApi";
import { PriceListsResponse } from "../../interfaces/priceLists.interfaces";
import { PriceListsState } from "../../reducers/priceListsReducer";
import { PriceListActionTypes } from "../../types/priceLists.types";

export interface UsePriceListsProps {
  getPriceLists: () => void;
}

export const usePriceLists = (
  state: PriceListsState,
  dispatch: Dispatch<PriceListActionTypes>
): UsePriceListsProps => {
  const getPriceLists = async () => {
    if (state.list.length === 0) {
      try {
        dispatch({ type: "getPriceLists" });
        const response = await backendApi.get<PriceListsResponse>(
          "price-lists"
        );
        dispatch({ type: "getPriceListsSuccess", payload: response.data.data });
      } catch (error) {
        dispatch({ type: "getPriceListsError" });
      }
    }
  };

  return {
    getPriceLists,
  };
};

import Brightness1Icon from "@material-ui/icons/Brightness1";
import { Grid, Typography } from "@material-ui/core";
import { UsersStatusType } from "../../../interfaces/users.interfaces";
import { useUsersStatusIcon } from "./styles";

type IconType =
  | "success"
  | "warning"
  | "primary"
  | "icon"
  | "statusLabel"
  | "largeStatusLabel"
  | "error";

interface Props {
  type: UsersStatusType;
  label: string;
  showIcon: boolean;
  uppercase?: boolean;
}

export const UserStatusIcon = ({
  type,
  label = "",
  showIcon,
  uppercase = false,
}: Props) => {
  const classes = useUsersStatusIcon();
  let color: IconType = "primary";
  switch (type) {
    case "activo":
    default:
      color = "primary";
  }

  return (
    <Grid
      item
      xs={12}
      container
      justifyContent="flex-start"
      alignItems="center"
    >
      {showIcon ? (
        <>
          <Brightness1Icon
            className={`${classes.icon} ${classes[color]}`}
            fontSize="small"
          />
          <Typography className={`${classes[color]} ${classes.statusLabel}`}>
            {uppercase ? label.toUpperCase() : label}
          </Typography>
        </>
      ) : (
        <Typography className={`${classes[color]} ${classes.largeStatusLabel}`}>
          {uppercase ? label.toUpperCase() : label}
        </Typography>
      )}
    </Grid>
  );
};

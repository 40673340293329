import { createContext, useReducer } from "react";
import {
  usePhoneTypes,
  UsePhoneTypesProps,
} from "../hooks/phonesTypes/usePhoneTypes";
import { ProviderProps } from "../interfaces/common.interfaces";
import {
  phoneTypesReducer,
  PhoneTypesState,
  PHONE_TYPES_INITIAL_STATE,
} from "../reducers/phoneTypes.reducer";

interface ContextProps extends PhoneTypesState, UsePhoneTypesProps {}

export const PhoneTypesContext = createContext({} as ContextProps);

export const PhoneTypesProvider = ({ children }: ProviderProps) => {
  const initialState = PHONE_TYPES_INITIAL_STATE;

  const [state, dispatch] = useReducer(phoneTypesReducer, initialState);

  const phoneTypesContext = usePhoneTypes(state, dispatch);

  return (
    <PhoneTypesContext.Provider value={{ ...state, ...phoneTypesContext }}>
      {children}
    </PhoneTypesContext.Provider>
  );
};

import { useContext, useEffect } from "react";
import {
  TextField,
  InputAdornment,
  IconButton,
  Switch,
  Input,
  InputLabel,
  Select,
  MenuItem,
  FormHelperText,
  Grid,
  Button,
  FormControl,
} from "@material-ui/core";
import Visibility from "@material-ui/icons/Visibility";
import VisibilityOff from "@material-ui/icons/VisibilityOff";
import EnhancedEncryptionIcon from "@material-ui/icons/EnhancedEncryption";
import { useUserFormStyles } from "./styles";
import { UsersContext } from "../../../contexts/UsersContext";
import { useGeneralStyles } from "../../../theme/generalStyles";
import { Phones } from "../../Phones/Phones";
import { Addresses } from "../../Addresses/Addresses";
import { JobsAutoComplete } from "./JobsAutoComplete";
import { UserAvatarForm } from "./UserAvatarForm";
import { EstablishmentsContext } from "../../../contexts/EstablishmentsContext";

export const UserForm = () => {
  const classes = useUserFormStyles();
  const generalClasses = useGeneralStyles();

  const { getEstablishments } = useContext(EstablishmentsContext);

  const {
    saveUser,
    errors,
    genders,
    user,
    statusList,
    addressesHandler,
    phonesHandler,
    setRandomPassword,
    updateState,
    hasError,
    showPassword,
    onChangeShowChangePassword,
    changePassword,
    handleMouseDownPassword,
    handleClickShowPassword,
    loadUsertStatus,
    loadGenders,
    loadRoles,
  } = useContext(UsersContext);

  const { list: offices } = useContext(EstablishmentsContext);

  useEffect(() => {
    loadUsertStatus();
    loadRoles();
    getEstablishments();
    loadGenders();
  }, []);

  return (
    <Grid container spacing={3} direction="column" item xs={12}>
      <UserAvatarForm />
      <Grid item container xs={12}>
        <TextField
          error={hasError("name")}
          label="Nombre"
          className={classes.formControl}
          helperText={errors.name}
          name="name"
          value={user.name}
          onChange={updateState}
        />
      </Grid>
      <Grid item container xs={12}>
        <TextField
          label="Apellidos"
          className={classes.formControl}
          error={hasError("lastName")}
          helperText={errors.lastName}
          name="lastName"
          value={user.lastName}
          onChange={updateState}
        />
      </Grid>
      <Grid item container xs={12}>
        <FormControl fullWidth error={hasError("statusId")}>
          <InputLabel id="demo-simple-select-label">Estatus</InputLabel>
          <Select
            labelId="demo-simple-select-label"
            className={classes.formControl}
            name="statusId"
            value={user.statusId}
            onChange={updateState}
          >
            {statusList.map((status) => (
              <MenuItem value={status.value} key={status.value}>
                {status.label}
              </MenuItem>
            ))}
          </Select>
          <FormHelperText>{errors.statusId[0]}</FormHelperText>
        </FormControl>
      </Grid>
      <Grid item container xs={12}>
        <TextField
          label="Fecha de nacimiento"
          type="date"
          className={classes.formControl}
          error={hasError("birthDate")}
          helperText={errors.birthDate[0]}
          InputLabelProps={{
            shrink: true,
          }}
          value={user.birthDate}
          name="birthDate"
          onChange={updateState}
        />
      </Grid>
      <Grid item container xs={12}>
        <Phones handler={phonesHandler} errorContent={errors.phones[0]} />
        <Addresses
          handler={addressesHandler}
          errorContent={errors.addresses[0]}
        />
      </Grid>
      <Grid item container xs={12}>
        <TextField
          id="email"
          label="Correo electrónico"
          className={classes.formControl}
          error={hasError("email")}
          helperText={errors.email[0]}
          name="email"
          value={user.email}
          onChange={updateState}
        />
      </Grid>
      <Grid item container xs={12}>
        <FormControl
          className={classes.formControl}
          error={hasError("roleNames")}
        >
          <JobsAutoComplete />
          <FormHelperText>{errors.roleNames[0]}</FormHelperText>
        </FormControl>
      </Grid>
      <Grid item container xs={12}>
        <FormControl fullWidth error={hasError("stablishmentId")}>
          <InputLabel id="demo-simple-select-label">Sucursal</InputLabel>
          <Select
            className={classes.formControl}
            labelId="demo-simple-select-label"
            value={user.stablishmentId}
            name="stablishmentId"
            onChange={updateState}
          >
            {offices.map((office) => (
              <MenuItem value={office.code} key={office.name}>
                {office.name}
              </MenuItem>
            ))}
          </Select>
          <FormHelperText>{errors.stablishmentId[0]}</FormHelperText>
        </FormControl>
      </Grid>
      <Grid item container xs={12}>
        <FormControl fullWidth error={hasError("gender")}>
          <InputLabel id="demo-simple-select-label">Género</InputLabel>
          <Select
            className={classes.formControl}
            labelId="demo-simple-select-label"
            id="rol"
            value={user.gender}
            name="gender"
            onChange={updateState}
          >
            {genders.map((item) => (
              <MenuItem value={item.value} key={item.label}>
                {item.label}
              </MenuItem>
            ))}
          </Select>
          <FormHelperText className="text-error-form">
            {errors.gender[0]}
          </FormHelperText>
        </FormControl>
      </Grid>
      <Grid item container xs={12}>
        <TextField
          label="Notas"
          fullWidth
          error={hasError("comment")}
          helperText={errors.comment[0]}
          name="comment"
          value={user.comment}
          onChange={updateState}
        />
      </Grid>
      {changePassword && (
        <Grid item container>
          <FormControl className={classes.formControl} fullWidth>
            <InputLabel htmlFor="standard-adornment-password">
              Contraseña
            </InputLabel>
            <Input
              fullWidth
              error={hasError("password")}
              type={showPassword ? "text" : "password"}
              name="password"
              value={user.password}
              onChange={updateState}
              endAdornment={
                <InputAdornment position="end">
                  <IconButton
                    aria-label="toggle password visibility"
                    onClick={handleClickShowPassword}
                    onMouseDown={handleMouseDownPassword}
                  >
                    {showPassword ? <Visibility /> : <VisibilityOff />}
                  </IconButton>
                </InputAdornment>
              }
            />
            <FormHelperText>{errors.password}</FormHelperText>
          </FormControl>
          <IconButton
            component="span"
            className="mx-2"
            disabled-color="default"
            onClick={() => setRandomPassword(10)}
          >
            <EnhancedEncryptionIcon />
          </IconButton>
        </Grid>
      )}
      <Grid item container xs={11}>
        <FormControl className={classes.formControl}>
          <label htmlFor="">Asignar contraseña</label>
          <Switch
            color="secondary"
            onChange={onChangeShowChangePassword}
            value={changePassword}
          />
        </FormControl>
      </Grid>

      <Grid item container justify="flex-start">
        <Button
          className={generalClasses.actionButton}
          onClick={saveUser}
          component="span"
        >
          Guardar
        </Button>
      </Grid>
    </Grid>
  );
};

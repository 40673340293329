import { useContext, useEffect } from "react";
import { CivilStatusItem } from "../components/CivilStatus/CivilStatusItem";
import { CivilStatusModal } from "../components/CivilStatus/CivilStatusModal";
import { ContentWrapper } from "../components/Common/ContentWrapper/ContentWrapper";
import { PaginatedTableWrapper } from "../components/Common/PaginatedTableWrapper/PagintatedTableWrapper";
import { ChildrenFab } from "../components/Common/ScrollToTopContatiner/ChildrenFab";
import { ScrollToTopContainer } from "../components/Common/ScrollToTopContatiner/ScrollToTopContainer";
import { CivilStatusContext } from "../contexts/CivilStatusContext";
import { HeaderType } from "../interfaces/pagination.interfaces";

const headers: HeaderType[] = [];
export const CivilStatusView = () => {
  const {
    getCivilStatus,
    loadCivilStatus,
    links,
    pagination,
    list,
    clearSearchvalue,
    createCivilStatus,
  } = useContext(CivilStatusContext);

  useEffect(() => {
    loadCivilStatus();
  }, []);

  return (
    <ContentWrapper>
      <PaginatedTableWrapper
        title="Estados civiles"
        getData={getCivilStatus}
        reloadFunction={loadCivilStatus}
        clearSearchValue={clearSearchvalue}
        searchValue=""
        searchFunction={() => {}}
        links={links}
        pagination={pagination}
        headers={headers}
      >
        {list.map((item) => (
          <CivilStatusItem status={item} />
        ))}
      </PaginatedTableWrapper>
      <ScrollToTopContainer>
        <ChildrenFab iconType="plus" onClick={createCivilStatus} />
      </ScrollToTopContainer>
      <CivilStatusModal />
    </ContentWrapper>
  );
};

import { useEffect } from "react";
import Alert from "@material-ui/lab/Alert";
import IconButton from "@material-ui/core/IconButton";
import CloseIcon from "@material-ui/icons/Close";
import { useAlertStyles } from "./styles";

type AletType = "success" | "info" | "error" | "warning";

interface Props {
  show: boolean;
  message: string;
  severity?: AletType;
  handleClose: () => void;
}

export const CustomAlert = ({
  show,
  message = "Guardado correctamente",
  severity = "success",
  handleClose,
}: Props) => {
  const classes = useAlertStyles();
  useEffect(() => {
    if (show) {
      setTimeout(() => {
        handleClose();
      }, 3000);
    }
  }, [show]);
  return (
    <div className={classes.root}>
      {show && (
        <Alert
          className={severity == "error" ? classes.errorAlert : classes.alert}
          severity={severity || "success"}
          action={
            <IconButton
              component="span"
              size="small"
              color="inherit"
              onClick={handleClose}
            >
              <CloseIcon />
            </IconButton>
          }
        >
          {message}
        </Alert>
      )}
    </div>
  );
};

import {
  LinksPagination,
  MetaPagination,
} from "../interfaces/pagination.interfaces";
import {
  App,
  Role,
  RoleErrors,
  RolePaginated,
} from "../interfaces/roles.interfaces";
import { RolesActionTypes } from "../types/roles.types";

export interface RolesState {
  loading: boolean;
  list: RolePaginated[];
  role: Role;
  links: LinksPagination;
  pagination: MetaPagination;
  apps: App[];
  searchValue: string;
  errors: RoleErrors;
  error: boolean;
  success: boolean;
  message: string;
}

export const ROLES_INITIAL_STATE: RolesState = {
  loading: false,
  error: false,
  success: false,
  list: [],
  apps: [],
  pagination: {
    current_page: 1,
    from: 1,
    to: 10,
    last_page: 0,
    path: "",
    per_page: 10,
    total: 0,
  },
  links: {
    first: undefined,
    last: undefined,
    prev: undefined,
    next: undefined,
  },
  searchValue: "",
  role: {
    name: "",
    status: "V",
    comment: "",
    usersNumber: 0,
    permissions: [],
  },
  errors: {
    name: [],
    comment: [],
  },
  message: "",
};

export const rolesReducer = (
  state = ROLES_INITIAL_STATE,
  action: RolesActionTypes
): RolesState => {
  switch (action.type) {
    case "getRoles":
    case "getApps":
    case "getRole":
    case "changePermission":
    case "saveRole":
      return {
        ...state,
        loading: true,
        message: "",
        error: false,
        success: false,
      };
    case "getRolesError":
    case "getAppsError":
    case "getRoleError":
      return { ...state, loading: false };
    case "getRolesSuccess":
      return {
        ...state,
        loading: false,
        list: action.payload.data,
        links: action.payload.links,
        pagination: action.payload.meta,
      };
    case "getAppsSuccess":
      return {
        ...state,
        loading: false,
        apps: action.payload,
      };
    case "setSearchValue":
      return {
        ...state,
        searchValue: action.payload,
      };
    case "getRoleSuccess":
      return {
        ...state,
        loading: false,
        role: action.payload,
      };
    case "setRole":
      return { ...state, role: action.payload };
    case "saveRoleSuccess":
      return {
        ...state,
        loading: false,
        error: false,
        success: true,
        message: "Se guardó correctamente la información",
        role: action.payload,
      };
    case "updateRoleSuccess":
      return {
        ...state,
        loading: false,
        error: false,
        success: true,
        message: "Se actualizo correctamente la información",
        role: action.payload,
      };
    case "hideErrorAlert":
      return {
        ...state,
        error: false,
        message: "",
      };
    case "hideMessageDialog":
      return {
        ...state,
        success: false,
        message: "",
      };
    case "removePermission":
      return {
        ...state,
        loading: false,
        role: {
          ...state.role,
          permissions: state.role.permissions.filter(
            (permission) => permission.id !== action.payload.id
          ),
        },
      };
    case "addPermission":
      return {
        ...state,
        loading: false,
        role: {
          ...state.role,
          permissions: [...state.role.permissions, action.payload],
        },
      };
    case "savePermissionError":
      return { ...state, error: true, message: action.payload, loading: false };
    case "resetRole":
      return {
        ...state,
        role: ROLES_INITIAL_STATE.role,
      };
    case "badRequest":
      return {
        ...state,
        loading: false,
        message: action.payload || "Error desconocido",
      };
    default:
      return state;
  }
};

import { createContext, useReducer } from "react";
import { CustomAlert } from "../components/Common/Alert/Alert";
import { LoadingSpinner } from "../components/LoadingSpinner/LoadingSpinner";
import {
  useCivilStatus,
  UseCivilStatusProps,
} from "../hooks/civilStatus/useCivilStatus";
import { ProviderProps } from "../interfaces/common.interfaces";
import {
  civilStatusReducer,
  CivilStatusState,
  CIVIL_STATUS_INITIAL_STATE,
} from "../reducers/civislStatus.reducer";

interface ContexProps extends CivilStatusState, UseCivilStatusProps {}
export const CivilStatusContext = createContext({} as ContexProps);

export const CivilStatusProvider = ({ children }: ProviderProps) => {
  const initialState = CIVIL_STATUS_INITIAL_STATE;

  const [state, dispatch] = useReducer(civilStatusReducer, initialState);
  const context = useCivilStatus(state, dispatch);

  return (
    <CivilStatusContext.Provider value={{ ...state, ...context }}>
      {children}
      <LoadingSpinner loading={state.loading} />
      <CustomAlert
        show={state.success}
        message={state.message}
        handleClose={() => dispatch({ type: "hideAlert" })}
      />
      <CustomAlert
        severity="error"
        show={state.error}
        message={state.message}
        handleClose={() => dispatch({ type: "hideAlert" })}
      />
    </CivilStatusContext.Provider>
  );
};

import { Collapse, IconButton, Grid, Typography } from "@material-ui/core";
import { Alert, AlertTitle } from "@material-ui/lab";
import CloseIcon from "@material-ui/icons/Close";
import ErrorOutlineIcon from "@material-ui/icons/ErrorOutline";
import FiberManualRecordIcon from "@material-ui/icons/FiberManualRecord";
import { useErrosAlertContainer } from "./styles";

type PropTypes = {
  error: boolean;
  errors: any;
  hideErrorsAlert: Function;
  optionalMessage: string;
};

export const ErrorsAlertContainer = ({
  error,
  errors,
  hideErrorsAlert,
  optionalMessage,
}: PropTypes) => {
  const classes = useErrosAlertContainer();
  return (
    <Collapse in={error}>
      <Alert severity="error" className={classes.errorsContainer} icon={false}>
        <Grid container spacing={1}>
          <Grid
            item
            xs={12}
            container
            justifyContent="space-between"
            alignItems="flex-start"
          >
            <AlertTitle>
              <ErrorOutlineIcon className={classes.errorIcon} />
              Error
            </AlertTitle>
            <IconButton
              id="hide-button"
              aria-label="close"
              color="inherit"
              size="small"
              component="span"
              onClick={() => hideErrorsAlert()}
            >
              <CloseIcon fontSize="inherit" />
            </IconButton>
          </Grid>
          <Grid item xs={12}>
            <Typography className={classes.errorItem}>
              {optionalMessage}
            </Typography>
          </Grid>
          <Grid item xs={12} container justifyContent="flex-start">
            {errors &&
              Object.keys(errors).map((item) => (
                <Grid
                  container
                  item
                  xs={12}
                  justifyContent="flex-start"
                  direction="row"
                  key={`error-${item}`}
                >
                  {errors[item].length > 0 && (
                    <Typography className={classes.errorItem}>
                      <FiberManualRecordIcon className={classes.bulletIcon} />
                      {errors[item][0]}
                    </Typography>
                  )}
                </Grid>
              ))}
          </Grid>
        </Grid>
      </Alert>
    </Collapse>
  );
};

import {
  Address,
  AddressesErrors,
  AddressImage,
  AddressType,
  Suburb,
} from "../interfaces/address.interfaces";
import { AddressesActiontypes } from "../types/addresses.types";

export interface AddressesState {
  newAddress: Address;
  addresses: Address[];
  reusableAddresses: Address[];
  subs: Suburb[][];
  images: AddressImage[];
  loadingSub: boolean;
  loadingSubIndex: number;
  imagesRoutes: string[];
  showAddressImageModal: boolean;
  imagePath: string;
  isObject: boolean;
  showPreviousAddress: boolean;
  selectedPreviousAddress: string;
  errors: AddressesErrors;
  types: AddressType[];
}

export const ADDRESSES_INITIAL_STATE: AddressesState = {
  addresses: [],
  reusableAddresses: [],
  subs: [],
  images: [],
  imagesRoutes: [],
  showAddressImageModal: false,
  imagePath: "",
  isObject: false,
  showPreviousAddress: false,
  selectedPreviousAddress: "",
  errors: {
    street: [],
    postalCode: [],
    comment: [],
    addresses: [],
    number: [],
    internalNumber: [],
    type: [],
    addressType: [],
    fullAddres: [],
    id: [],
    image: [],
    imagePath: [],
    keyFile: [],
    skipAddressNumber: [],
    suburbId: [],
  },
  loadingSub: false,
  newAddress: {
    id: undefined,
    keyFile: undefined,
    type: "",
    street: "",
    number: "",
    skipAddressNumber: false,
    suburbId: "",
    postalCode: undefined,
    latitude: "",
    longitude: "",
    comment: "",
    image: "",
    hasImage: false,
    internalNumber: "",
    default: false,
    fullAddress: "",
    addressType: undefined,
    imagePath: "",
  },
  loadingSubIndex: 0,
  types: [],
};

export const addressesReducer = (
  state = ADDRESSES_INITIAL_STATE,
  action: AddressesActiontypes
): AddressesState => {
  switch (action.type) {
    case "setAddresses":
      return { ...state, addresses: action.payload };
    case "setReusableAddresses":
      return { ...state, reusableAddresses: action.payload };
    case "setLoadingSub":
      return { ...state, loadingSub: action.payload };
    case "setSubs":
      return {
        ...state,
        subs: action.payload,
      };
    case "setImages":
      return { ...state, images: action.payload };
    case "setImagesRoutes":
      return { ...state, imagesRoutes: action.payload };
    case "setImagePath":
      return { ...state, imagePath: action.payload };
    case "setSelectedPreviousAddress":
      return { ...state, selectedPreviousAddress: action.payload };
    case "setShowAddressImageModal":
      return { ...state, showAddressImageModal: action.payload };
    case "setIsObject":
      return { ...state, isObject: action.payload };
    case "setShowPreviousAddress":
      return { ...state, showPreviousAddress: action.payload };
    case "setErrors":
      return { ...state, errors: action.payload };
    case "setLoadingSubIndex":
      return { ...state, loadingSubIndex: action.payload };
    default:
      return state;
  }
};

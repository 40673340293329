import { ChangeEvent, Dispatch } from "react";
import { useHistory } from "react-router";
import { EstablishmentErrors } from "../../interfaces/establishments.interfaces";
import { EstablishmentsState } from "../../reducers/establishments.reducer";
import { EstablishmentsActionTypes } from "../../types/establishmens.types";

export interface UseSimpleStablishmentProps {
  hasError: (name: keyof EstablishmentErrors) => boolean;
  getEstablishmentData: (id: string) => void;
  deleteImage: () => void;
  onSubmit: () => void;
  handleImageUpload: () => void;
  onChange: (e: ChangeEvent<{ name?: string; value: unknown }>) => void;
}

export const useSimpleStablishment = (
  state: EstablishmentsState,
  dispatch: Dispatch<EstablishmentsActionTypes>
): UseSimpleStablishmentProps => {
  const history = useHistory();

  const hasError = (name: keyof EstablishmentErrors) => {
    return false;
  };

  const getEstablishmentData = (id: string) => {
    history.push("/establishments/establishment");
  };

  const deleteImage = () => {};

  const onSubmit = () => {};

  const handleImageUpload = () => {};

  const onChange = (e: ChangeEvent<{ name?: string; value: unknown }>) => {};

  return {
    hasError,
    getEstablishmentData,
    deleteImage,
    onSubmit,
    handleImageUpload,
    onChange,
  };
};

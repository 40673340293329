import { Button, makeStyles } from "@material-ui/core";
import { grey } from "@material-ui/core/colors";
import React, { CSSProperties } from "react";

interface Props {
  label?: string;
  onClick: () => void;
  styles?: CSSProperties;
}
export const SecondaryActionButton = ({
  label = "Cancelar",
  onClick,
  styles,
}: Props) => {
  const classes = useStyles();
  return (
    <Button
      component="span"
      onClick={onClick}
      style={styles}
      variant="contained"
      className={classes.button}
    >
      {label}
    </Button>
  );
};

const useStyles = makeStyles(() => ({
  button: {
    background: grey[900],
    color: "white",
    "&:hover": {
      background: grey[800],
    },
  },
}));

import Button from "@material-ui/core/Button";
import Dialog from "@material-ui/core/Dialog";
import DialogActions from "@material-ui/core/DialogActions";
import DialogContent from "@material-ui/core/DialogContent";
import DialogContentText from "@material-ui/core/DialogContentText";
import DialogTitle from "@material-ui/core/DialogTitle";
import { useGeneralStyles } from "../../../theme/generalStyles";

interface Props {
  handleClose: () => void;
  open: boolean;
  title: string;
  children: JSX.Element;
  size: false | "xs" | "sm" | "md" | "lg" | "xl" | undefined;
  actionTitle: string;
  action: () => void;
}

export const ConfirmDialog = ({
  open,
  handleClose,
  title,
  children,
  size,
  actionTitle,
  action,
}: Props) => {
  const classes = useGeneralStyles();
  return (
    <div>
      <Dialog
        open={open}
        onClose={handleClose}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
        maxWidth={size}
        fullWidth
      >
        <DialogTitle id="alert-dialog-title" className={classes.modalTitle}>
          {title ? title.toUpperCase() : ""}
        </DialogTitle>
        <DialogContent>
          <DialogContentText id="alert-dialog-description">
            {children}
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button
            onClick={action}
            color="primary"
            component="span"
            className={classes.actionButton}
          >
            {actionTitle || "Guardar"}
          </Button>
          <Button
            onClick={handleClose}
            component="span"
            className={classes.secondaryButton}
          >
            Cancelar
          </Button>
        </DialogActions>
      </Dialog>
    </div>
  );
};

import React, { useContext } from "react";
import {
  FormControl,
  FormControlLabel,
  Grid,
  Radio,
  RadioGroup,
} from "@material-ui/core";
import { DiscountContext } from "../../../contexts/DiscountsContext";

export const DiscountRadioButton = () => {
  const { handleIsDiscount, isDiscount } = useContext(DiscountContext);

  return (
    <Grid item container xs={12}>
      <FormControl component="fieldset">
        <RadioGroup
          name="is_discount"
          value={isDiscount}
          onChange={handleIsDiscount}
        >
          <FormControlLabel
            value="is_percentage"
            control={<Radio />}
            label="Percentaje"
          />
          <FormControlLabel
            value="is_discount"
            control={<Radio />}
            label="Cantidad"
          />
        </RadioGroup>
      </FormControl>
    </Grid>
  );
};

import { useState } from "react";
import {
  Grid,
  Button,
  FormControl,
  InputLabel,
  Input,
  InputAdornment,
  IconButton,
  Typography,
  TextField,
  Paper,
} from "@material-ui/core";
import Visibility from "@material-ui/icons/Visibility";
import VisibilityOff from "@material-ui/icons/VisibilityOff";
import { loginFonrmUseStyles } from "./styles";
import { useContext } from "react";
import { AuthContext } from "../../../contexts/AuthContext";
import { LoginAlert } from "../../Common/LoginAlert/LoginAlert";

export const LoginForm = () => {
  const classes = loginFonrmUseStyles();
  const [showPassword, setShowPassword] = useState(false);
  const handleClickShowPassword = () => {
    setShowPassword(!showPassword);
  };

  const handleMouseDownPassword = (event: any) => {
    event.preventDefault();
  };

  const { error, message, onChangeUser, login, userLogin } =
    useContext(AuthContext);

  return (
    <Paper className={classes.root}>
      <form onSubmit={login}>
        <Grid container justifyContent="center" spacing={3}>
          <Grid item xs={11}>
            <TextField
              fullWidth
              label="Usuario"
              name="email"
              type="text"
              value={userLogin.email}
              onChange={onChangeUser}
            />
          </Grid>
          <Grid item xs={11}>
            <FormControl className={classes.formControl}>
              <InputLabel htmlFor="standard-adornment-password">
                Contraseña
              </InputLabel>
              <Input
                type={showPassword ? "text" : "password"}
                name="password"
                value={userLogin.password}
                onChange={onChangeUser}
                endAdornment={
                  <InputAdornment position="end">
                    <IconButton
                      component="span"
                      aria-label="toggle password visibility"
                      onClick={handleClickShowPassword}
                      onMouseDown={handleMouseDownPassword}
                    >
                      {showPassword ? <Visibility /> : <VisibilityOff />}
                    </IconButton>
                  </InputAdornment>
                }
              />
            </FormControl>
          </Grid>
          <Grid item container justifyContent="center" xs={11}>
            <Typography className={classes.passwordMessage}>
              ¿Olvidaste tu contraseña?
            </Typography>
            <Typography className={classes.passwordMessage}>
              Llama al equipo de Sistemas.
            </Typography>
          </Grid>
          <Grid item xs={11} container justifyContent="center">
            <Button type="submit" variant="contained" color="primary">
              Entrar
            </Button>
          </Grid>
          <Grid>
            {error && <LoginAlert message={message} error type="danger" />}
          </Grid>
        </Grid>
      </form>
    </Paper>
  );
};

import { useContext, useEffect } from "react";
import { ContentWrapper } from "../components/Common/ContentWrapper/ContentWrapper";
import { PaginatedTableWrapper } from "../components/Common/PaginatedTableWrapper/PagintatedTableWrapper";
import { DeleteFolioModal } from "../components/Transactions/DeleteFolioModal";
import { FolioModal } from "../components/Transactions/FolioModal";
import { TransactionItem } from "../components/Transactions/TransactionItem";
import { TransactionModal } from "../components/Transactions/TransactionModal";
import { TransactionContext } from "../contexts/TransactionsContext";
import { HeaderType } from "../interfaces/pagination.interfaces";

const headers: HeaderType[] = [
  { name: "Id", hiddeMobile: true },
  { name: "Nombre" },
  { name: "Tipo" },
  { name: "" },
];

export const TransactionsView = () => {
  const {
    links,
    pagination,
    getTransactions,
    loadTransactions,
    searchFunction,
    clearSearchValue,
    searchValue,
    list,
  } = useContext(TransactionContext);

  useEffect(() => {
    loadTransactions();
  }, []);

  useEffect(() => {
    const timeOut = setTimeout(() => {
      getTransactions("transactions", { searchValue });
    }, 500);

    return () => clearTimeout(timeOut);
  }, [searchValue]);

  return (
    <ContentWrapper>
      <PaginatedTableWrapper
        iconTableSearch
        title="Transaccciones"
        headers={headers}
        links={links}
        pagination={pagination}
        searchFunction={searchFunction}
        clearSearchValue={clearSearchValue}
        getData={getTransactions}
        reloadFunction={loadTransactions}
        searchValue={searchValue}
      >
        {list.map((transaction) => (
          <TransactionItem transaction={transaction} />
        ))}
      </PaginatedTableWrapper>
      <TransactionModal />
      <FolioModal />
      <DeleteFolioModal />
    </ContentWrapper>
  );
};

import {
  LinksPagination,
  MetaPagination,
} from "../interfaces/pagination.interfaces";
import { Route, Suburb, SuburbErrors } from "../interfaces/suburb.interfaces";
import { SuburbActiontypes } from "../types/suburbs.types";

export interface SuburbsState {
  loading: boolean;
  list: Suburb[];
  links: LinksPagination;
  pagination: MetaPagination;
  showModal: boolean;
  searchvalue: string;
  success: boolean;
  message: string;
  routes: Route[];
  errors: SuburbErrors;
  suburb: Suburb;
  showDelete: boolean;
  error: boolean;
}

export const INITIAL_STATE: SuburbsState = {
  loading: false,
  list: [],
  pagination: {
    current_page: 1,
    from: 1,
    to: 10,
    last_page: 0,
    path: "",
    per_page: 10,
    total: 0,
  },
  links: {
    first: undefined,
    last: undefined,
    prev: undefined,
    next: undefined,
  },
  searchvalue: "",
  showModal: false,
  success: false,
  message: "",
  routes: [],
  errors: {
    name: [],
    routeId: [],
    city: [],
    postalCodeId: [],
  },
  suburb: {
    city: "",
    name: "",
    postalCodeId: "",
    routeId: "",
  },
  showDelete: false,
  error: false,
};

export const subursReducer = (
  state = INITIAL_STATE,
  action: SuburbActiontypes
): SuburbsState => {
  switch (action.type) {
    case "getSuburbs":
    case "getRoutes":
    case "saveSuburb":
    case "deleteSuburb":
      return {
        ...state,
        loading: true,
      };
    case "getSuburbsError":
    case "getRoutesError":
      return {
        ...state,
        loading: false,
      };
    case "getSuburbsSuccess":
      return {
        ...state,
        list: action.payload.data,
        links: action.payload.links,
        pagination: action.payload.meta,
        loading: false,
      };
    case "getRoutesSuccess":
      return {
        ...state,
        loading: false,
        routes: action.payload,
      };
    case "setSearchValue":
      return {
        ...state,
        searchvalue: action.payload,
      };
    case "createSuburb":
      return {
        ...state,
        suburb: INITIAL_STATE.suburb,
        showModal: true,
      };
    case "setSuburb":
      return {
        ...state,
        suburb: action.payload,
      };
    case "editSuburb":
      return {
        ...state,
        suburb: action.payload,
        showModal: true,
      };
    case "saveSuburbError":
      return {
        ...state,
        loading: false,
        errors: { ...INITIAL_STATE.errors, ...action.payload },
      };
    case "saveSuburbSuccess":
      return {
        ...state,
        loading: false,
        errors: INITIAL_STATE.errors,
        success: true,
        message: "Colonia registrada correcctamente",
        suburb: action.payload.data,
        list: [action.payload.data, ...state.list],
      };
    case "updateSuburbSuccess":
      return {
        ...state,
        loading: false,
        errors: INITIAL_STATE.errors,
        success: true,
        message: "Colonia actulizada correcctamente",
        suburb: action.payload.data,
        list: state.list.map((item) =>
          item.id === action.payload.data.id ? action.payload.data : item
        ),
      };
    case "badRequest":
      return {
        ...state,
        loading: false,
        message: action.payload || "",
        error: true,
      };
    case "hideAlert":
      return {
        ...state,
        success: false,
        message: "",
        error: false,
      };
    case "hideModal":
      return {
        ...state,
        showModal: false,
      };
    case "showDeleteSuburb":
      return {
        ...state,
        suburb: action.payload,
        showDelete: true,
      };
    case "cancelDeleteSub":
      return { ...state, showDelete: false };
    case "deleteSuburbError":
      return {
        ...state,
        loading: false,
        message: action.payload || "Error desconocido",
        error: true,
      };
    case "deleteSuburbSuccess":
      return {
        ...state,
        loading: false,
        success: true,
        message: "Colonia eliminada",
        list: state.list.filter((item) => item.id !== state.suburb.id),
        showDelete: false,
      };
    default:
      return state;
  }
};

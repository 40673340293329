import { makeStyles } from "@material-ui/core";

export const loginFonrmUseStyles = makeStyles((theme) => ({
  root: {
    "& > *": {
      width: "100%",
    },
    padding: 10,
  },
  formControl: {
    minWidth: 120,
    width: "100%",
  },
  passwordMessage: {
    fontSize: 10,
  },
}));

import {
  CivilStatus,
  CivilStatusErrors,
  CivilStatusStatus,
  Status,
} from "../interfaces/civilSatus.interfaces";
import {
  LinksPagination,
  MetaPagination,
} from "../interfaces/pagination.interfaces";
import { CivilStatusActiontypes } from "../types/civilStatus.tyes";

export interface CivilStatusState {
  loading: boolean;
  list: CivilStatus[];
  searchValue: string;
  status: CivilStatusStatus[];
  links: LinksPagination;
  pagination: MetaPagination;
  civilStatus: CivilStatus;
  errors: CivilStatusErrors;
  showModal: boolean;
  error: boolean;
  success: boolean;
  message: string;
}
export const CIVIL_STATUS_INITIAL_STATE: CivilStatusState = {
  loading: false,
  list: [],
  searchValue: "",
  status: [],
  showModal: false,
  success: false,
  error: false,
  pagination: {
    current_page: 1,
    from: 1,
    to: 10,
    last_page: 0,
    path: "",
    per_page: 10,
    total: 0,
  },
  links: {
    first: undefined,
    last: undefined,
    prev: undefined,
    next: undefined,
  },
  civilStatus: {
    id: undefined,
    name: "",
    status: Status.active,
    label: "",
  },
  errors: {
    name: [],
    status: [],
  },
  message: "",
};

export const civilStatusReducer = (
  state = CIVIL_STATUS_INITIAL_STATE,
  action: CivilStatusActiontypes
): CivilStatusState => {
  switch (action.type) {
    case "getCivilStatus":
    case "getStatus":
    case "saveStatus":
      return {
        ...state,
        error: false,
        loading: true,
      };
    case "getCivilStatusError":
    case "getStatusError":
      return {
        ...state,
        loading: false,
      };
    case "getCivilStatusSuccess":
      return {
        ...state,
        loading: false,
        list: action.payload.data,
        links: action.payload.links,
        pagination: action.payload.meta,
      };
    case "getStatusSuccess":
      return {
        ...state,
        loading: false,
        status: action.payload.data,
      };
    case "closeModal":
      return {
        ...state,
        showModal: false,
      };
    case "createCivilStatus":
      return {
        ...state,
        civilStatus: CIVIL_STATUS_INITIAL_STATE.civilStatus,
        showModal: true,
      };
    case "setCivilStatus":
      return {
        ...state,
        civilStatus: action.payload,
      };
    case "updateCivilStatus":
      return {
        ...state,
        civilStatus: action.payload,
        showModal: true,
      };
    case "saveStatusError":
      return {
        ...state,
        loading: false,
        error: true,
        errors: { ...CIVIL_STATUS_INITIAL_STATE.errors, ...action.payload },
      };
    case "saveStatusSuccess":
      return {
        ...state,
        civilStatus: action.payload,
        loading: false,
        error: false,
        errors: CIVIL_STATUS_INITIAL_STATE.errors,
        success: true,
        message: "Información guardada correctamente",
        list: [action.payload, ...state.list],
      };
    case "updateStatusSuccess":
      return {
        ...state,
        civilStatus: action.payload,
        loading: false,
        error: false,
        errors: CIVIL_STATUS_INITIAL_STATE.errors,
        message: "Información actualizada correctamente",
        list: state.list.map((item) =>
          item.id === action.payload.id ? action.payload : item
        ),
        success: true,
      };
    case "hideAlert":
      return {
        ...state,
        success: false,
        error: false,
        message: "",
      };
    case "badRequest":
      return {
        ...state,
        loading: false,
        error: true,
        message: action.payload || "Error desconocido",
      };
    default:
      return state;
  }
};

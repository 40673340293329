import { Dispatch } from "react";
import { backendApi } from "../../api/backendApi";
import { AllFinancementsResponse } from "../../interfaces/fiancements.interfaces";
import { AllPriceListResponse } from "../../interfaces/priceLists.interfaces";
import {
  ProductsResponse,
  ProductStatusResponse,
} from "../../interfaces/products.interfaces";
import { ProductsState } from "../../reducers/products.reducer";
import { ProductActionTypes } from "../../types/products.types";
import { PaymentFrequenciesResponse } from "../../interfaces/paymentFrequency.interfaces";

export interface UseProductsProps extends ProductsState {
  getProducts: (path: string) => void;
  searchFunction: () => void;
  resetSearchValue: () => void;
  loadProducts: () => void;
  getProductStatus: () => void;
  loadProductStatus: () => void;
  loadFinancements: () => void;
  loadPriceLists: () => void;
  loadPaymentFrequencies: () => void;
}

export const useProducts = (
  state: ProductsState,
  dispatch: Dispatch<ProductActionTypes>
): UseProductsProps => {
  const getProducts = async (path: string) => {
    dispatch({ type: "getProducts" });
    try {
      const response = await backendApi.get<ProductsResponse>(path);
      dispatch({ type: "getProductsSucess", payload: response.data });
    } catch (error: any) {}
  };

  const searchFunction = () => {};

  const resetSearchValue = () => {};

  const loadProducts = () => {
    if (state.list.length === 0) {
      getProducts("products");
    }
  };

  const getProductStatus = async () => {
    dispatch({ type: "getProductStatus" });
    try {
      const response = await backendApi.get<ProductStatusResponse>(
        "products/status"
      );
      dispatch({
        type: "getProductStatusSuccess",
        payload: response.data.data,
      });
    } catch (error: any) {}
  };

  const loadProductStatus = () => {
    if (state.status.length === 0) {
      getProductStatus();
    }
  };

  const loadFinancements = async () => {
    if (state.financements.length === 0) {
      try {
        dispatch({ type: "getFinancements" });
        const response = await backendApi.get<AllFinancementsResponse>(
          "financements"
        );
        dispatch({
          type: "getFinancementsSuccesss",
          payload: response.data.data,
        });
      } catch (error) {
        dispatch({ type: "getFinancementsError" });
      }
    }
  };

  const loadPriceLists = async () => {
    if (state.priceLists.length === 0) {
      try {
        dispatch({ type: "getPriceList" });
        const response = await backendApi.get<AllPriceListResponse>(
          "price-lists/all"
        );
        dispatch({ type: "getPriceListSuccess", payload: response.data.data });
      } catch (error) {
        dispatch({ type: "getPriceListError" });
      }
    }
  };

  const loadPaymentFrequencies = async () => {
    if (state.paymentFrequencies.length === 0) {
      try {
        dispatch({ type: "getPaymentFrequencies" });
        const response = await backendApi.get<PaymentFrequenciesResponse>(
          "payment-frequencies/currents"
        );
        dispatch({
          type: "getPaymentFrequenciesSuccess",
          payload: response.data.data,
        });
      } catch (error) {
        dispatch({ type: "getPaymentFrequenciesError" });
      }
    }
  };

  return {
    ...state,
    getProducts,
    searchFunction,
    resetSearchValue,
    loadProducts,
    loadProductStatus,
    getProductStatus,
    loadFinancements,
    loadPriceLists,
    loadPaymentFrequencies,
  };
};

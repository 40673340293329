import {
  DialogContent,
  Grid,
  Table,
  TableBody,
  TableHead,
  TableRow,
  Typography,
} from "@material-ui/core";
import { useContext } from "react";
import { ProductsContext } from "../../../contexts/ProductsContext";
import { HeaderType } from "../../../interfaces/pagination.interfaces";
import { CustomActionButton } from "../../Common/ActionButtons/CustomActionButton";
import { CustomTableCell } from "../../Common/PaginatedTableWrapper/CustomTableCell";
import { ProductPriceItem } from "../ProductPriceModal/ProductPriceItem";
import { useProductModalStyles } from "./styles";

const headers: HeaderType[] = [
  { name: "Financiamiento" },
  { name: "Precio de contado", hiddeMobile: true },
  { name: "Factor de financiamiento", hiddeMobile: true },
  { name: "Precio" },
  { name: "Lista de precios", hiddeMobile: true },
  { name: "" },
];

export const FinancementsTab = () => {
  const { product, dispatch } = useContext(ProductsContext);
  const classes = useProductModalStyles();

  return (
    <DialogContent>
      <Grid item xs={12} container justify="space-between" alignItems="center">
        <Typography className={classes.priceTabProductTitle}>
          {product.name}
        </Typography>
        <CustomActionButton
          label="Registrar precio"
          onClick={() => dispatch({ type: "showCreatePrice" })}
          iconType="add"
          iconStyles={{ fontWeight: "bold", marginLeft: 5 }}
        />
      </Grid>
      <Grid container style={{ overflowX: "auto" }}>
        <Table>
          <TableHead>
            <TableRow>
              {headers.map((header) => (
                <CustomTableCell hiddenMobile={header.hiddeMobile}>
                  {header.name}
                </CustomTableCell>
              ))}
            </TableRow>
          </TableHead>
          <TableBody>
            {product.prices.map((price) => (
              <ProductPriceItem price={price} />
            ))}
          </TableBody>
        </Table>
      </Grid>
    </DialogContent>
  );
};

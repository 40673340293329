import { createContext, useReducer } from "react";
import { useReligions, UseReligionsProps } from "../hooks/useReligions";
import { ProviderProps } from "../interfaces/common.interfaces";
import {
  religionsReducer,
  ReligionsState,
  RELIGIONS_INITIAL_STATE,
} from "../reducers/religions.reducer";

interface ContextProps extends UseReligionsProps, ReligionsState {}
export const ReligionsContext = createContext({} as ContextProps);

export const ReligionsProvider = ({ children }: ProviderProps) => {
  const initialState = RELIGIONS_INITIAL_STATE;

  const [state, dispatch] = useReducer(religionsReducer, initialState);

  const context = useReligions(state, dispatch);

  return (
    <ReligionsContext.Provider value={{ ...state, ...context }}>
      {children}
    </ReligionsContext.Provider>
  );
};

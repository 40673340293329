import { useContext, useEffect } from "react";
import { ContentWrapper } from "../components/Common/ContentWrapper/ContentWrapper";
import { PaginatedTableWrapper } from "../components/Common/PaginatedTableWrapper/PagintatedTableWrapper";
import { ReligionItem } from "../components/Religions/ReligionItem";
import { ReligionsContext } from "../contexts/ReligionsContext";
import { HeaderType } from "../interfaces/pagination.interfaces";

const headers: HeaderType[] = [
  { name: "Nombre" },
  { name: "Estatus" },
  { name: "" },
];

export const ReligionsView = () => {
  const {
    list,
    links,
    pagination,
    getReligions,
    loadReligions,
    searchFunction,
    searchValue,
    clearSearchValue,
  } = useContext(ReligionsContext);

  useEffect(() => {
    loadReligions();
  }, []);

  return (
    <ContentWrapper>
      <PaginatedTableWrapper
        title="Religiones"
        headers={headers}
        links={links}
        pagination={pagination}
        getData={getReligions}
        reloadFunction={loadReligions}
        searchValue={searchValue}
        searchFunction={searchFunction}
        clearSearchValue={clearSearchValue}
      >
        {list.map((rel) => (
          <ReligionItem religion={rel} />
        ))}
      </PaginatedTableWrapper>
    </ContentWrapper>
  );
};

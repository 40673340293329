import { makeStyles } from "@material-ui/core";

export const useCustomAppBarStyles = makeStyles(() => ({
  appBar: {
    background: "#f5f5f5",
    boxShadow: "0 0 1em 0 darkgray",
    height: 60,
    display: "flex",
    flexDirection: "row",
    flexWrap: "nowrap",
    width: "calc(100% - 0px)",
  },
  backIconContainer: {
    display: "flex",
  },
  childrenContainer: {},
}));

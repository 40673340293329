import { createContext, useReducer } from "react";
import { LoadingSpinner } from "../components/LoadingSpinner/LoadingSpinner";
import {
  useSimpleStablishment,
  UseSimpleStablishmentProps,
} from "../hooks/stablishments/useSimpleStablishment";
import {
  useEstablishments,
  UseStablishmentsProps,
} from "../hooks/stablishments/useStablishments";
import { ProviderProps } from "../interfaces/common.interfaces";
import {
  establishmentsReducer,
  EstablishmentsState,
  ESTABLISHMENTS_INITIAL_STATE,
} from "../reducers/establishments.reducer";

interface ContextProps
  extends EstablishmentsState,
    UseStablishmentsProps,
    UseSimpleStablishmentProps {}

export const EstablishmentsContext = createContext({} as ContextProps);

export const EstablishmentsProvider = ({ children }: ProviderProps) => {
  const initialState = ESTABLISHMENTS_INITIAL_STATE;

  const [state, dispatch] = useReducer(establishmentsReducer, initialState);

  const officesContext = useEstablishments(state, dispatch);

  const simpleEstablishmentContext = useSimpleStablishment(state, dispatch);

  return (
    <EstablishmentsContext.Provider
      value={{ ...state, ...officesContext, ...simpleEstablishmentContext }}
    >
      {children}
      <LoadingSpinner loading={state.loading} />
    </EstablishmentsContext.Provider>
  );
};

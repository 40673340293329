import { Zoom, Grid } from "@material-ui/core";

type AlertType = "danger" | "success" | "warning";

interface Props {
  message: string;
  type?: AlertType;
  error: boolean;
}

export const LoginAlert = ({ message, type = "success", error }: Props) => {
  return (
    <>
      <Zoom in={error}>
        <Grid container className="alert alert-danger">
          {message}
        </Grid>
      </Zoom>
    </>
  );
};

import { FinancementPaginated } from "../interfaces/fiancements.interfaces";
import { FinancementsActionTypes } from "../types/financements.types";

export interface FinancementsState {
  loading: boolean;
  list: FinancementPaginated[];
}

export const FINANCEMENTS_INITIAL_STATE: FinancementsState = {
  loading: false,
  list: [],
};

export const financementsReducer = (
  state = FINANCEMENTS_INITIAL_STATE,
  action: FinancementsActionTypes
): FinancementsState => {
  switch (action.type) {
    case "getFinancments":
      return {
        ...state,
        loading: true,
      };
    case "getFinancementsSuccess":
      return {
        ...state,
        loading: false,
        list: action.payload,
      };
    default:
      return state;
  }
};

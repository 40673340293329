import {
  LinksPagination,
  MetaPagination,
} from "../interfaces/pagination.interfaces";
import { Religion, Status } from "../interfaces/religions.interfaces";
import { ReligionActionTypes } from "../types/religions.types";

export interface ReligionsState {
  list: Religion[];
  loading: boolean;
  error: boolean;
  success: boolean;
  message: string;
  religion: Religion;
  links: LinksPagination;
  pagination: MetaPagination;
  searchValue: string;
}

export const RELIGIONS_INITIAL_STATE: ReligionsState = {
  loading: false,
  success: false,
  error: false,
  message: "",
  religion: {
    name: "",
    status: Status.active,
  },
  list: [],
  pagination: {
    current_page: 1,
    from: 1,
    to: 10,
    last_page: 0,
    path: "",
    per_page: 10,
    total: 0,
  },
  links: {
    first: undefined,
    last: undefined,
    prev: undefined,
    next: undefined,
  },
  searchValue: "",
};

export const religionsReducer = (
  state = RELIGIONS_INITIAL_STATE,
  action: ReligionActionTypes
): ReligionsState => {
  switch (action.type) {
    case "getReligions":
      return {
        ...state,
        loading: true,
      };
    case "getReligionsError":
      return {
        ...state,
        loading: false,
        error: true,
      };
    case "getReligionsSuccess":
      return {
        ...state,
        loading: false,
        list: action.payload.data,
        links: action.payload.links,
        pagination: action.payload.meta,
      };
    case "setSearchValue":
      return {
        ...state,
        searchValue: action.payload,
      };
    default:
      return state;
  }
};

import { ChangeEvent, Dispatch, useContext, useEffect, useState } from "react";
import { useHistory } from "react-router";
import { backendApi } from "../../api/backendApi";
import {
  NewUserResponse,
  SimpleRole,
  UserResponse,
  UsersErrors,
} from "../../interfaces/users.interfaces";
import { UsersState } from "../../reducers/users.reducer";
import { UsersActionTypes } from "../../types/users.types";
import { useAddresses, UseAddressesProps } from "../useAddresses";
import { usePhones, UsePhonesProps } from "../usePhones";
import { GeneralContext } from "../../contexts/GeneralContext";

export interface UseSimpleUserProps {
  addressesHandler: UseAddressesProps;
  phonesHandler: UsePhonesProps;
  showPassword: boolean;
  changePassword: boolean;
  saveUser: () => void;
  setRandomPassword: (length?: number) => void;
  updateState: (e: ChangeEvent<{ name?: string; value: unknown }>) => void;
  handleImageUpload: (e: ChangeEvent<HTMLInputElement>) => void;
  deleteImage: () => void;
  hasError: (value: keyof UsersErrors) => boolean;
  onChangeRoles: (roles: SimpleRole[]) => void;
  onChangePassword: () => void;
  handleMouseDownPassword: () => void;
  handleClickShowPassword: () => void;
  onChangeShowChangePassword: (e: ChangeEvent<HTMLInputElement>) => void;
  setChangePassword: (value: boolean) => void;
  getUserData: (id: string) => void;
}

export const useSimpleUser = (
  state: UsersState,
  dispatch: Dispatch<UsersActionTypes>
): UseSimpleUserProps => {
  const [showPassword, setShowPassword] = useState(false);
  const [changePassword, setChangePassword] = useState(false);
  const history = useHistory();

  const { getKeyValue } = useContext(GeneralContext);

  const addressesHandler = useAddresses();

  const phonesHandler = usePhones();

  const saveUser = async () => {
    dispatch({ type: "saveUser" });
    try {
      const { user } = state;
      const formData = new FormData();

      Object.keys(user).map((key: any) => {
        const value = getKeyValue(user, key);
        if (value) {
          formData.append(key, value);
        }
      });

      if (state.image) {
        formData.append("image", state.image);
      }

      formData.append("phones", JSON.stringify(phonesHandler.phones));
      formData.append("addresses", JSON.stringify(addressesHandler.addresses));
      formData.append("roleNames", JSON.stringify(user.roleNames));

      addressesHandler.images.forEach((item: any) => {
        formData.append(item.key, item.file);
      });

      if (user.folio) {
        const response = await backendApi.post<UserResponse>(
          `users/${user.folio}`,
          formData
        );
        dispatch({ type: "updateUserSuccess", payload: response.data });
      } else {
        const response = await backendApi.post<NewUserResponse>(
          "users",
          formData
        );
        dispatch({ type: "saveUserSuccess", payload: response.data });
      }
    } catch (error: any) {
      if (error.response.status === 422) {
        dispatch({
          type: "saveUserError",
          payload: error.response.data.errors,
        });
      } else {
        dispatch({ type: "badRequest", payload: error.response.data.message });
      }
    }
  };

  const setRandomPassword = (length = 10) => {
    const randomChars =
      "ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789";
    let result = "";
    for (let i = 0; i < length; i += 1) {
      result += randomChars.charAt(
        Math.floor(Math.random() * randomChars.length)
      );
    }
    dispatch({ type: "setUser", payload: { ...state.user, password: result } });
  };

  const updateState = (e: ChangeEvent<{ name?: string; value: unknown }>) => {
    dispatch({
      type: "setUser",
      payload: { ...state.user, [e.target.name!]: e.target.value },
    });
  };

  const handleImageUpload = (e: ChangeEvent<HTMLInputElement>) => {
    let file;
    if (e.target.files) {
      file = e.target.files[0];
    }
    if (file) {
      const reader = new FileReader();
      reader.onloadend = () => {
        dispatch({ type: "setImageRoute", payload: reader.result });
      };
      dispatch({ type: "setImage", payload: file });
      reader.readAsDataURL(file);
    }
  };

  const deleteImage = () => {
    dispatch({ type: "deleteImage" });
  };

  const hasError = (key: keyof UsersErrors) => {
    return state.errors[key].length > 0;
  };

  const onChangeRoles = (roles: SimpleRole[]) => {
    dispatch({
      type: "setUser",
      payload: {
        ...state.user,
        roleNames: roles.map((role) => role.name),
        roles,
      },
    });
  };

  const getUserData = async (id: string) => {
    dispatch({ type: "getUserData" });
    try {
      const response = await backendApi.get<UserResponse>(`users/${id}`);
      phonesHandler.dispatch({
        type: "setPhones",
        payload: response.data.data.phones,
      });
      addressesHandler.dispatch({
        type: "setAddresses",
        payload: response.data.data.addresses,
      });
      dispatch({ type: "getUserDataSucess", payload: response.data.data });
      history.push("/users/user");
    } catch (error) {
      dispatch({ type: "getUserDataError" });
    }
  };

  const onChangePassword = () => {};

  const handleMouseDownPassword = () => {};

  const handleClickShowPassword = () => {
    setShowPassword(!showPassword);
  };

  const onChangeShowChangePassword = (e: ChangeEvent<HTMLInputElement>) => {
    const { checked } = e.target;
    if (!checked) {
      dispatch({
        type: "setUser",
        payload: { ...state.user, password: undefined },
      });
    }
    setChangePassword(checked);
  };

  return {
    showPassword,
    changePassword,
    phonesHandler,
    addressesHandler,
    saveUser,
    setRandomPassword,
    updateState,
    handleImageUpload,
    deleteImage,
    hasError,
    onChangeRoles,
    onChangePassword,
    handleMouseDownPassword,
    handleClickShowPassword,
    onChangeShowChangePassword,
    setChangePassword,
    getUserData,
  };
};

import { makeStyles, Grid, GridJustification } from "@material-ui/core";
import ArrowBackIosIcon from "@material-ui/icons/ArrowBackIos";
import ArrowForwardIosIcon from "@material-ui/icons/ArrowForwardIos";
import IconButton from "@material-ui/core/IconButton";
import {
  LinksPagination,
  MetaPagination,
} from "../../../interfaces/pagination.interfaces";

const useStyles = makeStyles((theme) => ({
  container: {
    marginBottom: theme.spacing(6),
    paddingTop: theme.spacing(1),
    color: theme.palette.grey[600],
  },
}));

interface Props {
  pagination: MetaPagination;
  links: LinksPagination;
  justifyContent: GridJustification | undefined;
  loadData: (path: string) => void;
}

export const PaginatedTableFooter = ({
  links,
  pagination,
  loadData,
  justifyContent,
}: Props) => {
  const classes = useStyles();

  return (
    <Grid
      container
      className={classes.container}
      alignItems="center"
      justifyContent={justifyContent}
    >
      <span className="mx-3">{`${pagination.from || 0}-${
        pagination.to || 0
      } de ${pagination.total || 0}`}</span>
      <IconButton
        onClick={() => loadData(links.prev!)}
        disabled={!links.prev}
        component="span"
      >
        <ArrowBackIosIcon fontSize="small" />
      </IconButton>
      <IconButton
        onClick={() => loadData(links.next!)}
        disabled={!links.next}
        component="span"
      >
        <ArrowForwardIosIcon fontSize="small" />
      </IconButton>
    </Grid>
  );
};

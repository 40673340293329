import { TextField } from "@material-ui/core";
import { Autocomplete } from "@material-ui/lab";
import { useContext } from "react";
import { UsersContext } from "../../../contexts/UsersContext";

export const JobsAutoComplete = () => {
  const { user, onChangeRoles, hasError, roles } = useContext(UsersContext);

  return (
    <Autocomplete
      multiple
      id="tags-standard"
      options={roles}
      onChange={(e, v) => onChangeRoles(v)}
      getOptionLabel={(option) => option.name}
      value={user.roles}
      renderInput={(params) => (
        <TextField
          {...params}
          label="Puestos de trabajo"
          placeholder="Puestos de Trabajo"
          error={hasError("roleNames")}
        />
      )}
    />
  );
};

import {
  LinksPagination,
  MetaPagination,
} from "../interfaces/pagination.interfaces";
import {
  ServiceType,
  ServiceTypeErrors,
  ServiceTypeStatus,
  TypeStatus,
} from "../interfaces/serviceTypes.interfaces";
import { ServiceTypeActionTypes } from "../types/serviceTypes.types";

export interface ServiceTypesState {
  loading: boolean;
  serviceTypes: ServiceType[];
  links: LinksPagination;
  pagination: MetaPagination;
  searchvalue: string;
  status: TypeStatus[];
  errors: ServiceTypeErrors;
  type: ServiceType;
  showModal: boolean;
  success: boolean;
  message: string;
}
export const SERVICETYPE_INITIAL_STATE: ServiceTypesState = {
  loading: false,
  serviceTypes: [],
  pagination: {
    current_page: 1,
    from: 1,
    to: 10,
    last_page: 0,
    path: "",
    per_page: 10,
    total: 0,
  },
  links: {
    first: undefined,
    last: undefined,
    prev: undefined,
    next: undefined,
  },
  searchvalue: "",
  status: [],
  errors: {
    name: [],
    status: [],
  },
  type: {
    name: "",
    status: ServiceTypeStatus.active,
    label: "",
  },
  showModal: false,
  success: false,
  message: "",
};

export const serviceTypesReducer = (
  state = SERVICETYPE_INITIAL_STATE,
  action: ServiceTypeActionTypes
): ServiceTypesState => {
  switch (action.type) {
    case "getServiceTypes":
    case "getStatus":
    case "saveType":
      return {
        ...state,
        loading: true,
      };
    case "getStatusError":
      return {
        ...state,
        loading: false,
      };
    case "getServiceTypesSuccess":
      return {
        ...state,
        loading: false,
        serviceTypes: action.payload.data,
        links: action.payload.links,
        pagination: action.payload.meta,
      };
    case "setSearchValue":
      return {
        ...state,
        searchvalue: action.payload,
      };
    case "getStatusSuccess":
      return {
        ...state,
        loading: false,
        status: action.payload,
      };
    case "createType":
      return {
        ...state,
        type: SERVICETYPE_INITIAL_STATE.type,
        showModal: true,
      };
    case "updateType":
      return {
        ...state,
        showModal: true,
      };
    case "setShowModal":
      return {
        ...state,
        showModal: action.payload,
      };
    case "saveTypeError":
      return {
        ...state,
        loading: false,
        errors: { ...SERVICETYPE_INITIAL_STATE.errors, ...action.payload },
      };
    case "saveTypeSuccess":
      return {
        ...state,
        loading: false,
        success: true,
        errors: SERVICETYPE_INITIAL_STATE.errors,
        serviceTypes: [action.payload, ...state.serviceTypes],
        message: "Tipo de servicio registrado correctamente",
      };
    case "updateTypeSuccess":
      return {
        ...state,
        loading: false,
        success: true,
        errors: SERVICETYPE_INITIAL_STATE.errors,
        message: "Información actualizada correctamente",
        serviceTypes: state.serviceTypes.map((item) =>
          item.id === action.payload.id ? action.payload : item
        ),
      };
    case "setType":
      return {
        ...state,
        type: action.payload,
        showModal: true,
      };
    case "hideAlert":
      return {
        ...state,
        success: false,
        message: "",
      };
    default:
      return state;
  }
};

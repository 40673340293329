import { ChangeEvent } from "react";
import { DialogTitle, Tab, Tabs } from "@material-ui/core";
import { useTransactionStyles } from "./styles";

export type TabsIndex = "data" | "folios";

export const a11yProps = (index: TabsIndex) => {
  return {
    id: `wrapped-tab-${index}`,
    "aria-controls": `wrapped-tabpanel-${index}`,
  };
};

interface Props {
  handleChange: (event: ChangeEvent<{}>, newValue: TabsIndex) => void;
  value: TabsIndex;
}

export const TransactionsModalHeader = ({ value, handleChange }: Props) => {
  const classes = useTransactionStyles();

  return (
    <DialogTitle className={classes.headerContainer}>
      <Tabs
        variant="scrollable"
        scrollButtons="auto"
        value={value}
        onChange={handleChange}
        className={classes.headerTitle}
      >
        <Tab
          component="span"
          value="data"
          label="Información de transacción"
          wrapped
          {...a11yProps("data")}
        />
        <Tab
          component="span"
          value="folios"
          label="Folios"
          {...a11yProps("folios")}
        />
      </Tabs>
    </DialogTitle>
  );
};

import { ChangeEvent, Dispatch } from "react";
import { Redirect, useHistory } from "react-router";
import { backendApi } from "../../api/backendApi";
import {
  AppsResponse,
  RolesPaginatedResponse,
} from "../../interfaces/roles.interfaces";
import { RolesState } from "../../reducers/roles.reducer";
import { RolesActionTypes } from "../../types/roles.types";

export interface UseRolesProps {
  getRoles: (path: string, params?: Object) => void;
  loadApps: () => void;
  loadRoles: () => void;
  searchFunction: (e: ChangeEvent<HTMLInputElement>) => void;
  clearSearchValue: () => void;
  redirectNewRole: () => void;
}

export const useRoles = (
  state: RolesState,
  dispatch: Dispatch<RolesActionTypes>
): UseRolesProps => {
  const history = useHistory();

  const getRoles = async (path: string, params?: Object) => {
    dispatch({ type: "getRoles" });
    try {
      const response = await backendApi.get<RolesPaginatedResponse>(path, {
        params,
      });
      dispatch({ type: "getRolesSuccess", payload: response.data });
    } catch (error) {
      dispatch({ type: "getRolesError" });
    }
  };
  const loadRoles = () => {
    if (state.list.length === 0) {
      getRoles("roles");
    }
  };

  const loadApps = async () => {
    if (state.apps.length === 0) {
      try {
        dispatch({ type: "getApps" });
        const response = await backendApi.get<AppsResponse>("apps");
        dispatch({ type: "getAppsSuccess", payload: response.data.data });
      } catch (error) {
        dispatch({ type: "getAppsError" });
      }
    }
  };

  const searchFunction = (e: ChangeEvent<HTMLInputElement>) => {
    dispatch({ type: "setSearchValue", payload: e.target.value });
  };

  const clearSearchValue = () => {
    dispatch({ type: "setSearchValue", payload: "" });
  };

  const redirectNewRole = () => {
    dispatch({ type: "resetRole" });
    history.push("/jobs/job");
  };

  return {
    loadApps,
    loadRoles,
    getRoles,
    searchFunction,
    clearSearchValue,
    redirectNewRole,
  };
};

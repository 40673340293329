import { useContext, useEffect } from "react";
import {
  FormControl,
  InputLabel,
  Select,
  MenuItem,
  TextField,
  IconButton,
  Button,
  Grid,
  FormHelperText,
} from "@material-ui/core";
import AddCircleOutlineIcon from "@material-ui/icons/AddCircleOutline";
import DeleteIcon from "@material-ui/icons/Delete";
import { PhoneTypesContext } from "../../contexts/PhoneTypesContext";
import { UsePhonesProps } from "../../hooks/usePhones";
import { usePhonesStyles } from "./styles";

interface Props {
  handler: UsePhonesProps;
  errorContent?: string;
}

export const Phones = ({ handler, errorContent = "" }: Props) => {
  const classes = usePhonesStyles();
  const { errors, onChangePhone, deletePhone, updatePhones, phones } = handler;

  const { list: types, getPhoneTypes } = useContext(PhoneTypesContext);

  useEffect(() => {
    getPhoneTypes();
  }, []);

  return (
    <Grid container spacing={3}>
      <Grid item xs={12}>
        <FormHelperText className="text-danger">{errorContent}</FormHelperText>
      </Grid>
      <Grid item xs={12} container justify="flex-start">
        <Button
          component="span"
          className={classes.enabledTextColor}
          onClick={updatePhones}
        >
          <AddCircleOutlineIcon color="inherit" />
          <span className="mx-2">NUEVO TELÉFONO</span>
        </Button>
      </Grid>
      <Grid item xs={12}>
        {phones.map((item, index) => (
          <Grid
            container
            justify="flex-start"
            key={item.id}
            className={classes.phoneContainer}
          >
            <Grid item xs={3} lg={2} className={classes.itemContainer}>
              <FormControl className={classes.formControl}>
                <InputLabel id={`telefono-${index}`}>Tipo</InputLabel>
                <Select
                  labelId={`telefono-${index}`}
                  id={`telefono-${index}`}
                  name="typeId"
                  className={classes.formElement}
                  value={item.typeId}
                  onChange={(e) => {
                    onChangePhone(e, index);
                  }}
                >
                  {types.map((type) => (
                    <MenuItem value={type.id} key={type.id}>
                      {type.name}
                    </MenuItem>
                  ))}
                </Select>
              </FormControl>
            </Grid>
            <Grid item xs={8} lg={3}>
              <TextField
                label="Número"
                name="phoneNumber"
                type="number"
                className={classes.formElement}
                error={errors["phoneNumber"].length > 0}
                helperText={errors.phoneNumber}
                value={item.phoneNumber}
                onChange={(e) => {
                  onChangePhone(e, index);
                }}
                inputProps={{
                  maxLength: 15,
                  type: "number",
                }}
              />
            </Grid>
            <Grid item xs={1}>
              <IconButton component="span" onClick={() => deletePhone(index)}>
                <DeleteIcon />
              </IconButton>
            </Grid>
          </Grid>
        ))}
      </Grid>
    </Grid>
  );
};

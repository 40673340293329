import { useContext, useEffect } from "react";
import { ContentWrapper } from "../../components/Common/ContentWrapper/ContentWrapper";
import { CustomTableCell } from "../../components/Common/PaginatedTableWrapper/CustomTableCell";
import { CustomTableRow } from "../../components/Common/PaginatedTableWrapper/CustomTableRow";
import { PaginatedTableWrapper } from "../../components/Common/PaginatedTableWrapper/PagintatedTableWrapper";
import { ChildrenFab } from "../../components/Common/ScrollToTopContatiner/ChildrenFab";
import { ScrollToTopContainer } from "../../components/Common/ScrollToTopContatiner/ScrollToTopContainer";
import { EstablishmentsContext } from "../../contexts/EstablishmentsContext";
import { HeaderType } from "../../interfaces/pagination.interfaces";

const headers: HeaderType[] = [
  { name: "Establecimiento" },
  { name: "Dirección", hiddeMobile: true },
];

export const EstablishmentsListView = () => {
  const {
    getEstablishments,
    pagination,
    links,
    list,
    searchFunction,
    clearSearchvalue,
    redirectToNewOffice,
    getEstablishmentData,
  } = useContext(EstablishmentsContext);

  useEffect(() => {
    getEstablishments();
  }, []);

  return (
    <ContentWrapper>
      <PaginatedTableWrapper
        title="Sucursales"
        getData={getEstablishments}
        searchValue=""
        clearSearchValue={clearSearchvalue}
        headers={headers}
        pagination={pagination}
        links={links}
        searchFunction={searchFunction}
        reloadFunction={getEstablishments}
      >
        {list.map((estab) => (
          <CustomTableRow onClick={() => getEstablishmentData("omsdf")}>
            <CustomTableCell>
              <span>{estab.name}</span>
            </CustomTableCell>
            <CustomTableCell hiddenMobile>
              <span>{estab.fullAddress}</span>
            </CustomTableCell>
          </CustomTableRow>
        ))}
      </PaginatedTableWrapper>
      <ScrollToTopContainer>
        <ChildrenFab
          iconType="plus"
          onClick={redirectToNewOffice}
          title="Nuevo usuario"
        />
      </ScrollToTopContainer>
    </ContentWrapper>
  );
};

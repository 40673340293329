import { useContext } from "react";
import { CivilStatusContext } from "../../contexts/CivilStatusContext";
import { CivilStatus } from "../../interfaces/civilSatus.interfaces";
import { CustomIconButton } from "../Common/ActionButtons/CustomIconButton";
import { CustomTableCell } from "../Common/PaginatedTableWrapper/CustomTableCell";
import { CustomTableRow } from "../Common/PaginatedTableWrapper/CustomTableRow";
import { CivilStatusStatusIcon } from "./CivilStatusStatusIcon";

interface Props {
  status: CivilStatus;
}
export const CivilStatusItem = ({ status }: Props) => {
  const { setCivilStatus } = useContext(CivilStatusContext);
  return (
    <CustomTableRow>
      <CustomTableCell>{status.name}</CustomTableCell>
      <CustomTableCell>
        <CivilStatusStatusIcon type={status.status} label={status.label} />
      </CustomTableCell>
      <CustomTableCell style={{ margin: 0, padding: 0 }}>
        <CustomIconButton
          iconType="edit"
          onClick={() => setCivilStatus(status.id!)}
        />
      </CustomTableCell>
    </CustomTableRow>
  );
};

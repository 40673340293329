import { LinksPagination, MetaPagination } from "./pagination.interfaces";

export interface CivilStatusStatusResponse {
  data: CivilStatusStatus[];
  code: number;
}

export interface CivilStatusStatus {
  name: string;
  value: string;
}

export interface AllCivilStatusResponse {
  data: CivilStatus[];
  links: LinksPagination;
  meta: MetaPagination;
}

export interface CivilStatus {
  id?: string;
  name: string;
  status: Status;
  label: string;
}

export enum Status {
  active = "V",
  inactive = "C",
}

export interface CivilStatusErrors {
  name: string[];
  status: string[];
}

export interface CivilStatusResponse {
  data: CivilStatus;
  code: number;
}

import { ChangeEvent } from "react";
import { DialogTitle, Tab, Tabs } from "@material-ui/core";
import { useProductModalStyles } from "./styles";

export type TabsIndex = "data" | "financements" | "paymentFrequency";

export const a11yProps = (index: TabsIndex) => {
  return {
    id: `wrapped-tab-${index}`,
    "aria-controls": `wrapped-tabpanel-${index}`,
  };
};

interface Props {
  handleChange: (event: ChangeEvent<{}>, newValue: TabsIndex) => void;
  value: TabsIndex;
}

export const UpdateModalHeader = ({ value, handleChange }: Props) => {
  const classes = useProductModalStyles();

  return (
    <DialogTitle className={classes.headerContainer}>
      <Tabs
        variant="scrollable"
        scrollButtons="auto"
        value={value}
        onChange={handleChange}
        className={classes.headerTitle}
      >
        <Tab
          component="span"
          value="data"
          label="Información del homenaje"
          wrapped
          {...a11yProps("data")}
        />
        <Tab
          component="span"
          value="financements"
          label="Precios por financiamiento"
          {...a11yProps("financements")}
        />
        <Tab
          component="span"
          value="paymentFrequency"
          label="Frecuencias de pago"
          {...a11yProps("paymentFrequency")}
        />
      </Tabs>
    </DialogTitle>
  );
};

import { Fab } from "@material-ui/core";
import {
  ScrollIconButton,
  ScrollIconType,
} from "../ScrollToTopButton/ScrollButtonIcon";
import { CSSProperties } from "react";
import { useFloatinActionStyles } from "./styles";

interface Props {
  onClick: () => void;
  styles?: CSSProperties;
  iconType?: ScrollIconType;
  title?: string;
}

export const ChildrenFab = ({
  onClick,
  styles,
  iconType = "default",
  title = "",
}: Props) => {
  const classes = useFloatinActionStyles();
  return (
    <Fab
      color="primary"
      className={classes.fab}
      component="span"
      onClick={onClick}
      style={styles}
      title={title}
    >
      <ScrollIconButton type={iconType} />
    </Fab>
  );
};

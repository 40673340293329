import { useContext, useEffect } from "react";
import {
  Grid,
  TextField,
  Select,
  MenuItem,
  InputLabel,
  FormControl,
  FormHelperText,
} from "@material-ui/core";
import { ServiceTypesContext } from "../../contexts/ServiceTypesContext";

export const ServiceTypeForm = () => {
  const { errors, onChange, type, status, loadServiceTpesStatus } =
    useContext(ServiceTypesContext);

  useEffect(() => {
    loadServiceTpesStatus();
  }, []);

  return (
    <Grid container spacing={2}>
      <Grid item container xs={12}>
        <FormControl fullWidth>
          <TextField
            label="Nombre"
            name="name"
            value={type.name}
            onChange={onChange}
            error={errors.name.length > 0}
            helperText={errors.name[0]}
          />
        </FormControl>
      </Grid>
      <Grid item container xs={12}>
        <FormControl fullWidth>
          <InputLabel id="label-status">Estatus</InputLabel>
          <Select
            labelId="label-status"
            value={type.status}
            onChange={onChange}
            name="status"
          >
            <MenuItem value={type.status}>Selecciona</MenuItem>
            {status.map((item) => (
              <MenuItem value={item.value}>{item.label}</MenuItem>
            ))}
          </Select>
          <FormHelperText>{errors.status[0]}</FormHelperText>
        </FormControl>
      </Grid>
    </Grid>
  );
};

import { PaginationActionTypes } from "../types/pagination.types";

export interface PaginationState {
  to: number;
  from: number;
  totalRows: number;
  currentPage: number;
  lastPage: number;
  perPage: number;
}

const INITIAL_STATE: PaginationState = {
  to: 0,
  from: 0,
  totalRows: 0,
  currentPage: 1,
  perPage: 10,
  lastPage: 0,
};

export const paginationRedcuer = (
  state = INITIAL_STATE,
  action: PaginationActionTypes
): PaginationState => {
  switch (action.type) {
    case "updateTotal":
      return updateCurrentPageData(action.payload);
    case "decrease":
      return decreaseCurrentPage(state);
    case "increase":
      return increaseCurrentPage(state);
    default:
      return state;
  }
};

const decreaseCurrentPage = (payload: PaginationState) => {
  const currentPage = payload.currentPage - 1;
  let from = currentPage * payload.perPage + 1;
  let to = from + (payload.perPage - 1);
  if (currentPage === 1) {
    from = 1;
    to = payload.perPage;
  }
  return { ...payload, to, from, currentPage };
};

const increaseCurrentPage = (payload: PaginationState) => {
  const from = payload.currentPage * payload.perPage + 1;
  let to = from + (payload.perPage - 1);
  if (payload.currentPage + 1 === payload.lastPage) {
    to = payload.totalRows;
  }
  const currentPage = payload.currentPage + 1;
  return { ...payload, to, from, currentPage };
};

const updateCurrentPageData = (payload: number) => {
  const totalRows = payload;
  const lastPage = totalRows / 10 > 0 ? Math.trunc(totalRows / 10 + 1) : 1;
  const perPage = 10;
  const currentPage = 1;
  let to = currentPage + (perPage - 1);
  if (lastPage === 1) {
    to = totalRows;
  }

  return {
    currentPage,
    totalRows,
    perPage,
    lastPage,
    from: 1,
    to,
  };
};

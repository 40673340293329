import { Avatar, Chip } from "@material-ui/core";
import DoneIcon from "@material-ui/icons/Done";

interface Props {
  label: string;
}

export const UserRoleItem = ({ label }: Props) => {
  return (
    <Chip
      style={{ margin: 2 }}
      size="small"
      avatar={
        <Avatar>
          <DoneIcon style={{ fontSize: 15 }} />
        </Avatar>
      }
      label={label}
      clickable
      color="primary"
    />
  );
};

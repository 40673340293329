import { useRef } from "react";

interface UseTouchElement {
  onTouchStart: () => void;
  onTouchEnd: () => void;
}

export const useTouchElement = (action?: Function): UseTouchElement => {
  const startDate = useRef(0);
  const endDate = useRef(0);

  const onTouchStart = () => {
    startDate.current = performance.now();
  };

  const onTouchEnd = () => {
    endDate.current = performance.now();

    const diff = endDate.current - startDate.current;
    if (diff > 500 && diff < 1000 && action) {
      action();
    }
  };

  return {
    onTouchEnd,
    onTouchStart,
  };
};

import { Grid } from "@material-ui/core";
import { ContentWrapper } from "../../components/Common/ContentWrapper/ContentWrapper";
import { RoleForm } from "../../components/Roles/RoleForm";
import { SimpleRoleAppBarContent } from "../../components/Roles/SimpleRoleAppBar";

export const SimpleRoleView = () => {
  return (
    <ContentWrapper
      appBarContent={<SimpleRoleAppBarContent />}
      defaultPaddingTop
      paddingTop
    >
      <Grid container justifyContent="center">
        <Grid item xs={11} lg={10}>
          <RoleForm />
        </Grid>
      </Grid>
    </ContentWrapper>
  );
};

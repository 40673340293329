import {
  Paper,
  TextField,
  IconButton,
  Grid,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
  GridJustification,
} from "@material-ui/core";
import { v4 as uuid } from "uuid";
import ClearIcon from "@material-ui/icons/Clear";
import CachedIcon from "@material-ui/icons/Cached";
import {
  HeaderType,
  LinksPagination,
  MetaPagination,
} from "../../../interfaces/pagination.interfaces";
import { PaginatedTableFooter } from "./PaginatedTableFooter";
import { ChangeEvent } from "react";
import { usPaginatedTableStyles } from "./styles";

interface PropsType {
  children: JSX.Element | JSX.Element[];
  pagination: MetaPagination;
  title: string;
  iconTableSearch?: boolean;
  marginTop?: number;
  paddingTop?: number;
  searchValue?: string;
  headers: HeaderType[];
  links: LinksPagination;
  justifyFooterContent?: GridJustification | undefined;
  searchFunction: (e: ChangeEvent<HTMLInputElement>) => void;
  clearSearchValue: () => void;
  reloadFunction?: () => void;
  getData: (path: string) => void;
}

export const PaginatedTableWrapper = ({
  children,
  title,
  iconTableSearch,
  marginTop,
  paddingTop,
  headers,
  searchValue,
  searchFunction,
  clearSearchValue,
  reloadFunction,
  pagination,
  links,
  getData,
  justifyFooterContent = "center",
}: PropsType) => {
  const classes = usPaginatedTableStyles();

  return (
    <Grid container style={{ overflowX: "auto" }}>
      <Paper elevation={3} style={{ width: "100%", maxWidth: "90vw" }}>
        <Grid
          item
          xs={12}
          container
          className={`${classes.container} ${marginTop && classes.marginTop} ${
            paddingTop && classes.paddingTop
          }`}
        >
          <Grid container direction="row">
            <Grid
              item
              xs={12}
              lg={8}
              container
              direction="row"
              alignItems="center"
            >
              <h2 className="text-secondary">{title}</h2>
              {reloadFunction && (
                <IconButton component="span" onClick={reloadFunction}>
                  <CachedIcon />
                </IconButton>
              )}
            </Grid>
            <Grid
              item
              xs={12}
              lg={4}
              container
              alignItems="center"
              className={classes.searchFieldContainer}
            >
              {iconTableSearch && (
                <TextField
                  id="field"
                  label=""
                  placeholder="Buscar..."
                  className={classes.textField}
                  name="field"
                  value={searchValue}
                  onChange={searchFunction}
                />
              )}
              {searchValue && (
                <IconButton
                  component="span"
                  title="borrar término de búsqueda"
                  onClick={clearSearchValue}
                >
                  <ClearIcon />
                </IconButton>
              )}
            </Grid>
          </Grid>
          <Grid container style={{ overflowX: "auto" }}>
            <Table>
              <TableHead>
                <TableRow>
                  {headers.map((header) => (
                    <TableCell
                      key={uuid()}
                      className={`${classes.tableHeader} ${
                        header.hiddeMobile ? classes.hiddenMobile : ""
                      } ${header.hiddenDesktop ? classes.hiddenDesktop : ""}`}
                    >
                      {header.name}
                    </TableCell>
                  ))}
                </TableRow>
              </TableHead>
              <TableBody>{children}</TableBody>
            </Table>
          </Grid>
        </Grid>
      </Paper>
      <PaginatedTableFooter
        links={links}
        pagination={pagination}
        loadData={getData}
        justifyContent={justifyFooterContent}
      />
    </Grid>
  );
};

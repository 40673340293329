import { ChangeEvent, Dispatch } from "react";
import { backendApi } from "../api/backendApi";
import {
  ByPostalCodeResponse,
  RoutesResponse,
  SuburbResponse,
} from "../interfaces/suburb.interfaces";
import { SuburbsState } from "../reducers/suburbs.reducer";
import { SuburbActiontypes } from "../types/suburbs.types";

export interface UseSuburbsProps {
  getSuburbs: (postalCode: string) => void;
  searchFunction: (e: ChangeEvent<HTMLInputElement>) => void;
  clearSearchValue: () => void;
  closeModal: () => void;
  saveSuburb: () => void;
  onChangeSuburb: (e: ChangeEvent<{ name?: string; value: unknown }>) => void;
  createModal: () => void;
  loadRoutes: () => void;
  setSuburb: (id: number) => void;
  deleteSuburb: () => void;
  showDeleteSuburb: (id: number) => void;
  cancelDeleteSub: () => void;
}

export const useSuburbs = (
  state: SuburbsState,
  dispatch: Dispatch<SuburbActiontypes>
): UseSuburbsProps => {
  const getSuburbs = async (postalCode: string) => {
    try {
      dispatch({ type: "getSuburbs" });
      const response = await backendApi.get<ByPostalCodeResponse>(
        `suburbs/by-postal-code/${postalCode}`
      );
      dispatch({ type: "getSuburbsSuccess", payload: response.data });
    } catch (error: any) {
      dispatch({ type: "badRequest", payload: error.response.data.message });
    }
  };

  const searchFunction = (e: ChangeEvent<HTMLInputElement>) => {
    dispatch({ type: "setSearchValue", payload: e.target.value });
  };

  const clearSearchValue = () => {
    dispatch({ type: "setSearchValue", payload: "" });
  };

  const closeModal = () => {
    dispatch({ type: "hideModal" });
  };

  const createModal = () => {
    dispatch({ type: "createSuburb" });
  };

  const createSuburb = async () => {
    try {
      dispatch({ type: "saveSuburb" });
      const response = await backendApi.post<SuburbResponse>(
        "suburbs",
        state.suburb
      );
      dispatch({ type: "saveSuburbSuccess", payload: response.data });
    } catch (error: any) {
      if (error.response.status === 422) {
        dispatch({
          type: "saveSuburbError",
          payload: error.response.data.errors,
        });
      } else {
        dispatch({ type: "badRequest", payload: error.response.data.message });
      }
    }
  };

  const updateSuburb = async () => {
    try {
      dispatch({ type: "saveSuburb" });
      const response = await backendApi.patch<SuburbResponse>(
        `suburbs/${state.suburb.id}`,
        state.suburb
      );
      dispatch({ type: "updateSuburbSuccess", payload: response.data });
    } catch (error: any) {
      if (error.response.status === 422) {
        dispatch({
          type: "saveSuburbError",
          payload: error.response.data.errors,
        });
      } else {
        dispatch({ type: "badRequest", payload: error.response.data.message });
      }
    }
  };

  const saveSuburb = () => {
    state.suburb.id ? updateSuburb() : createSuburb();
  };

  const onChangeSuburb = (
    e: ChangeEvent<{ name?: string; value: unknown }>
  ) => {
    dispatch({
      type: "setSuburb",
      payload: { ...state.suburb, [e.target.name!]: e.target.value },
    });
  };

  const loadRoutes = async () => {
    if (state.routes.length === 0) {
      try {
        dispatch({ type: "getRoutes" });
        const response = await backendApi.get<RoutesResponse>("routes");
        dispatch({ type: "getRoutesSuccess", payload: response.data.data });
      } catch (error) {
        dispatch({ type: "getRoutesError" });
      }
    }
  };

  const setSuburb = (id: number) => {
    const payload = state.list.find((item) => item.id === id);
    if (payload) {
      dispatch({ type: "editSuburb", payload });
    }
  };

  const showDeleteSuburb = (id: number) => {
    const payload = state.list.find((item) => item.id === id);
    if (payload) {
      dispatch({ type: "showDeleteSuburb", payload });
    }
  };

  const deleteSuburb = async () => {
    try {
      dispatch({ type: "deleteSuburb" });
      await backendApi.delete<SuburbResponse>(`suburbs/${state.suburb.id}`);
      dispatch({ type: "deleteSuburbSuccess" });
    } catch (error: any) {
      dispatch({
        type: "deleteSuburbError",
        payload: error.response.data.message,
      });
    }
  };

  const cancelDeleteSub = () => {
    dispatch({ type: "cancelDeleteSub" });
  };

  return {
    getSuburbs,
    searchFunction,
    clearSearchValue,
    closeModal,
    saveSuburb,
    onChangeSuburb,
    createModal,
    loadRoutes,
    setSuburb,
    deleteSuburb,
    showDeleteSuburb,
    cancelDeleteSub,
  };
};

import { Grid } from "@material-ui/core";
import { useContext, useEffect } from "react";
import { ContentWrapper } from "../../components/Common/ContentWrapper/ContentWrapper";
import { EstablishmentContentAppBar } from "../../components/Establishments/ContentAppBar";
import { EstablishmentForm } from "../../components/Establishments/EstablishmentForm";
import { PriceListContext } from "../../contexts/PriceListContext";

export const EstablishmentView = () => {
  const { getPriceLists } = useContext(PriceListContext);

  useEffect(() => {
    getPriceLists();
  }, []);

  return (
    <ContentWrapper
      appBarContent={<EstablishmentContentAppBar />}
      defaultPaddingTop
    >
      <Grid container justifyContent="center">
        <Grid item xs={11} lg={8}>
          <EstablishmentForm />
        </Grid>
      </Grid>
    </ContentWrapper>
  );
};
